import {ITableAction, ITableColumn} from '@relayter/rubber-duck';
import {AppConstants} from '../../../../app.constants';
import {WorkflowConfigurationModel} from '../../../../models/api/workflow-configuration.model';
import {
    CUSTOM_WORKFLOW_TRANSITION_RECIPE_TASKS,
    CustomWorkflowTransitionRecipeTaskModel
} from '../../../../models/api/custom-workflow-transition.model';
import {
    CUSTOM_WORKFLOW_ACTIONS_CONFIGS,
    EWorkflowConfigurationActionType
} from '../../../../models/api/custom-workflow-action.model';
import {EWorkflowConfigurationFilterType} from '../../../../models/api/custom-workflow-filter.model';

export interface IActionConfig {
    type: ITableAction;
    permission?: string
}

export interface IPropertyConfig {
    type: EWorkflowConfigurationProperty;
    property: string;
    tableView?: {
        columns: ITableColumn[];
        actions?: ITableAction[];
        actionsConfig?: IActionConfig[];
    };
}

export enum EWorkflowConfigurationProperty {
    STEPS = 'Steps',
    COMPONENTS = 'Components',
    TRANSITIONS = 'Transitions',
    ACTIONS = 'Actions',
    FILTERS = 'Filters'
}

export class WorkflowConfigurationPropertyConfigs {
    private configs: IPropertyConfig[] = [
        {
            type: EWorkflowConfigurationProperty.STEPS,
            property: EWorkflowConfigurationProperty.STEPS.toLowerCase(),
            tableView: {
                columns: [
                    {
                        title: 'Step name',
                        key: 'name',
                    },
                    {
                        title: 'Icon',
                        key: 'icon',
                    },
                    {
                        title: 'Components',
                        key: 'components',
                        format: (v) => v.length
                    },
                    {
                        title: 'Permissions',
                        key: 'permissions',
                        format: (v) => v.join(', ')
                    },
                    {
                        title: 'Schedule (hours before deadline)',
                        key: 'schedule.hours',
                        sortProperty: 'schedule.hours',
                        sortDuplicates: true
                    }
                ] as ITableColumn[],
                actionsConfig: [
                    {
                        type: AppConstants.TABLE_ACTION_TYPES.EDIT,
                        permission: AppConstants.PERMISSIONS.PATCH_WORKFLOW_CONFIGURATION
                    },
                    {
                        type: AppConstants.TABLE_ACTION_TYPES.DELETE,
                        permission: AppConstants.PERMISSIONS.POST_WORKFLOW_CONFIGURATION_JOB
                    }
                ]
            }
        },
        {
            type: EWorkflowConfigurationProperty.COMPONENTS,
            property: EWorkflowConfigurationProperty.COMPONENTS.toLowerCase(),
            tableView: {
                columns: [
                    {
                        title: 'Component name',
                        key: 'name',
                    },
                    {
                        title: 'Component type',
                        key: 'componentType',
                    },
                    {
                        title: 'Actions',
                        key: 'actions',
                        format: (v) => v?.length || 0
                    },
                    {
                        title: 'Options',
                        key: 'options',
                        format: (v) => v?.length || 0
                    }
                ] as ITableColumn[],
                actionsConfig: [
                    {
                        type: AppConstants.TABLE_ACTION_TYPES.EDIT,
                        permission: AppConstants.PERMISSIONS.PATCH_WORKFLOW_CONFIGURATION
                    },
                    {
                        type: AppConstants.TABLE_ACTION_TYPES.DELETE,
                        permission: AppConstants.PERMISSIONS.PATCH_WORKFLOW_CONFIGURATION
                    }
                ]
            }
        },
        {
            type: EWorkflowConfigurationProperty.TRANSITIONS,
            property: EWorkflowConfigurationProperty.TRANSITIONS.toLowerCase(),
            tableView: {
                columns: [
                    {
                        title: 'Transition name',
                        key: 'name',
                    },
                    {
                        title: 'Transition title',
                        key: 'title',
                    },
                    {
                        title: 'From step',
                        key: 'from',
                        format: (v) => this.workflowConfiguration?.steps.find((step) => step._id === v)?.name
                    },
                    {
                        title: 'To step',
                        key: 'to',
                        format: (v) => this.workflowConfiguration?.steps.find((step) => step._id === v)?.name
                    },
                    {
                        title: 'Recipe tasks',
                        key: 'recipe',
                        format: (v) => v.map((task: CustomWorkflowTransitionRecipeTaskModel) =>
                            CUSTOM_WORKFLOW_TRANSITION_RECIPE_TASKS.find((item) => item.task === task.name)?.title || 'Not found').join(', ')
                    }
                ] as ITableColumn[],
                actionsConfig: [{
                    type: AppConstants.TABLE_ACTION_TYPES.EDIT,
                    permission: AppConstants.PERMISSIONS.PATCH_WORKFLOW_CONFIGURATION
                }, {
                    type: AppConstants.TABLE_ACTION_TYPES.DELETE,
                    permission: AppConstants.PERMISSIONS.PATCH_WORKFLOW_CONFIGURATION
                }]
            }
        },
        {
            type: EWorkflowConfigurationProperty.ACTIONS,
            property: EWorkflowConfigurationProperty.ACTIONS.toLowerCase(),
            tableView: {
                columns: [
                    {
                        title: 'Action name',
                        key: 'name',
                        format: (v: string) => {
                            return CUSTOM_WORKFLOW_ACTIONS_CONFIGS.find((action) => action.name === v)?.title || v;
                        }
                    },
                    {
                        title: 'Title',
                        key: 'title'
                    },
                    {
                        title: 'Type',
                        key: 'type',
                        format: (v) => v || EWorkflowConfigurationActionType.GENERAL_ACTION
                    },
                    {
                        title: 'Transition',
                        key: 'transition',
                        format: (v) => this.workflowConfiguration?.transitions.find(
                            (transition) => transition._id === v)?.name
                    },
                    {
                        title: 'Icon',
                        key: 'icon',
                    },
                    {
                        title: 'Permissions',
                        key: 'permissions',
                        format: (v) => v.join(', ')
                    },
                    {
                        title: 'Note status from',
                        key: 'from',
                        format: (v) => v?.join(', ')
                    },
                    {
                        title: 'Note status to',
                        key: 'to'
                    },
                    {
                        title: 'Options',
                        key: 'options',
                        format: (v) => v?.length || 0
                    }
                ] as ITableColumn[],
                actionsConfig: [{
                    type: AppConstants.TABLE_ACTION_TYPES.EDIT,
                    permission: AppConstants.PERMISSIONS.PATCH_WORKFLOW_CONFIGURATION
                }, {
                    type: AppConstants.TABLE_ACTION_TYPES.DELETE,
                    permission: AppConstants.PERMISSIONS.PATCH_WORKFLOW_CONFIGURATION
                }]
            }
        },
        {
            type: EWorkflowConfigurationProperty.FILTERS,
            property: EWorkflowConfigurationProperty.FILTERS.toLowerCase(),
            tableView: {
                columns: [
                    {
                        title: 'Filter name',
                        key: 'name'
                    },
                    {
                        title: 'Filter type',
                        key: 'filterName',
                        format: (value) => value ?? EWorkflowConfigurationFilterType.ITEM_FIELD_FILTER
                    },
                    {
                        title: 'Query param',
                        key: 'queryParam'
                    }
                ] as ITableColumn[],
                actionsConfig: [{
                    type: AppConstants.TABLE_ACTION_TYPES.EDIT,
                    permission: AppConstants.PERMISSIONS.PATCH_WORKFLOW_CONFIGURATION
                }, {
                    type: AppConstants.TABLE_ACTION_TYPES.DELETE,
                    permission: AppConstants.PERMISSIONS.PATCH_WORKFLOW_CONFIGURATION
                }]
            }
        }
    ];

    constructor(private workflowConfiguration: WorkflowConfigurationModel) {
    }

    public getConfig(name: string): IPropertyConfig {
        return this.configs.find(config => config.type === name);
    }
}
