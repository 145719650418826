
<div class="image-container">
    <img class="logo" [src]="logo">
</div>
<form [formGroup]="form">
    <nuc-form-field label="Name">
        <nuc-input formControlName="name"
                   type="string"
                   placeholder="Fill in your connection name">
        </nuc-input>

        <ng-container *ngIf="form.controls.name.touched && form.controls.name.errors as error">
            <nuc-error *ngIf="error.required">Name required</nuc-error>
        </ng-container>
    </nuc-form-field>
    <nuc-form-field label="Client id">
        <nuc-input formControlName="clientId"
                   type="string"
                   placeholder="Fill in your Client ID">
        </nuc-input>

        <ng-container *ngIf="form.controls.clientId.touched && form.controls.clientId.errors as error">
            <nuc-error *ngIf="error.required">Client ID required</nuc-error>
        </ng-container>
    </nuc-form-field>

    <nuc-form-field label="Client secret">
        <nuc-input formControlName="clientSecret"
                   type="text"
                   placeholder="Fill in your Client Secret">
        </nuc-input>

        <ng-container *ngIf="form.controls.clientSecret.touched && form.controls.clientSecret.errors as error">
            <nuc-error *ngIf="error.required">Client secret required</nuc-error>
        </ng-container>
    </nuc-form-field>
    <nuc-form-field label="Subscription key">
        <nuc-input formControlName="subscriptionKey"
                   type="text"
                   placeholder="Fill in your Primary subscription key">
        </nuc-input>

        <ng-container *ngIf="form.controls.subscriptionKey.touched && form.controls.subscriptionKey.errors as error">
            <nuc-error *ngIf="error.required">Subscription key required</nuc-error>
        </ng-container>
    </nuc-form-field>

</form>
