<div class="header">
    <h1>{{group?.name | nullUndefinedFormatter:'Content group details'}}</h1>
</div>

<ng-container *ngIf="permissions.GET_USERS | userIsAllowedTo else noPermissionState">
    <nuc-table [emptyStateTitle]="searchValue ? 'No item matches your search criteria.' : null"
               [items]="users"
               [columns]="columns"
               [loading]="!userSubscription?.closed"
               [tableId]="tableId"
               [prefixStorageKey]="storageKey"
               (sortOptionChange)="onSortOptionChanged($event)">
        <nuc-input button-bar-left
                   class="search"
                   [(data)]="searchValue"
                   prefix="nucicon_search"
                   (debounceValueChanged)="setPageIndex()"
                   placeholder="Search users">
        </nuc-input>

        <paginator button-bar-right
                   [viewId]="tableId"
                   [disableNextPage]="disableNextPage"
                   [loading]="!userSubscription?.closed">
        </paginator>
    </nuc-table>
</ng-container>

<ng-template #noPermissionState>
    <no-permission-state-component></no-permission-state-component>
</ng-template>
