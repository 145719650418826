import {Component, DestroyRef, inject, Inject, OnInit} from '@angular/core';
import {distinctUntilChanged} from 'rxjs/operators';
import {
    FormArray,
    FormControl,
    FormControlStatus,
    FormGroup,
    ValidationErrors,
    ValidatorFn,
    Validators
} from '@angular/forms';
import {
    BUTTON_TYPE,
    ButtonConfig,
    FullModalActionModel,
    FullModalService,
    NUC_FULL_MODAL_DATA
} from '@relayter/rubber-duck';
import {DropdownItem} from '../../models/ui/dropdown-item.model';
import {CustomWorkflowStepModel} from '../../models/api/custom-workflow-step.model';
import {WorkflowConfigurationsService} from '../../api/services/workflow-configurations.service';
import {ModelUtil} from '../../classes/model.util';
import {
    CUSTOM_WORKFLOW_COMPONENT_TYPES_CONFIGS,
    CustomWorkflowComponentModel,
    EColumnConfigModel,
    EComponentTypeContext,
    IComponentOptionConfig,
    IComponentTypeConfig
} from '../../models/api/custom-workflow-component.model';
import {takeUntilDestroyed} from '@angular/core/rxjs-interop';
import {WorkflowConfigurationModel} from '../../models/api/workflow-configuration.model';
import {IDropdownItem} from '@relayter/rubber-duck/lib/interfaces/idropdown-item';
import {IDropdownRequestDataEvent} from '@relayter/rubber-duck/lib/atoms/dropdown/dropdown.component';
import {Toaster} from '../../classes/toaster.class';
import {StringUtil} from '../../classes/string-util';
import {CustomWorkflowOptionModel, EWorkflowComponentOptionName} from '../../models/api/custom-workflow-option.model';
import {EDataFieldCollectionName} from '../../app.enums';
import {DataFieldsApiService} from '../../api/services/data-fields.api.service';
import {DataFieldModel} from '../../models/api/data-field.model';
import {Subscription} from 'rxjs';

export interface IWorkflowConfigurationComponentFormData {
    workflowConfiguration: WorkflowConfigurationModel;
    component?: CustomWorkflowComponentModel;
    workflowConfigurationStep?: CustomWorkflowStepModel;
}

@Component({
    selector: 'workflow-configuration-component-form-component',
    templateUrl: 'workflow-configuration-component-form.component.html',
    styleUrls: ['workflow-configuration-component-form.component.scss']
})
export class WorkflowConfigurationComponentFormComponent implements OnInit {
    private destroyRef = inject(DestroyRef);

    public permissions: DropdownItem<string>[];
    public formGroup: FormGroup;
    private saveButton: ButtonConfig;
    private workflowConfiguration: WorkflowConfigurationModel;
    private workflowConfigurationStep: CustomWorkflowStepModel;
    private component: CustomWorkflowComponentModel;
    public allComponentTypes: IDropdownItem<IComponentTypeConfig>[];
    public componentTypes: IDropdownItem<IComponentTypeConfig>[];

    protected readonly EWorkflowComponentOptionName = EWorkflowComponentOptionName;
    private campaignItemDataFields: DataFieldModel[];
    private columnSubscriptions: Subscription[] = [];

    // we set up the properties in the frontend instead of doing an api call
    // to be able to use display properties, such as templateType.name (backend is posType.name) and pageType (projected field)
    public allColumnConfigProperties: DropdownItem<string>[];
    public columnConfigProperties: DropdownItem<string>[];

    private readonly templateProperties = [
        new DropdownItem('Template name', 'template.name'),
        new DropdownItem('Template type', 'template.templateType.name')
    ];

    private readonly publicationItemProperties = [
        new DropdownItem('Page type', 'pageType'),
        new DropdownItem('Items assigned', 'numberOfAssignedItems')
    ];

    constructor(private fullModalService: FullModalService,
                private workflowConfigurationService: WorkflowConfigurationsService,
                private dataFieldsService: DataFieldsApiService,
                @Inject(NUC_FULL_MODAL_DATA) public modalData: IWorkflowConfigurationComponentFormData) {
    }

    public ngOnInit(): void {
        this.setupData();
    }

    public setupData(): void {
        this.workflowConfiguration = this.modalData.workflowConfiguration;
        this.component = this.modalData.component || new CustomWorkflowComponentModel();
        this.workflowConfigurationStep = this.modalData.workflowConfigurationStep;
        this.allComponentTypes = CUSTOM_WORKFLOW_COMPONENT_TYPES_CONFIGS
                .filter((config) => {
                    return (!config.publicationTypes || config.publicationTypes.includes(this.workflowConfiguration.publicationType.name))
                        && config.context === (this.workflowConfigurationStep ? EComponentTypeContext.STEP : EComponentTypeContext.WORKFLOW)
                })
                .map(config => new DropdownItem<IComponentTypeConfig>(config.type, config));
        this.componentTypes = this.allComponentTypes;

        this.dataFieldsService.getAllDataFields(EDataFieldCollectionName.CAMPAIGN_ITEM).subscribe({
            next: (campaignItemDataFields) => {
                this.campaignItemDataFields = campaignItemDataFields;
                this.initForm();
                this.initModalButtons();
            },
            error: Toaster.handleApiError
        });

    }

    public onRequestComponentTypes(request: IDropdownRequestDataEvent): void {
        if (request.search) {
            const regex = new RegExp(StringUtil.escapeRegExp(request.search), 'i');
            this.componentTypes = this.allComponentTypes.filter((componentType) =>
                componentType.getTitle().match(regex)?.length > 0);
        } else {
            this.componentTypes = this.allComponentTypes;
        }
    }

    private initModalButtons(): void {
        const cancelButton = new ButtonConfig(BUTTON_TYPE.SECONDARY, 'Cancel');
        this.saveButton = new ButtonConfig(BUTTON_TYPE.PRIMARY, 'Save', null, false);
        this.saveButton.disabled = this.formGroup.status !== 'VALID';

        const cancelAction = new FullModalActionModel(cancelButton);
        const saveAction = new FullModalActionModel(this.saveButton);

        cancelAction.observable.subscribe(() => this.fullModalService.close(false, true));
        saveAction.observable.subscribe(() => {
            this.saveWorkflowConfigurationComponent();
        });
        this.fullModalService.setModalActions([cancelAction, saveAction]);

        if (this.workflowConfigurationStep) {
            this.fullModalService.setDescription(`Enter the information to create a new component for step: ${this.workflowConfigurationStep.name}.`);
        }
    }

    private initForm(): void {
        const selectedComponentType = this.componentTypes.find((componentType) =>
            this.component?.componentType === componentType.getTitle());

        this.formGroup = new FormGroup<any>({
            name: new FormControl(this.component.name, Validators.required),
            componentType: new FormControl({
                value: selectedComponentType,
                disabled: !!selectedComponentType
                }, [Validators.required, this.noDeprecatedComponentType()]
            )
        });
        if (selectedComponentType?.getValue().optionsConfig) {
            this.addOptionsFormGroup(selectedComponentType.getValue().optionsConfig, this.component?.options);
        }
        this.listenToFormChanges();
        this.listenToComponentTypeControl();
    }

    private noDeprecatedComponentType(): ValidatorFn {
        return (formControl: FormControl): ValidationErrors => {
            if (this.component?.componentType === formControl.getRawValue()?.getTitle()) {
                return null;
            }
            if (formControl.getRawValue()?.getValue()?.canBeReplacedBy) {
                // eslint-disable-next-line max-len
                return {canBeReplaceBy: `${formControl.getRawValue().getTitle()} is deprecated, please use ${formControl.getRawValue().getValue()?.canBeReplacedBy} component instead.`}
            }
            return null;
        }
    }

    private listenToFormChanges(): void {
        this.formGroup.statusChanges.pipe(
            distinctUntilChanged(),
            takeUntilDestroyed(this.destroyRef)
        ).subscribe((status: FormControlStatus) => this.saveButton.disabled = status !== 'VALID');
    }

    private listenToComponentTypeControl(): void {
        this.formGroup.controls['componentType'].valueChanges.pipe(
            distinctUntilChanged(),
            takeUntilDestroyed(this.destroyRef)
        ).subscribe((value: IDropdownItem<IComponentTypeConfig>) => {
            // reset options form control
            this.formGroup.removeControl('options');
            if (value.getValue().optionsConfig) this.addOptionsFormGroup(value.getValue().optionsConfig);
        });
    }

    private addOptionsFormGroup(optionsConfig: IComponentOptionConfig[], options: Record<string, any> = []): void {

        const optionsFormGroup = new FormGroup({});

        for (const optionConfig of optionsConfig) {
            const value = options?.find((option) => optionConfig.name === option.name)?.value;

            switch (optionConfig.name) {
                case EWorkflowComponentOptionName.COLUMN_CONFIG:
                    if (optionConfig.model === EColumnConfigModel.PUBLICATION_ITEM) {
                        this.allColumnConfigProperties = this.campaignItemDataFields.map((dataField) =>
                            new DropdownItem(dataField.name, 'campaignItems.0.dataFields.' + dataField.fieldName))
                            .concat(this.templateProperties, this.publicationItemProperties);
                    } else {
                        this.allColumnConfigProperties = this.campaignItemDataFields.map((dataField) =>
                            new DropdownItem(dataField.name, 'campaignItem.dataFields.' + dataField.fieldName))
                            .concat(this.publicationItemProperties
                                .filter((property) => property.getValue() !== 'numberOfAssignedItems')
                                .concat(this.templateProperties).map((property) => {
                                    return new DropdownItem(property.getTitle(), 'publicationItem.' + property.getValue());
                                })
                            );
                    }
                    this.columnConfigProperties = this.allColumnConfigProperties;

                    const formArray = new FormArray([]);
                    value?.forEach((item) => formArray.push(this.createColumnFormGroup(item)));
                    optionsFormGroup.addControl(optionConfig.name, formArray);
                    break;
                default:
                    Toaster.error(`Workflow component option: ${optionConfig.name} is not supported yet.`);
            }
        }
        // this is to help with the save button status, otherwise we can use two forms
        this.formGroup.addControl('options', optionsFormGroup);
    }

    private saveWorkflowConfigurationComponent(): void {
        const component = ModelUtil.createApiBody({
            name: this.formGroup.value.name,
            componentType: this.formGroup.getRawValue().componentType.getTitle(),
            options: this.formGroup.contains('options') ? this.createOptionsBody() : [],
        }, this.component._id);

        this.saveButton.loading = true;
        const observable = this.component._id
            ? this.workflowConfigurationService.patchWorkflowConfigurationComponent(
                this.workflowConfiguration._id, this.component._id, component, this.workflowConfigurationStep?._id)
            : this.workflowConfigurationService.createWorkflowConfigurationComponent(
                this.workflowConfiguration._id, component, this.workflowConfigurationStep?._id)

        observable
            .pipe(takeUntilDestroyed(this.destroyRef))
            .subscribe({
                error: (error) => {
                    this.saveButton.loading = false;
                    Toaster.handleApiError(error);
                },
                next: (workflowConfiguration) => {
                    this.fullModalService.close(workflowConfiguration);
                    Toaster.success(`Component ${this.component._id ? 'updated' : 'created'} successfully`);
                }
            });
    }

    public addColumn(formArray: FormArray): void {
        formArray.push(this.createColumnFormGroup({}));
    }

    public createColumnFormGroup(value: Record<string, any>): FormGroup<{ displayName: FormControl, property: FormControl}> {
        const selectedProperty = this.columnConfigProperties.find((property) => property.getValue() === value.property);

        const propertyControl = new FormControl(selectedProperty, Validators.required);
        const displayNameControl = new FormControl(value.displayName, Validators.required);

        // listen to property control
        const subscription = propertyControl.valueChanges.subscribe((property) => {
            if (!displayNameControl.value) displayNameControl.patchValue(property.getTitle());
        });

        this.columnSubscriptions.push(subscription);

        return new FormGroup({
            displayName: displayNameControl,
            property: propertyControl
        });
    }

    public removeColumn(formArray: FormArray, index: number): void {
        formArray.removeAt(index);
        const subscription = this.columnSubscriptions.splice(index, 1);
        subscription[0].unsubscribe();
    }

    private createOptionsBody(): CustomWorkflowOptionModel[] {
        const formattedOptions = [];

        for (const [optionName, value] of Object.entries(this.formGroup.controls['options'].value)) {
            if (!value || (Array.isArray(value) && value.length === 0)) continue;

            switch (optionName) {
                case EWorkflowComponentOptionName.COLUMN_CONFIG:
                    // we trust the form group
                    const formattedValue = (value as Record<string, any>[]).map((v) => {
                        return {
                            displayName: v.displayName,
                            property: v.property.getValue()
                        }
                    });
                    formattedOptions.push({
                        name: optionName,
                        value: formattedValue
                    });
                    break;
                default:
                    Toaster.error(`Workflow component option: ${optionName} is not supported yet.`);
                    break;
            }
        }
        return formattedOptions;

    }

    public onSearchProperty(request: IDropdownRequestDataEvent): void {
        if (request.search) {
            const regex = new RegExp(StringUtil.escapeRegExp(request.search), 'i');
            this.columnConfigProperties = this.allColumnConfigProperties.filter((columnConfigProperty) =>
                columnConfigProperty.getTitle().match(regex)?.length > 0);
        } else {
            this.columnConfigProperties = this.allColumnConfigProperties;
        }
    }
}
