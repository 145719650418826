import {Component, OnInit} from '@angular/core';
import {RLTableComponent} from '../../../../components/rl-base-component/rl-table.component';
import {UserSettingsStorageService} from '../../../../api/services/user-settings-storage.service';
import {
    BUTTON_TYPE,
    EColumnDataType,
    EColumnType,
    ESelectionMode,
    FullModalConfig,
    FullModalService,
    IActionClickEvent,
    ISortOptionEvent,
    ITableAction,
    ITableColumn,
    NucDialogConfigModel,
    NucDialogService
} from '@relayter/rubber-duck';
import {ProductAssetExportSetupFormComponent} from '../../../../forms/product-asset-export-setup-form/product-asset-export-setup-form.component';
import {PaginatorService} from '../../../../components/paginator/paginator.service';
import {ActivatedRoute, Router} from '@angular/router';
import {takeUntilDestroyed} from '@angular/core/rxjs-interop';
import {AppConstants} from '../../../../app.constants';
import {MatrixUrlParams} from '../../../../models/ui/matrix-url-params.model';
import {EProductAssetExportSetupJobType, ProductAssetExportSetupsService} from '../../../../api/services/product-asset-export-setups.service';
import {Toaster} from '../../../../classes/toaster.class';
import {ProductAssetExportSetupModel} from '../../../../models/api/product-asset-export-setup.model';
import {RLDatePipe} from '../../../../pipes/rl-date.pipe';
import {UserIsAllowedToPipe} from '../../../../pipes/user-is-allowed-to.pipe';
import {EJobStatus, JobModel} from '../../../../models/api/job.model';
import {MonitoredJobsService} from '../../../../api/services/monitored-jobs.service';
import {filter, map} from 'rxjs/operators';
import {ARApiError} from '@relayter/core';
import {Subscription} from 'rxjs';
import {ItemUsedModel} from '../../../../models/api/item-used.model';

@Component({
    selector: 'product-asset-export-setup-overview',
    templateUrl: './product-asset-export-setup-overview.component.html',
    styleUrl: './product-asset-export-setup-overview.component.scss',
    providers: [PaginatorService]
})
export class ProductAssetExportSetupOverviewComponent extends RLTableComponent implements OnInit {
    public readonly tableId = 'product-asset-export-setup-overview-table';

    public total: number;
    public pageIndex: number;
    public pageSize: number;
    public disableNextPage = true;

    protected readonly ESelectionMode = ESelectionMode;
    private dataSubscription: Subscription;
    private deleteSubscription: Subscription;

    public items: ProductAssetExportSetupModel[];
    public actions: ITableAction[] = [];

    public get loading(): boolean {
        return !this.dataSubscription?.closed || (this.deleteSubscription && !this.deleteSubscription.closed);
    }

    public columns: ITableColumn[] = [
        {
            title: 'Name',
            key: 'name',
            sortProperty: 'name',
            type: EColumnType.DEFAULT
        },
        {
            title: 'Description',
            key: 'description',
            sortProperty: 'description',
            sortDuplicates: true
        },
        {
            title: 'Sort items count',
            key: 'sortItems',
            format: (items) => items.length
        },
        {
            title: 'Format',
            key: 'format',
            sortProperty: 'format',
            sortDuplicates: true
        },
        {
            title: 'Scale type',
            key: 'scaleType',
            sortDuplicates: true
        },
        {
            title: 'Width',
            key: 'width',
            sortProperty: 'width',
            sortDuplicates: true
        },
        {
            title: 'Height',
            key: 'height',
            sortProperty: 'height',
            sortDuplicates: true
        },
        {
            title: 'Date created',
            key: 'createdAt',
            sortProperty: 'createdAt',
            sortDuplicates: true,
            dataType: EColumnDataType.DATE,
            format: (value) => RLDatePipe.format(value, RLDatePipe.dateFormats.TABLE_DETAILED)
        },
        {
            title: 'Date modified',
            key: 'updatedAt',
            sortProperty: 'updatedAt',
            sortDuplicates: true,
            dataType: EColumnDataType.DATE,
            format: (value) => RLDatePipe.format(value, RLDatePipe.dateFormats.TABLE_DETAILED)
        }
    ];

    constructor(userSettingsStorageService: UserSettingsStorageService,
                private dialogService: NucDialogService,
                private fullModalService: FullModalService,
                private route: ActivatedRoute,
                private router: Router,
                private userIsAllowedPipe: UserIsAllowedToPipe,
                private productAssetExportSetupsService: ProductAssetExportSetupsService,
                private monitoredJobsService: MonitoredJobsService,
                private paginatorService: PaginatorService) {
        super(userSettingsStorageService);
    }

    public ngOnInit(): void {
        if (this.userIsAllowedPipe.transform(this.permissions.POST_PRODUCT_ASSET_EXPORT_SETUP_JOBS) &&
            this.userIsAllowedPipe.transform(this.permissions.GET_PRODUCT_ASSET_EXPORT_SETUP)) {
            this.actions.push(AppConstants.TABLE_ACTION_TYPES.EDIT);
            this.actions.push(AppConstants.TABLE_ACTION_TYPES.DELETE);
        }

        this.initFromRoute();
        this.listenToPagination();
    }

    private initFromRoute(): void {
        const params = this.route.snapshot.params;
        this.searchValue = params['search'];
        const pageIndex = params['pageIndex'] ? parseInt(params['pageIndex'], 10) : 1;
        this.paginatorService.setPageIndex(this.tableId, pageIndex);
        this.tableSortOptions.fromRoute(params, this.columns);
        this.setPageIndex();
        // we don't set pageSize from route
    }

    private listenToPagination(): void {
        this.paginatorService.getPagination(this.tableId)
            .pipe(takeUntilDestroyed(this.destroyRef))
            .subscribe(pagination => {
                if (pagination.pageIndex === 1 || pagination.pageSize !== this.pageSize) { // reset cursor when needed
                    this.newApiCursor.reset(pagination.pageIndex, this.tableSortOptions);
                }
                this.pageIndex = pagination.pageIndex;
                this.pageSize = pagination.pageSize;
                this.updateUrl();
                this.getData();
            });
    }

    private updateUrl(): void {
        const matrixUrl = new MatrixUrlParams(this.pageIndex, null,
            this.tableSortOptions.sortPropertiesAsString, this.tableSortOptions.sortOrder, this.searchValue);
        this.router.navigate([AppConstants.CONTEXT_URL.EXPORT_SETUPS,
            AppConstants.CONTEXT_URL.POST_PRODUCT_ASSET_EXPORT_SETUPS, matrixUrl], {replaceUrl: true});
    }

    private getData(): void {
        if (this.dataSubscription) this.dataSubscription.unsubscribe();

        const cursor = this.newApiCursor.getCursor(this.pageIndex);
        const offset = (this.pageIndex === 1 || cursor._id) ? 0 : (this.pageIndex - 1) * this.pageSize;

        this.dataSubscription =
            this.productAssetExportSetupsService.find(this.pageSize, offset, this.tableSortOptions, cursor, this.searchValue)
                .pipe(takeUntilDestroyed(this.destroyRef))
                .subscribe({
                    next: (result) => {
                        this.items = result.items;
                        this.disableNextPage = !result.hasNext;

                        if (this.items.length > 0) {
                            const item = this.items[this.items.length - 1];
                            this.newApiCursor.setCursor(this.pageIndex, item);
                        }
                    },
                    error: Toaster.handleApiError
                });
    }

    public onSortOptionChanged(sortEvent: ISortOptionEvent): void {
        this.tableSortOptions.updateWithSortOptionEvent(sortEvent);
        this.setPageIndex();
    }

    private setPageIndex(pageIndex = 1): void {
        this.newApiCursor.reset(pageIndex);
        this.paginatorService.setPageIndex(this.tableId, pageIndex);
    }

    public onCreateSetupClicked(): void {
        const modalConfig = new FullModalConfig(
            'Add Product asset export setup',
            'Enter the information to create a new Product asset export setup.', {});
        modalConfig.confirmClose = true;
        modalConfig.hideHeaderDivider = true;
        this.fullModalService.open(ProductAssetExportSetupFormComponent, modalConfig)
            .afterClosed()
            .pipe(takeUntilDestroyed(this.destroyRef))
            .subscribe((result) => {
                if (result) this.setPageIndex();
            });
    }

    public onSearchBarValueUpdated(searchValue: string): void {
        this.searchValue = searchValue;
        this.setPageIndex();
    }

    public onActionClicked(event: IActionClickEvent): void {
        switch (event.action) {
            case AppConstants.TABLE_ACTION_TYPES.EDIT: {
                this.productAssetExportSetupsService.findOne(event.item._id)
                    .pipe(takeUntilDestroyed(this.destroyRef))
                    .subscribe({
                        next: (productAssetExportSetup) => {
                            const modalConfig = new FullModalConfig(
                                'Edit Product asset export setup',
                                'Enter the information to edit this Product asset export setup.',
                                {productAssetExportSetup});
                            modalConfig.confirmClose = true;
                            modalConfig.hideHeaderDivider = true;
                            this.fullModalService.open(ProductAssetExportSetupFormComponent, modalConfig)
                                .afterClosed()
                                .pipe(takeUntilDestroyed(this.destroyRef))
                                .subscribe((result) => {
                                    if (result instanceof JobModel) {
                                        this.monitoredJobsService.getJobMonitor(result._id)
                                            .pipe(
                                                map((jobModel: JobModel) => jobModel.status === EJobStatus.DONE),
                                                filter((jobDone) => jobDone),
                                                takeUntilDestroyed(this.destroyRef)
                                            )
                                            .subscribe(() => this.setPageIndex());
                                    }
                                });
                        },
                        error: Toaster.handleApiError
                    });
                break;
            }
            case AppConstants.TABLE_ACTION_TYPES.DELETE: {
                this.productAssetExportSetupsService.findOne(event.item._id)
                    .pipe(takeUntilDestroyed(this.destroyRef))
                    .subscribe({
                        next: (productAssetExportSetup) => {
                            this.checkUsages(productAssetExportSetup)
                        },
                        error: Toaster.handleApiError
                    });
                break;
            }
            default:
                return Toaster.notYetImplementedError()
        }
    }

    private checkUsages(item: ProductAssetExportSetupModel) {
        this.deleteSubscription = this.productAssetExportSetupsService.getUsages(item._id)
            .pipe(takeUntilDestroyed(this.destroyRef))
            .subscribe({
                    next: (setupUsed: ItemUsedModel) => this.openDeleteDialog(!!setupUsed?.usage, item),
                    error: (err: ARApiError) => Toaster.handleApiError(err)
                }
            );
    }

    private openDeleteDialog(setupUsed: boolean, item: ProductAssetExportSetupModel): void {
        const dialogMessage = (setupUsed ?
            'The product asset export setup is used in products. Deleting this setup will remove it from all products. ' :
            '') + `Please confirm that you wish to delete ${item.name}?`
        const deleteDialogConfig = new NucDialogConfigModel('Delete Product Asset Export Setup',
            dialogMessage);
        const deleteDialog = this.dialogService.openDialog(deleteDialogConfig);
        deleteDialogConfig.addAction('Cancel', BUTTON_TYPE.SECONDARY).subscribe(() => deleteDialog.close());
        deleteDialogConfig.addAction('Delete', BUTTON_TYPE.DESTRUCTIVE).subscribe(() => {
            deleteDialog.close();
            this.deleteProductAssetExportSetup(item._id);
        });
    }

    private deleteProductAssetExportSetup(productAssetExportSetupId: string): void {
        this.productAssetExportSetupsService.postJob(
            EProductAssetExportSetupJobType.DELETE_PRODUCT_ASSET_EXPORT_SETUP_JOB,
            {productAssetExportSetupId})
            .subscribe({
                next: (job: JobModel) => {
                    this.monitoredJobsService.getJobMonitor(job._id)
                        .subscribe((jobModel: JobModel) => {
                            if (jobModel.status === EJobStatus.DONE) {
                                this.setPageIndex();
                            }
                        });
                },
                error: Toaster.handleApiError
            });
    }
}
