<div class="sticky-notes-container" [class.highlight]="selectedStickyNote" (click)="createSticky($event)" #stickyNotesContainer
     [class.hidden]="overlay() === previewOverlayEnum.NONE">
    <div class="standout_layer" *ngIf="overlay() === previewOverlayEnum.NOTES_BACKGROUND"></div>
    @for (stickyNote of notes(); track $index){
        <div *ngIf="stickyNote.publicationItem._id === item()._id"
             [class.selected]="selectedStickyNote?._id === stickyNote._id"
             class="sticky" (click)="onStickyNoteClicked($event, stickyNote)"
             [ngStyle]="{'left.%': stickyNote.position.x, 'top.%': stickyNote.position.y}">
                <img
                    [src]="stickyNote.status | getNotePinImagePipe:'':selectedStickyNote?._id === stickyNote._id"
                    class="pin"/>
                <div class="with-comments" *ngIf="stickyNote.totalComments > 0">
                    <span>{{stickyNote.totalComments}}</span>
                </div>
        </div>
    }
    @for (campaignItem of item().changedCampaignItems; track $index) {
        <div [class.selected]="selectedBriefingChange?.campaignItemId === campaignItem._id
             && selectedBriefingChange?.publicationItemId === item()._id"
             class="change" (click)="onBriefingChangeClicked(item()._id, campaignItem._id)"
             [ngStyle]="{'left.%': campaignItem.position.x, 'top.%': campaignItem.position.y}">
            <img [src]="selectedBriefingChange?.campaignItemId === campaignItem._id
                        && selectedBriefingChange?.publicationItemId === item()._id
                        ? 'assets/images/icon_pin_briefing_changes_selected.svg'
                        : 'assets/images/icon_pin_briefing_changes.svg'"
                 class="pin"/>
        </div>
    }
    <div class="sticky new" *ngIf="newStickyNote && newStickyNote.publicationItem._id === item()._id"
         [ngStyle]="{'left.%': newStickyNote.position.x, 'top.%': newStickyNote.position.y}" (click)="onNewStickyNoteClicked($event)">
        <img [src]="newStickyNote.status | getNotePinImagePipe:'':true"
             class="pin selected" @newStickyNote/>
    </div>
</div>
