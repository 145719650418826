<nuc-dropdown #connectionTypeDropdown (dataChange)="selectionChanged($event)" [items]="connections" [(ngModel)]="selectedConnection" [label]="'Dropdown'"
              placeholder="Select an option"
    class="connection-selector"></nuc-dropdown>
<ng-container [ngSwitch]="selectedConnectionType">
    <ng-container *ngSwitchCase="CONNECTIONS.MEDIA_VALET">
        <media-valet-connection-form (formSubmitted)="createOrUpdateConnection($event)" [formData]="modalData?.connection"></media-valet-connection-form>
    </ng-container>
    <ng-container *ngSwitchCase="CONNECTIONS.WEBHOOK_CONSUMER">
        <consumer-webhook-connection-form (formSubmitted)="createOrUpdateConnection($event)" [formData]="modalData?.connection"></consumer-webhook-connection-form>
    </ng-container>
    <ng-container *ngSwitchCase="CONNECTIONS.SUPERUNIE_ADAM">
        <superunie-adam-connection-form (formSubmitted)="createOrUpdateConnection($event)" [formData]="modalData?.connection"></superunie-adam-connection-form>
    </ng-container>
</ng-container>
