import {Injectable} from '@angular/core';
import {ApiConstants} from '../api.constant';
import {Observable} from 'rxjs';
import {ApiPagedRequest} from '../requests/api-paged.request';
import {ApiService} from './api.service';
import {UserModel} from '../../models/api/user.model';

@Injectable({
    providedIn: 'root'
})
export class UsersApiService extends ApiService<UserModel> {

    constructor() {
        super([ApiConstants.API_GROUP_USERS], UserModel);
    }

    public getAllUsers(): Observable<UserModel[]> {
        const request = new ApiPagedRequest(this.groups, this.model);
        return this.apiRequestService.findAll(request);
    }
}
