import {Directive, ElementRef, HostListener, inject, input, OnInit, Renderer2} from '@angular/core';
import {UserSettingsStorageService} from '../../api/services/user-settings-storage.service';

export enum EResizableOrientation {
    HORIZONTAL = 'horizontal',
    VERTICAL = 'vertical'
}
@Directive({
    selector: '[resizable]',
})
export class ResizableDirective implements OnInit {
    private static readonly STORAGE_KEY = 'resizable';
    private static readonly COLOR_SNOW = '#e5e5e5';
    private static readonly COLOR_RELAYTER_GREEN = '#3fbb87';

    private readonly boundOnMouseMove = (event: MouseEvent) => this.onMouseMove(event);
    private readonly boundOnMouseUp = () => this.onMouseUp();

    // Directive optional input
    public resizableId = input<string>(null); // Unique id to store position of the slider
    public resizableOrientation = input<EResizableOrientation>(EResizableOrientation.HORIZONTAL);

    private leftDiv: Element;
    private slider: Element;

    private startPosition: number = 0;
    private startSize: number = 0;
    private resizing: boolean = false;

    // Injectables
    private el = inject(ElementRef);
    private renderer = inject(Renderer2);
    private settingsStorageService = inject(UserSettingsStorageService);

    public ngOnInit(): void {
        this.createSlider();

        this.leftDiv = this.el.nativeElement.previousElementSibling;

        if (this.resizableId()) {
            const flexBasis = this.loadResizableData(this.resizableId());
            if (flexBasis) this.renderer.setStyle(this.leftDiv, 'flex-basis', `${flexBasis}px`);
        }
    }

    private isHorizontal(): boolean {
        return this.resizableOrientation() === EResizableOrientation.HORIZONTAL;
    }

    @HostListener('mouseover')
    public onMouseOver() {
        if (this.slider) {
            this.renderer.setStyle(this.slider, 'transition', `all .1s ease-in-out`);
            this.renderer.setStyle(this.slider, 'background-color', ResizableDirective.COLOR_RELAYTER_GREEN);
            this.renderer.setStyle(this.slider, this.isHorizontal() ? 'width' : 'height', '2px');
        }
    }

    @HostListener('mouseout')
    public onMouseOut() {
        if (this.slider) {
            this.renderer.setStyle(this.slider, 'transition', `all .1s ease-out-in`);
            this.renderer.setStyle(this.slider, 'background-color', ResizableDirective.COLOR_SNOW);
            this.renderer.setStyle(this.slider, this.isHorizontal() ? 'width' : 'height', '1px');
        }
    }

    @HostListener('mousedown', ['$event'])
    public onMouseDown(event: MouseEvent) {
        this.resizing = true;
        this.startPosition = this.isHorizontal() ? event.clientX : event.clientY;

        this.startSize = this.isHorizontal() ? this.leftDiv.clientWidth : this.leftDiv.clientHeight;

        document.addEventListener('mousemove', this.boundOnMouseMove);
        document.addEventListener('mouseup', this.boundOnMouseUp);

        this.renderer.setStyle(document.body, '-webkit-user-select', 'none');
        this.renderer.setStyle(document.body, 'user-select', 'none');
    }

    private onMouseUp() {
        this.resizing = false;

        if (this.resizableId()) {
            this.storeResizableData(this.resizableId(), this.isHorizontal() ? this.leftDiv.clientWidth : this.leftDiv.clientHeight);
        }

        document.removeEventListener('mousemove', this.boundOnMouseMove);
        document.removeEventListener('mouseup', this.boundOnMouseUp);

        this.renderer.removeStyle(document.body, '-webkit-user-select');
        this.renderer.removeStyle(document.body, 'user-select');
    }

    private onMouseMove(event: MouseEvent) {
        if (!this.resizing) {
            return;
        }

        const dx = this.isHorizontal() ? event.clientX - this.startPosition : event.clientY - this.startPosition;

        this.renderer.setStyle(this.leftDiv, 'flex-basis', `${this.startSize + dx}px`);
    }

    private loadResizableData(id: string): number {
        const resizableSettings = this.settingsStorageService.loadSettings(ResizableDirective.STORAGE_KEY) ?? {};

        return resizableSettings[id];
    }

    private storeResizableData(id: string, width: number): void {
        const resizableSettings = this.settingsStorageService.loadSettings(ResizableDirective.STORAGE_KEY) ?? {};

        resizableSettings[id] = width;
        this.settingsStorageService.storeSettings(ResizableDirective.STORAGE_KEY, resizableSettings);
    }

    private createSlider() {
        this.renderer.setStyle(this.el.nativeElement, this.isHorizontal() ? 'width' : 'height', '6px');
        this.renderer.setStyle(this.el.nativeElement, 'cursor', this.isHorizontal() ? 'ew-resize' : 'ns-resize');
        this.renderer.setStyle(this.el.nativeElement, 'display', 'flex');
        this.renderer.setStyle(this.el.nativeElement, 'flex-direction', this.isHorizontal() ?  'row' : 'column');
        this.renderer.setStyle(this.el.nativeElement, 'justify-content', 'center');
        this.renderer.setStyle(this.el.nativeElement, 'align-items', 'stretch');
        this.renderer.setStyle(this.el.nativeElement, 'overflow', 'hidden');
        this.renderer.setStyle(this.el.nativeElement, 'flex', '0 0 auto');

        this.slider = this.renderer.createElement('div');
        this.renderer.setStyle(this.slider, this.isHorizontal() ? 'width' : 'height', '1px');
        this.renderer.setStyle(this.slider, 'background-color', ResizableDirective.COLOR_SNOW);
        this.renderer.appendChild(this.el.nativeElement, this.slider);
    }
}
