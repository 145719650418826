
<div class="image-container">
    <img class="logo" [src]="logo">
</div>
<form [formGroup]="form">
    <nuc-form-field label="Name">
        <nuc-input formControlName="name"
                   type="string"
                   placeholder="Fill in your connection name">
        </nuc-input>

        <ng-container *ngIf="form.controls.name.touched && form.controls.name.errors as error">
            <nuc-error *ngIf="error.required">Name required</nuc-error>
        </ng-container>
    </nuc-form-field>
    <nuc-form-field label="API key">
        <nuc-input formControlName="apiKey"
                   type="text"
                   placeholder="Fill in your API key">
        </nuc-input>

        <ng-container *ngIf="form.controls.apiKey.touched && form.controls.apiKey.errors as error">
            <nuc-error *ngIf="error.required">API key required</nuc-error>
        </ng-container>
    </nuc-form-field>
    <nuc-form-field label="Global Location Numbers (GLN)">
        <nuc-suggestive-input placeholder="Add a global location number"
                              formControlName="globalLocationNumbers">
        </nuc-suggestive-input>
    </nuc-form-field>
</form>
