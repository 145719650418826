import {Component, Input, OnInit} from '@angular/core';
import {EJobStatus, JobModel} from '../../../models/api/job.model';
import {AppConstants} from '../../../app.constants';
import {BUTTON_TYPE, EToastType, ToastActionModel, ToastButtonConfig, ToastDataModel} from '@relayter/rubber-duck';
import {ARLogger} from '@relayter/core';
import {Toaster} from '../../../classes/toaster.class';
import {MonitoredJobsService} from '../../../api/services/monitored-jobs.service';

@Component({
    selector: 'rl-job-notification',
    styleUrls: ['job-notification.component.scss'],
    templateUrl: 'job-notification.component.html',
})
export class JobNotificationComponent implements OnInit {
    @Input() public jobInput: JobModel;

    public DOWNLOAD_JOBS = Object.keys(AppConstants.JOBS)
        .filter(jobName => AppConstants.JOBS[jobName].download)
        .map(jobName => AppConstants.JOBS[jobName].name);

    private toastData: ToastDataModel;

    /**
     * @param monitoredJobsService
     */
    constructor(protected monitoredJobsService: MonitoredJobsService) {}

    /**
     * Angular lifecycle method
     * Starts the monitoring of the job
     */
    public ngOnInit(): void {
        this.toastData = new ToastDataModel(EToastType.NEUTRAL, this.jobInput.title, 'Queued', true);
        Toaster.openToast(this.toastData);

        this.monitoredJobsService.getJobMonitor(this.jobInput._id).subscribe({
            next: (job) => this.updateToastData(job),
            error: (error) => ARLogger.error(`jobNotification: ${error}`),
            complete: () => this.monitoredJobsService.removeMonitoredJob(this.jobInput._id)
        });
    }

    private updateToastData(job: JobModel): void {
        switch (job.status) {
            case EJobStatus.QUEUED:
                this.toastData.type = EToastType.NEUTRAL;
                this.toastData.title = job.title;
                this.toastData.message = 'Queued';
                this.toastData.loading = true;
                break;
            case EJobStatus.IN_PROGRESS:
                this.toastData.type = EToastType.NEUTRAL;
                this.toastData.title = job.title;
                this.toastData.message = 'In Progress';
                this.toastData.loading = true;
                break;
            case EJobStatus.FAILED:
                this.toastData.type = EToastType.ERROR;
                this.toastData.title = job.title;
                if (job.result) {
                    this.toastData.message = `Failed: ${job.result}`;
                } else {
                    this.toastData.message = 'Failed';
                    this.toastData.loading = false;
                }
                break;
            case EJobStatus.DONE:
                this.toastData.type = EToastType.SUCCESS;
                this.toastData.title = job.title;
                this.toastData.loading = false;
                if (this.DOWNLOAD_JOBS.includes(job.type)) {
                    this.toastData.message ='Ready to download';
                    this.setDownloadAction(job);
                } else {
                    this.toastData.message = 'Done';
                }
                break;
            default:
                ARLogger.error(`JobNotification: Unhandled Job status ${job.status}`);
        }
    }

    private setDownloadAction(job: JobModel): void {
        const downloadAction = new ToastActionModel(new ToastButtonConfig(BUTTON_TYPE.SECONDARY, 'nucicon_download'));
        downloadAction.observable.subscribe(() => window.open(job.result));

        this.toastData.addAction(downloadAction);
    }
}
