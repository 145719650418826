<div class="container">
        <div class="canvas-container" #container>
            <ng-container *ngIf="publicationItemSignal() as items">
                <video-sticky-notes-view *ngIf="items.length === 1 && items[0].previewType === 'Video'; else containerTemplate"
                                        [src]="items[0].previewImage" [item]="items[0]"
                                         [overlay]="overlay"/>
                <ng-template #containerTemplate>
                    <image-sticky-notes-view [items]="items" (minZoomLevel)="setMinZoomLevel($event)"
                                             [horizontal]="horizontal" [(zoomLevel)]="zoomLevel"
                                            [overlay]="overlay" [placeHolderImage]="placeHolderImage"
                    ></image-sticky-notes-view>
                </ng-template>
            </ng-container>
        </div>

    <div class="controls" *ngIf="publicationItemSignal().length === 1">
        @if (publicationItemSignal()[0].previewType !== 'Video') {
            <nuc-button-secondary (click)="zoomIn()" icon="nucicon_add"
                                  [nucTooltip]="'Zoom in'" [nucTooltipPosition]="'right'"></nuc-button-secondary>
            <nuc-slider step="1" [min]="(minZoomLevel()  * 100)" max="300" vertical="true" [value]="(zoomLevel * 100)"
                        [nucTooltip]="getZoomValue()" [nucTooltipPosition]="'right'" (valueChange)="valueChanged($event)">
            </nuc-slider>
            <nuc-button-secondary (click)="zoomOut()" icon="nucicon_remove"
                                  [nucTooltip]="'Zoom out'" [nucTooltipPosition]="'right'"></nuc-button-secondary>
        }
        <nuc-button-secondary (click)="fitToContainer()" icon="nucicon_images_line"
                              [nucTooltip]="'Fit to canvas'" [nucTooltipPosition]="'right'"></nuc-button-secondary>
        <nuc-button-secondary [disabled]="previewLoading"
                              [icon]="overlay === previewOverlayEnum.NOTES ? 'nucicon_comments_show':
                                          overlay === previewOverlayEnum.NOTES_BACKGROUND ? 'nucicon_comments_popout'
                                          :'nucicon_comments_hide'"
                              [matMenuTriggerFor]="menu"
                              nucTooltip="Note view" nucTooltipPosition="right">
        </nuc-button-secondary>
        <mat-menu #menu="matMenu" xPosition="before" class="menu-items-container">
            <button mat-menu-item
                    *ngFor="let item of previewOverlays"
                    (click)="setOverlay(item.getValue())">
                {{item.getTitle()}}
            </button>
        </mat-menu>
    </div>
    <form class="file-versions" *ngIf="filesVersions" [formGroup]="formGroup">
        <nuc-dropdown formControlName="filesVersion"
                      [nullOption]="false"
                      [total]="filesVersions.length"
                      [items]="filesVersions">
        </nuc-dropdown>
    </form>

</div>
