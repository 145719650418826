import {Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {TemplateService} from '../../../../../../../api/services/templates.service';
import {PublicationModel} from '../../../../../../../models/api/publication.model';
import {of, Subject, Subscription} from 'rxjs';
import {TemplateTypeService} from '../../../../../../../api/services/template-types.service';
import {UserIsAllowedToPipe} from '../../../../../../../pipes/user-is-allowed-to.pipe';
import {EPublicationType} from '../../../../../templates/template-detail/publication-type.enum';
import {EChannelDisplayProperties} from '../../../../../../../pipes/channel-display.pipe';
import {
    BUTTON_TYPE,
    ButtonConfig,
    EColumnType,
    ESortOrder,
    FullModalActionModel,
    FullModalService,
    GetPropertyPipe,
    ISortOptionEvent,
    ITableColumn,
    ITableItem,
    NUC_FULL_MODAL_DATA,
    TooltipConfig
} from '@relayter/rubber-duck';
import {TabBarItemModel} from '../../../../../../../models/ui/tab-bar-item.model';
import {CustomWorkflowActionModel} from '../../../../../../../models/api/custom-workflow-action.model';
import {FormControl, FormGroup} from '@angular/forms';
import {EEngineType, TemplateModel} from '../../../../../../../models/api/template.model';
import {PackageModel} from '../../../../../../../models/api/package.model';
import {CampaignModel} from '../../../../../../../models/api/campaign.model';
import {AppConstants} from '../../../../../../../app.constants';
import {ARPagedResponseDataModel} from '@relayter/core';
import {Toaster} from '../../../../../../../classes/toaster.class';
import {TemplateTypeModel} from '../../../../../../../models/api/template-type.model';
import {switchMap, takeUntil} from 'rxjs/operators';
import {SortDirection} from '@angular/material/sort';
import {PublicationsService} from '../../../../../../../api/services/publications.service';
import {PackagesService} from '../../../../../../../api/services/packages.service';
import {RLTableComponent} from '../../../../../../../components/rl-base-component/rl-table.component';
import {UserSettingsStorageService} from '../../../../../../../api/services/user-settings-storage.service';
import {BriefingActionsService} from '../../../../../../../api/services/briefing-actions.service';
import {CampaignItemResultModel} from '../../../../../../../models/ui/campaign-item-result.model';
import {CampaignItemModel} from '../../../../../../../models/api/campaign-item.model';
import {PaginatorService} from '../../../../../../../components/paginator/paginator.service';
import {VariantModel} from '../../../../../../../models/api/variant.model';
import {DataFieldsComponentUtil} from '../../../../../../../classes/data-fields-component.util';
import {EWorkflowActionOptionName} from '../../../../../../../models/api/custom-workflow-option.model';

export interface ICustomWorkflowSelectionModalData {
    publication: PublicationModel;
    campaignItem?: CampaignItemModel;
    hasNext?: boolean;
    campaignItemIds?: string[];
    action: CustomWorkflowActionModel;
    campaign: CampaignModel;
    multiSelection: boolean;
    activeVariant?: VariantModel;
}

interface IAddItemActionOptions {
    addPackageData: { name: EWorkflowActionOptionName.ADD_PACKAGE_DATA; value: boolean };
    addNotes: { name: EWorkflowActionOptionName.ADD_NOTES; value: boolean };
    engineType: { name: EWorkflowActionOptionName.ENGINE_TYPE; value: EEngineType };
    templateFilterCampaignTags: { name: EWorkflowActionOptionName.TEMPLATE_FILTER_CAMPAIGN_TAGS; value: boolean };
}

interface IItemBody {
    templateId: string;
    campaignItemId: string;
    packageData?: { // work with ADD_PACKAGE_DATA option
        packageId: string;
        dataFields?: Record<string, any>;
    };
    note?: string; // work with ADD_NOTES option
}

@Component({
    selector: 'custom-workflow-selection-modal-component',
    templateUrl: './custom-workflow-selection-modal.component.html',
    styleUrls: ['./custom-workflow-selection-modal.component.scss'],
    providers: [PaginatorService],
    host: {
        '[class.no-tab-bar]': 'noTabBar'
    }
})
export class CustomWorkflowSelectionModalComponent extends RLTableComponent implements OnInit, OnDestroy {
    public tableId = 'custom-workflow-selection-template-table';

    // general
    private onDestroySubject = new Subject<void>();
    public EPublicationType = EPublicationType;
    public EChannelDisplayProperties = EChannelDisplayProperties;
    public permissions = AppConstants.PERMISSIONS;
    public templateSubscription: Subscription;
    public packageSubscription: Subscription;

    public noTemplateStateTitle: string;
    public packageDataForm: FormGroup;
    public notesForm: FormGroup;

    public selectedTemplates: TemplateModel[] = []; // selectedTemplates is only to used to show the name in the template data form
    public selectedPackage: PackageModel;

    // data from modal
    public publication: PublicationModel;
    public campaignItem: CampaignItemModel;
    public nextItemTooltipItem: string;
    public campaignItemIds: string[] = [];
    public campaign: CampaignModel;
    public action: CustomWorkflowActionModel;
    public addItemOptions: IAddItemActionOptions;

    private hasNext = false;

    // modal config
    private cancelButton: ButtonConfig;
    private cancelAction: FullModalActionModel;
    private addAndCloseButton: ButtonConfig;
    private addAndCloseAction: FullModalActionModel;
    private onAddAndCloseClicked: () => void;
    private addAndGoNextButton: ButtonConfig;
    private addAndGoNextAction: FullModalActionModel;
    private onAddAndGoNextClicked: () => void;

    private multiSelection: boolean;

    // template selection table related
    public columns: ITableColumn[] = [
        {
            title: 'Template name',
            key: 'name',
            type: EColumnType.DEFAULT,
            sortProperty: 'name'
        } as ITableColumn
    ];
    public total: number;
    public pageIndex = 1;
    public pageSize = AppConstants.PAGE_SIZE_DEFAULT;
    public sortOrder: SortDirection;
    public sortProperty: string;
    public templates: TemplateModel[] = []; // templates of the current page

    public templateTypes: TemplateTypeModel[] = [];
    public totalTemplateTypes: number;
    public selectedTemplateType: TemplateTypeModel;
    public templateTypeControl = new FormControl();
    private campaignItemFields: string[];
    public disableNextPage: boolean;

    public get selectedTemplateIds(): string[] {
        return this.selectedTemplates.map((template) => template._id);
    }

    // package data related
    public packages: PackageModel[] = [];
    public totalPackages: number;
    public packageControl = new FormControl();

    // tab bar related
    public TAB_TEMPLATES = 0;
    public templatesTab = new TabBarItemModel('Templates', this.TAB_TEMPLATES);
    public tabbarItems: TabBarItemModel[] = [this.templatesTab];

    public TAB_NOTES: number;
    public TAB_PACKAGE_DATA: number;
    public notesTab: TabBarItemModel;
    public packageDataTab: TabBarItemModel;
    public noTabBar = false;
    private activeVariant: VariantModel;

    public get addPackageData(): boolean {
        return this.addItemOptions?.addPackageData?.value === true;
    }

    public get addNotes(): boolean {
        return this.addItemOptions?.addNotes?.value === true;
    }

    private get templateFilterCampaignTags(): string[] {
        return this.addItemOptions?.templateFilterCampaignTags?.value === true ? this.campaign.tags : [];
    }

    private _selectedTab = this.tabbarItems[this.TAB_TEMPLATES];
    public get selectedTab(): TabBarItemModel {
        return this._selectedTab;
    }

    public set selectedTab(tab: TabBarItemModel) {
        if (tab !== this._selectedTab) {
            const index = this.tabbarItems.find((t) => t.title === tab.title).index;
            this._selectedTab = tab;
            this._selectedTab.index = index;
            this.setConfirmButton();
            this.setModalButtons();
            this.updateButtonStatus();
        }
    }

    public get viewId(): string {
        return this.tableId;
    }

    constructor(private templatesService: TemplateService,
                private templateTypeService: TemplateTypeService,
                private userIsAllowedToPipe: UserIsAllowedToPipe,
                private fullModalService: FullModalService,
                private publicationService: PublicationsService,
                private packageService: PackagesService,
                userSettingsStorageService: UserSettingsStorageService,
                @Inject(NUC_FULL_MODAL_DATA) private modalData: ICustomWorkflowSelectionModalData,
                private briefingActionsService: BriefingActionsService,
                private paginatorService: PaginatorService) {
        super(userSettingsStorageService);
    }

    private getActionOptions(): void {
        if (this.action.options) {
            this.addItemOptions = {
                addPackageData: this.action.options.find((option) => option.name === EWorkflowActionOptionName.ADD_PACKAGE_DATA),
                addNotes: this.action.options.find((option) => option.name === EWorkflowActionOptionName.ADD_NOTES),
                engineType: this.action.options.find((option) => option.name === EWorkflowActionOptionName.ENGINE_TYPE),
                templateFilterCampaignTags: this.action.options.find((option) =>
                    option.name === EWorkflowActionOptionName.TEMPLATE_FILTER_CAMPAIGN_TAGS)
            } as IAddItemActionOptions;
        }
    }

    public ngOnInit(): void {
        this.publication = this.modalData.publication;
        this.action = this.modalData.action;
        this.campaign = this.modalData.campaign;
        this.activeVariant = this.modalData.activeVariant;

        this.getActionOptions();
        const channel = this.publication.channel.getTitle();
        this.noTemplateStateTitle = this.addItemOptions?.templateFilterCampaignTags?.value === true
            ? `You cannot generate ${channel} items until you’ve created a ${channel} template with the appropriate tag(s).`
            : `You cannot generate ${channel} items until you’ve created a ${channel} template.`;
        this.setTableColumns();
        this.getTemplateTypes(AppConstants.PAGE_SIZE_DEFAULT, 0);
        this.listenToTemplateTypeControl();

        if (this.modalData?.multiSelection) {
            this.multiSelection = true;
            this.tableId = 'custom-workflow-multi-selection-template-table';
            this.campaignItemIds = this.modalData.campaignItemIds;
            this.setPageIndex(); // trigger getting data
        }

        if (this.addPackageData) {
            this.TAB_PACKAGE_DATA = this.tabbarItems.length;
            this.packageDataTab = new TabBarItemModel('Package data', this.TAB_PACKAGE_DATA);
            this.tabbarItems.push(this.packageDataTab);
            this.getPackages(AppConstants.PAGE_SIZE_DEFAULT, 0);
            this.listenToPackageControl();
        }

        if (this.addNotes) {
            this.TAB_NOTES = this.tabbarItems.length;
            this.notesTab = new TabBarItemModel('Notes', this.TAB_NOTES);
            this.packageDataTab = new TabBarItemModel('Package data', this.TAB_PACKAGE_DATA);
            this.tabbarItems.push(this.notesTab);
            this.notesForm = new FormGroup({});
        }

        this.noTabBar = this.tabbarItems.length === 1;

        this.initModalButtons();
        this.resetForm();

        if (!this.multiSelection) {
            const itemFields = this.action.options?.find(option => option.name === EWorkflowActionOptionName.CAMPAIGN_ITEM_FIELDS)?.value;
            this.campaignItemFields = Array.isArray(itemFields) ? itemFields : [];

            this.briefingActionsService.campaignItem$
                .pipe(takeUntil(this.onDestroySubject))
                .subscribe((result: CampaignItemResultModel) => {
                    if (result.campaignItems.length === 0) {
                        return this.fullModalService.close();
                    }

                    this.hasNext = result.hasNext;
                    this.campaignItem = result.campaignItems[0];
                    this.campaignItemIds = [this.campaignItem._id];
                    this.setPageIndex(); // reset pageIndex

                    this.addAndCloseButton.loading = false;
                    if (this.addAndGoNextButton) {
                        this.addAndGoNextButton.loading = false;
                        this.addAndGoNextButton.tooltip = null;

                        const nextCampaignItem = result.campaignItems.length > 1 ? result.campaignItems[1] : null;
                        if (nextCampaignItem) {
                            this.nextItemTooltipItem = this.getCampaignItemDescription(nextCampaignItem, 'Next item:', false);
                        }

                        this.addAndGoNextButton.tooltip = new TooltipConfig(this.nextItemTooltipItem);
                    }
                    this.resetForm();
                });
        }

        this.paginatorService.getPagination(this.viewId)
            .pipe(takeUntil(this.onDestroySubject))
            .subscribe((pagination) => {
                this.pageIndex = pagination.pageIndex;
                this.pageSize = pagination.pageSize;

                this.getTemplates();
            });
    }

    public ngOnDestroy(): void {
        this.onDestroySubject.next();
        this.onDestroySubject.complete();
    }

    public resetForm(): void {
        // Reset values, like a new selected campaign item
        this.selectedTemplates = [];
        this.searchValue = '';

        let description = 'Select the template(s) for your item.';
        if (!this.multiSelection && this.campaignItemFields?.length > 0) {
            description = 'Select the template(s) for item:';
            description = this.getCampaignItemDescription(this.campaignItem, description);
        }
        this.fullModalService.setDescription(description);

        this.selectedTab = this.templatesTab;

        if (this.addNotes) this.setupNotesForm();
        if (this.addPackageData) this.setupPackageDataForm();
    }

    private setTableColumns(): void {
        if (this.publication.channel.isPosChannel()) {
            this.columns.push({
                title: 'Template type',
                key: 'templateType.name',
                type: EColumnType.DEFAULT,
                sortProperty: 'templateType.name',
                sortDuplicates: true
            } as ITableColumn);
        }
        if (!this.multiSelection || this.campaignItemIds.length === 1) {
            this.columns.push({
                title: 'Generated',
                key: 'itemsGenerated',
                type: EColumnType.ICON,
                iconClass: (value) => value > 0 ? 'nucicon_check_round_fill' : '',
                format: (value) => value > 0 ? value : 'No items',
                color: AppConstants.FIRST_BRAND_COLOR,
                sortProperty: 'itemsGenerated',
                sortDuplicates: true
            } as ITableColumn);
        }
    }

    private initModalButtons(): void {
        this.cancelButton = new ButtonConfig(BUTTON_TYPE.SECONDARY, 'Cancel');
        this.cancelAction = new FullModalActionModel(this.cancelButton);
        this.cancelAction.observable.subscribe(() => this.fullModalService.close(null, true));

        // initialized button state
        if (this.tabbarItems.length === 1) {
            this.addAndCloseButton = new ButtonConfig(BUTTON_TYPE.PRIMARY, 'Add', null, null, true);
            this.onAddAndCloseClicked = this.onSubmit;
        } else {
            this.addAndCloseButton = new ButtonConfig(BUTTON_TYPE.PRIMARY, 'Next', null, null, false);
            this.onAddAndCloseClicked = this.nextAction;

            if (!this.multiSelection) {
                this.addAndGoNextButton = new ButtonConfig(BUTTON_TYPE.PRIMARY, 'Add & Next briefing item', null, null, false);
                this.onAddAndGoNextClicked = this.onSubmitAndGoNext;

                this.addAndGoNextAction = new FullModalActionModel(this.addAndGoNextButton);
                this.addAndGoNextAction.observable.subscribe(() => this.onAddAndGoNextClicked());
            }
        }

        this.addAndCloseAction = new FullModalActionModel(this.addAndCloseButton);
        this.addAndCloseAction.observable.subscribe(() => this.onAddAndCloseClicked());

        this.setModalButtons();
    }

    private setModalButtons(): void {
        switch (this.selectedTab) {
            case this.templatesTab:
                this.fullModalService.setModalActions([this.cancelAction, this.addAndCloseAction]);
                break;
            case this.notesTab:
            case this.packageDataTab:
                if (this.selectedTab.index < this.tabbarItems.length - 1 || this.multiSelection) {
                    this.fullModalService.setModalActions([this.cancelAction, this.addAndCloseAction]);
                } else {
                    this.fullModalService.setModalActions([this.cancelAction, this.addAndCloseAction, this.addAndGoNextAction]);
                }
                break;
        }
    }

    public getTemplates(): void {
        if (this.templateSubscription) this.templateSubscription.unsubscribe();

        this.templateSubscription = this.templatesService
            .getTemplates(
                this.publication.channel.name,
                this.addItemOptions?.engineType?.value || EEngineType.INDESIGN, // default engineType is INDESIGN
                this.pageSize,
                (this.pageIndex - 1) * this.pageSize,
                null,
                this.templateFilterCampaignTags,
                this.searchValue,
                this.selectedTemplateType?._id,
                this.sortProperty,
                this.sortOrder,
                this.publication._id,
                this.campaignItemIds.length === 1 ? this.campaignItemIds[0] : null)
            .pipe(takeUntil(this.onDestroySubject))
            .subscribe({
                next: (result: ARPagedResponseDataModel<TemplateModel>) => {
                    this.total = result.total;
                    this.templates = result.items;

                    this.disableNextPage = this.total <= this.pageIndex * this.pageSize;
                },
                error: Toaster.handleApiError
            });
    }

    public getTemplateTypes(limit: number, offset: number): void {
        if (!this.publication.channel.isPosChannel() || !this.userIsAllowedToPipe.transform(this.permissions.GET_TEMPLATE_TYPES)) return;
        this.templateTypeService.getTemplateTypes(limit, offset)
            .pipe(takeUntil(this.onDestroySubject))
            .subscribe({
                next: (result: ARPagedResponseDataModel<TemplateTypeModel>) => {
                    this.templateTypes = this.templateTypes.concat(result.items);
                    this.totalTemplateTypes = result.total;
                },
                error: Toaster.handleApiError
            });
    }

    public onSubmit(goToNextItem?: boolean): void {
        const items = this.selectedTemplateIds.reduce((acc, templateId) => {
            return acc.concat(this.campaignItemIds.map((campaignItemId) => {
                const item = {templateId, campaignItemId} as IItemBody;
                if (this.addPackageData && this.selectedPackage) {
                    item.packageData = {
                        packageId: this.selectedPackage._id,
                        dataFields: this.packageDataForm?.controls[templateId]?.value || {}
                    };
                }

                if (this.addNotes) item.note = this.notesForm?.controls[templateId]?.value?.note || '';
                return item;
            }));
        }, []);

        this.addAndCloseButton.loading = true;
        if (this.addAndGoNextButton) {
            this.addAndGoNextButton.loading = true;
        }

        this.publicationService.postTransitionItem(this.publication._id, this.action.transition, {items})
            .pipe(takeUntil(this.onDestroySubject))
            .subscribe({
                next: (transitionItem) => {
                    this.briefingActionsService.transitionPosted(transitionItem._id);

                    if (!goToNextItem) {
                        this.fullModalService.close(true);
                    } else {
                        this.briefingActionsService.requestCampaignItem();
                    }
                },
                error: Toaster.handleApiError
            });
    }

    private onSubmitAndGoNext(): void {
        this.onSubmit(true);
    }

    public onSearchBarValueUpdated(value: string): void {
        if (this.searchValue !== value) {
            this.searchValue = value;
            this.setPageIndex();  // reset pageIndex
        }
    }

    private setPageIndex(pageIndex = 1): void {
        this.paginatorService.setPageIndex(this.viewId, pageIndex);
    }

    private listenToTemplateTypeControl(): void {
        this.templateTypeControl.valueChanges
            .pipe(takeUntil(this.onDestroySubject))
            .subscribe((value: TemplateTypeModel) => {
                this.selectedTemplateType = value;
                this.setPageIndex(); // reset pageIndex
            });
    }

    public onSortOptionChanged(event: ISortOptionEvent): void {
        if (event.column?.sortProperty) {
            this.sortProperty = event.column?.sortProperty;
            this.sortOrder = event.sortOrder === ESortOrder.ASC ? 'asc' : 'desc';
        } else {
            this.sortProperty = '';
            this.sortOrder = '';
        }

        this.setPageIndex();
    }

    public onSelectionChanged(selectedTemplates: ITableItem[]): void {
        this.selectedTemplates = selectedTemplates as TemplateModel[];
        // update the forms
        if (this.addNotes) this.setupNotesForm();
        if (this.addPackageData) this.setupPackageDataForm();
        this.updateButtonStatus();
    }

    private setupNotesForm(): void {
        for (const templateId of this.selectedTemplateIds) {
            this.notesForm.addControl(templateId, new FormGroup({note: new FormControl()}));
        }
        // remove unselected template controls
        Object.keys(this.notesForm.controls).forEach((controlName) => {
            if (!this.selectedTemplateIds.includes(controlName)) this.notesForm.removeControl(controlName);
        });
    }

    private setupPackageDataForm(): void {
        // make sure formGroup is correct
        if (!this.selectedPackage?.packageSetup?.materialDataFields.length || !this.selectedTemplateIds.length) {
            this.packageDataForm = null;
            return;
        }
        // add controls for newly selected template
        this.packageDataForm = this.packageDataForm || new FormGroup({});
        for (const templateId of this.selectedTemplateIds) {
            if (!Object.keys(this.packageDataForm.controls).find((controlName) => controlName === templateId)) {
                const templateFormGroup = new FormGroup({});
                this.selectedPackage.packageSetup.materialDataFields.forEach((dataField) => {
                    templateFormGroup.addControl(dataField.fieldName, new FormControl(''));
                });
                this.packageDataForm.addControl(templateId, templateFormGroup);
            }
        }
        // remove unselected template controls
        Object.keys(this.packageDataForm.controls).forEach((controlName) => {
            if (!this.selectedTemplateIds.includes(controlName)) this.packageDataForm.removeControl(controlName);
        });
    }

    public getPackages(limit: number, offset: number): void {
        if (!this.userIsAllowedToPipe.transform(this.permissions.GET_PACKAGES)) return;
        this.packageSubscription = this.packageService.getPackagesForCampaign(this.campaign._id, limit, offset)
            .pipe(takeUntil(this.onDestroySubject))
            .subscribe({
                next: (result) => {
                    this.totalPackages = result.total;
                    this.packages = this.packages.concat(result.items);
                    if (this.packages.length && !this.packageControl.value) { // initialize the value
                        this.packageControl.patchValue(this.packages[0]);
                    }
                },
                error: Toaster.handleApiError
            });
    }

    private listenToPackageControl(): void {
        this.packageControl.valueChanges
            .pipe(
                switchMap((value: PackageModel) => {
                    return value?._id && this.userIsAllowedToPipe.transform(this.permissions.GET_PACKAGE_DETAILS) ?
                        this.packageService.getPackageForCampaign(this.campaign._id, value._id) : of(null);
                }),
                takeUntil(this.onDestroySubject)
            )
            .subscribe({
                next: (value: PackageModel) => {
                    this.selectedPackage = value;
                    this.packageDataForm = new FormGroup({}); // always reset formGroup
                    this.setupPackageDataForm();
                },
                error: Toaster.handleApiError
            });
    }

    private updateButtonStatus(): void {
        const disabled = this.selectedTab === this.tabbarItems[this.tabbarItems.length - 1] && this.selectedTemplates.length === 0;
        this.addAndCloseButton.disabled = disabled;
        if (this.addAndGoNextButton) {
            this.addAndGoNextButton.disabled = disabled || !this.hasNext;
        }
    }

    private setConfirmButton(): void {
        if (this.selectedTab.index < this.tabbarItems.length - 1) {
            this.addAndCloseButton.text = 'Next';
            this.onAddAndCloseClicked = this.nextAction;
        } else {
            this.addAndCloseButton.text = this.multiSelection || this.tabbarItems.length === 1 ? 'Generate' :
                'Add & Close';
            this.onAddAndCloseClicked = this.onSubmit;
        }
    }

    private nextAction(): void {
        this.selectedTab = this.tabbarItems.find((tab) => tab.index === this.selectedTab.index + 1);
    }

    private getCampaignItemDescription(campaignItem: CampaignItemModel, description = '', strong = true): string {
        for (const field of this.campaignItemFields) {
            const value = GetPropertyPipe.transform(campaignItem, field,
                DataFieldsComponentUtil.getDataFieldFormatter(null, this.activeVariant?.key));
            if (value) {
                description += ` ${strong ? '<strong>' : ''}${value}${strong ? '</strong>' : ''}`;
            }
        }

        return description;
    }
}
