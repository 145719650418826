
<div class="image-container">
    <img class="logo" [src]="logo">
</div>
<form [formGroup]="form">
    <nuc-form-field label="Name">
        <nuc-input formControlName="name"
                   type="string"
                   placeholder="Fill in your connection name">
        </nuc-input>

        <ng-container *ngIf="form.controls.name.touched && form.controls.name.errors as error">
            <nuc-error *ngIf="error.required">Name required</nuc-error>
        </ng-container>
    </nuc-form-field>
</form>
