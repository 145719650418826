<form *ngIf="formGroup else loadingIndicator" [formGroup]="formGroup">
    <nuc-form-field label="Type">
        <nuc-dropdown formControlName="type"
                      [nullOption]="false"
                      [total]="actionTypeOptions.length"
                      [items]="actionTypeOptions"
                      placeholder="Select an action type">
        </nuc-dropdown>
    </nuc-form-field>

    <nuc-form-field label="Name">
        <nuc-dropdown formControlName="name"
                      [nullOption]="false"
                      [searchable]="true"
                      (requestData)="searchNames($event)"
                      [total]="nameOptions.length"
                      [items]="nameOptions"
                      placeholder="Select an action name">
        </nuc-dropdown>
    </nuc-form-field>

    <nuc-form-field label="Title">
        <nuc-input formControlName="title" placeholder="Enter title"></nuc-input>
    </nuc-form-field>

    <nuc-form-field label="Transition" *ngIf="formGroup.controls.transition">
        <nuc-dropdown formControlName="transition"
                      [nullOption]="false"
                      [searchable]="true"
                      (requestData)="searchTransitions($event)"
                      [total]="transitionOptions.length"
                      [items]="transitionOptions"
                      placeholder="Select a transition">
        </nuc-dropdown>
    </nuc-form-field>

    <nuc-form-field label="Icon">
        <nuc-dropdown formControlName="icon"
                      [nullOption]="false"
                      [searchable]="true"
                      (requestData)="searchIcons($event)"
                      [total]="nucIconOptions.length"
                      [items]="nucIconOptions"
                      placeholder="Select an icon">
        </nuc-dropdown>
    </nuc-form-field>

    <nuc-form-field label="Permissions">
        <nuc-dropdown-multiselect
            formControlName="permissions"
            [items]="permissions"
            placeholder="Select permissions">
        </nuc-dropdown-multiselect>
    </nuc-form-field>

    <nuc-form-field label="Note status from" *ngIf="formGroup.controls.from">
        <nuc-dropdown-multiselect formControlName="from"
                                  [items]="noteFromStatus"
                                  placeholder="Select note status from">
        </nuc-dropdown-multiselect>
    </nuc-form-field>



    <nuc-form-field label="Note status to" *ngIf="formGroup.controls.to">
        <nuc-dropdown formControlName="to"
                      [total]="noteToStatus.length"
                      [items]="noteToStatus"
                      placeholder="Select note status to">
        </nuc-dropdown>
    </nuc-form-field>

    <div *ngIf="formGroup.controls.options" class="options-form">
        <h2>Action options</h2>
        <p>Set the options for the action</p>

        <hr />

        <form [formGroup]="optionsFormGroup">

            <ng-container *ngFor="let control of optionsFormGroup.controls | keyvalue">
                <ng-container [ngSwitch]="control.key">
                    <div *ngSwitchCase="EWorkflowActionOptionName.ADD_PACKAGE_DATA" class="boolean-field">
                        <nuc-form-field label="Add package data"></nuc-form-field>
                        <!--text and textPosition is used to fix styling-->
                        <nuc-checkbox [formControlName]="control.key" text=" " textPosition="start"></nuc-checkbox>
                    </div>

                    <div *ngSwitchCase="EWorkflowActionOptionName.ADD_NOTES" class="boolean-field">
                        <nuc-form-field label="Add notes"></nuc-form-field>
                        <!--text and textPosition is used to fix styling-->
                        <nuc-checkbox [formControlName]="control.key" text=" " textPosition="start"></nuc-checkbox>
                    </div>

                    <div *ngSwitchCase="EWorkflowActionOptionName.AUTO_ASSIGN" class="boolean-field">
                        <nuc-form-field label="Auto assign"></nuc-form-field>
                        <!--text and textPosition is used to fix styling-->
                        <nuc-checkbox [formControlName]="control.key" text=" " textPosition="start"></nuc-checkbox>
                    </div>

                    <div *ngSwitchCase="EWorkflowActionOptionName.TEMPLATE_FILTER_CAMPAIGN_TAGS" class="boolean-field">
                        <nuc-form-field label="Filter templates by campaign tags"></nuc-form-field>
                        <!--text and textPosition is used to fix styling-->
                        <nuc-checkbox [formControlName]="control.key" text=" " textPosition="start"></nuc-checkbox>
                    </div>

                    <div *ngSwitchCase="EWorkflowActionOptionName.ADD_FOR_RELATED_PUBLICATION_ITEMS" class="boolean-field">
                        <nuc-form-field label="Add to related publication items"></nuc-form-field>
                        <!--text and textPosition is used to fix styling-->
                        <nuc-checkbox [formControlName]="control.key" text=" " textPosition="start"></nuc-checkbox>
                    </div>

                    <div *ngSwitchCase="EWorkflowActionOptionName.ENGINE_TYPE" class="dropdown-field">
                        <nuc-form-field label="Engine type">
                            <nuc-dropdown [formControlName]="control.key"
                                          [nullOption]="false"
                                          [total]="engineTypes.length"
                                          [items]="engineTypes"
                                          placeholder="Select an engine type">
                            </nuc-dropdown>
                        </nuc-form-field>
                    </div>

                    <div *ngSwitchCase="EWorkflowActionOptionName.DISPLAY" class="dropdown-field">
                        <!--Use formControl instead of formControlName here, to avoid formControl reference to get lost/confused-->
                        <nuc-form-field label="Choose the display properties from the campaign item" *ngIf="displayType === EWorkflowActionOptionDisplayType.MULTI">
                            <nuc-dropdown-multiselect [formControl]="optionsFormGroup.controls[control.key]"
                                                      [items]="displayOptions"
                                                      placeholder="Select display properties">
                            </nuc-dropdown-multiselect>
                        </nuc-form-field>

                        <nuc-form-field label="Choose the display property from the campaign item" *ngIf="displayType === EWorkflowActionOptionDisplayType.SINGLE">
                            <nuc-dropdown [formControl]="optionsFormGroup.controls[control.key]"
                                          [items]="displayOptions"
                                          placeholder="Select display property">
                            </nuc-dropdown>
                        </nuc-form-field>
                    </div>

                    <div *ngSwitchCase="EWorkflowActionOptionName.CAMPAIGN_ITEM_FIELDS" class="dropdown-field">
                        <nuc-form-field label="Choose the display properties from the campaign item">
                            <nuc-dropdown-multiselect [formControlName]="control.key"
                                                      [items]="campaignItemFieldOptions"
                                                      placeholder="Select display properties">
                            </nuc-dropdown-multiselect>
                        </nuc-form-field>
                    </div>

                    <div *ngSwitchCase="EWorkflowActionOptionName.PACKAGES" [formGroupName]="control.key">
                        <nuc-form-field label="Select package types">
                            <nuc-dropdown-multiselect formControlName="packageTypes"
                                                      [items]="packageOptions"
                                                      placeholder="Select package types">
                            </nuc-dropdown-multiselect>
                        </nuc-form-field>

                        <div class="boolean-field">
                            <nuc-form-field label="Use custom export package"></nuc-form-field>
                            <!--text and textPosition is used to fix styling-->
                            <nuc-checkbox formControlName="customExportPackage" text=" " textPosition="start"></nuc-checkbox>
                        </div>

                        <ng-container *ngIf="optionsFormGroup.controls[control.key].controls['customExportPackageData']">
                            <div formGroupName="customExportPackageData">
                                <nuc-form-field label="Name">
                                    <nuc-input formControlName="NAME"></nuc-input>
                                </nuc-form-field>
                                <nuc-form-field label="Description">
                                    <nuc-input formControlName="DESCRIPTION"></nuc-input>
                                </nuc-form-field>
                                <nuc-form-field label="Format">
                                    <nuc-dropdown formControlName="FORMAT"
                                                  [nullOption]="false"
                                                  [total]="formatOptions.length"
                                                  [items]="formatOptions"
                                                  placeholder="Select a format">
                                    </nuc-dropdown>
                                </nuc-form-field>

                                <h2>Size</h2>
                                <p>Set up size related data</p>
                                <hr />

                                <nuc-form-field label="Scale type">
                                    <nuc-dropdown formControlName="scaleType"
                                                  [nullOption]="false"
                                                  [total]="scaleTypes.length"
                                                  [items]="scaleTypes"
                                                  placeholder="Select a scale type">
                                    </nuc-dropdown>
                                </nuc-form-field>

                                <nuc-form-field label="Width">
                                    <nuc-input type="number" min="1" max="20000" formControlName="width" placeholder="Fill in the width"></nuc-input>
                                </nuc-form-field>

                                <nuc-form-field label="Height">
                                    <nuc-input type="number" min="1" max="20000" formControlName="height" placeholder="Fill in the height"></nuc-input>
                                </nuc-form-field>
                            </div>
                        </ng-container>
                    </div>

                    <div *ngSwitchCase="EWorkflowActionOptionName.NOTIFICATION">
                        <div class="boolean-field">
                            <nuc-form-field label="Send notifications"></nuc-form-field>
                            <!--text and textPosition is used to fix styling-->
                            <nuc-checkbox [formControl]="optionsFormGroup.controls[control.key].controls['sendNotifications']" text=" " textPosition="start"></nuc-checkbox>
                        </div>
                        <workflow-configuration-action-notification-form
                            *ngIf="optionsFormGroup.controls[control.key].controls['notificationData']"
                            [noteToStatus]="noteToStatus"
                            [campaignItemFieldOptions]="displayOptions"
                            [initialValue]="notificationOptionValue"
                            [formGroup]="optionsFormGroup.controls[control.key].controls['notificationData']">
                        </workflow-configuration-action-notification-form>
                    </div>
                </ng-container>
            </ng-container>
        </form>
    </div>
</form>

<ng-template #loadingIndicator>
    <rl-loading-indicator size="large"></rl-loading-indicator>
</ng-template>
