import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {Subject, Subscription} from 'rxjs';
import {UntypedFormGroup} from '@angular/forms';
import {takeUntil} from 'rxjs/operators';
import {MasterPageModel} from '../../../../../../models/api/master-page.model';
import {IndesignLibraryModel} from '../../../../../../modules/format-rulesets/models/api/indesign-library.model';
import {FormatRulesetService} from '../../../../../../api/services/format-ruleset.service';
import {FormatRulesetModel} from '../../../../../../modules/format-rulesets/models/api/format-ruleset.model';
import {Toaster} from '../../../../../../classes/toaster.class';
import {EPublicationType} from '../../publication-type.enum';
import {IDropdownRequestDataEvent} from '@relayter/rubber-duck/lib/atoms/dropdown/dropdown.component';
import {MasterPagesService} from '../../../../../../api/services/master-pages.service';
import {IndesignLibraryService} from '../../../../../../api/services/indesign-library.service';
import {VariantModel} from '../../../../../../models/api/variant.model';
import {DropdownComponent} from '@relayter/rubber-duck';

@Component({
    selector: 'template-variant-preset',
    templateUrl: 'template-variant-preset.component.html',
    styleUrls: ['template-variant-preset.component.scss']
})

export class TemplateVariantPresetComponent implements OnInit, OnDestroy {
    public static REQUEST_LIMIT = DropdownComponent.DEFAULT_LIMIT;

    @Input() public formGroup: UntypedFormGroup;
    @Input() public publicationType: EPublicationType;
    public selectedVariant: VariantModel;

    public selectedLibrary: IndesignLibraryModel;
    public selectedFormatRuleset: FormatRulesetModel;

    public masterPages: MasterPageModel[] = [];
    public masterPageSubscription: Subscription;
    private masterPagePage = 0;
    public totalMasterPages: number;

    public libraries: IndesignLibraryModel[] = [];
    public librarySubscription: Subscription;
    private libraryPage = 0;
    public totalLibraries: number;

    public ruleSets: FormatRulesetModel[] = [];
    public ruleSetSubscription: Subscription;
    private ruleSetPage = 0;
    public totalFormatRulesets: number;

    private onDestroySubject = new Subject<void>();

    constructor(private masterPageService: MasterPagesService,
                private libraryService: IndesignLibraryService,
                private formatRulesetService: FormatRulesetService) {
    }

    public ngOnInit(): void {
        this.initData();

        this.formGroup.get('library').valueChanges
            .pipe(takeUntil(this.onDestroySubject))
            .subscribe(library => this.onLibraryChanged(library));

        this.formGroup.get('ruleSet').valueChanges
            .pipe(takeUntil(this.onDestroySubject))
            .subscribe(ruleSet => this.selectedFormatRuleset = ruleSet);
    }

    public ngOnDestroy(): void {
        this.onDestroySubject.next();
        this.onDestroySubject.complete();
    }

    private initData(): void {
        this.selectedVariant = this.formGroup.value.variant;
        this.selectedLibrary = this.formGroup.value.library;
        this.selectedFormatRuleset = this.formGroup.value.ruleSet;

        this.getMasterPages();
        this.getIndesignLibraries();
        if (this.selectedLibrary) {
            if (this.formGroup.get('ruleSet').disabled) this.formGroup.get('ruleSet').enable();
            this.getFormatRulesets();
        } else {
            this.formGroup.get('ruleSet').disable();
        }
    }

    public getMasterPages(event?: IDropdownRequestDataEvent): void {
        if (event?.reset) {
            this.masterPages = [];
            this.masterPagePage = 0;
            this.totalMasterPages = 0;
            this.masterPageSubscription?.unsubscribe();
        }

        // If there is an active subscription, don't respond to data request
        if (this.masterPageSubscription && !this.masterPageSubscription.closed) {
            return;
        }

        this.masterPageSubscription = this.masterPageService.getMasterPages(this.publicationType, TemplateVariantPresetComponent.REQUEST_LIMIT,
            this.masterPagePage * TemplateVariantPresetComponent.REQUEST_LIMIT, 'name', 'asc', event?.search)
            .pipe(takeUntil(this.onDestroySubject))
            .subscribe(
                (result) => {
                    this.masterPagePage += 1;
                    this.masterPages = this.masterPages.concat(result.items);
                    this.totalMasterPages = result.total;
                });
    }

    public getIndesignLibraries(event?: IDropdownRequestDataEvent): void {
        if (event?.reset) {
            this.libraryPage = 0;
            this.libraries = [];
            this.totalLibraries = 0;
            this.librarySubscription?.unsubscribe();
        }

        // If there is an active subscription, don't respond to data request
        if (this.librarySubscription && !this.librarySubscription.closed) {
            return;
        }

        this.librarySubscription = this.libraryService.getIndesignLibraries(TemplateVariantPresetComponent.REQUEST_LIMIT,
            this.libraryPage * TemplateVariantPresetComponent.REQUEST_LIMIT, 'name', 'asc', event?.search)
            .pipe(takeUntil(this.onDestroySubject))
            .subscribe(
                (result) => {
                    this.libraryPage += 1;
                    this.libraries = this.libraries.concat(result.items);
                    this.totalLibraries = result.total;
                });
    }

    public getFormatRulesets(event?: IDropdownRequestDataEvent): void {
        if (event?.reset) {
            this.ruleSets = [];
            this.ruleSetPage = 0;
            this.totalFormatRulesets = 0;
            this.ruleSetSubscription?.unsubscribe();
        }

        // If there is an active subscription, don't respond to data request
        if (this.ruleSetSubscription && !this.ruleSetSubscription.closed) {
            return;
        }

        this.ruleSetSubscription = this.formatRulesetService.getFormatRulesets(this.selectedLibrary._id,
            TemplateVariantPresetComponent.REQUEST_LIMIT, this.ruleSetPage * TemplateVariantPresetComponent.REQUEST_LIMIT,
            null, 'name', 'asc', event?.search)
            .pipe(takeUntil(this.onDestroySubject))
            .subscribe(
                (result) => {
                    this.ruleSetPage += 1;
                    this.ruleSets = this.ruleSets ? [...this.ruleSets, ...result.items] : result.items;
                    this.totalFormatRulesets = result.total;
                },
                (error) => Toaster.handleApiError(error));
    }

    public onLibraryChanged(library: IndesignLibraryModel): void {
        this.selectedLibrary = library;
        this.ruleSetSubscription?.unsubscribe();

        this.ruleSetPage = 0;
        this.ruleSets = [];
        this.totalFormatRulesets = 0;

        if (!library || !this.selectedFormatRuleset?.libraries.includes(this.selectedLibrary?._id)) {
            this.formGroup.patchValue({ruleSet: null});
        }

        if (this.selectedLibrary) {
            if (this.formGroup.get('ruleSet').disabled) {
                this.formGroup.get('ruleSet').enable({emitEvent: false});
            }
            this.getFormatRulesets();
        } else {
            this.formGroup.get('ruleSet').disable({emitEvent: false});
        }
    }
}
