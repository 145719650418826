import {Pipe, PipeTransform} from '@angular/core';
import {PublicationItemModel} from '../models/api/publication-item.model';
import {ICardMediaType} from '@relayter/rubber-duck';

export enum EPublicationMediaProperties {
    THUMBNAIL,
    PREVIEW}

@Pipe({
    name: 'publicationItemMedia'
})
export class PublicationItemMediaPipe implements PipeTransform {

    public transform(publicationItem: PublicationItemModel, property: EPublicationMediaProperties|string, variant?: string): ICardMediaType {
        switch (property) {
            case EPublicationMediaProperties.THUMBNAIL:
                return publicationItem.getVariantFiles(variant)?.thumb?.media;
            case EPublicationMediaProperties.PREVIEW:
                return publicationItem.getVariantFiles(variant)?.preview?.media;
            default:
                throw new Error(`PublicationItemDisplayPipe: Unhandled property ${property} on publicationItem ${publicationItem}`);
        }
    }
}
