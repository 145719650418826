<ng-container *ngIf="permissions.GET_USERS | userIsAllowedTo else noPermissionState">
    <nuc-table [items]="users"
               [columns]="columns"
               [actions]="actions"
               (actionClicked)="handleTableRowAction($event)"
               [selectionMode]="selectionMode"
               [loading]="!usersSubscription?.closed"
               emptyStateTitle="This section is empty. Add the first item."
               (itemClicked)="onRowClicked($event)"
               [tableId]="tableId"
               [prefixStorageKey]="storageKey"
               (sortOptionChange)="onSortOptionChanged($event)">
        <nuc-input button-bar-left
                   class="search"
                   [(data)]="searchValue"
                   prefix="nucicon_search"
                   (debounceValueChanged)="setPageIndex()"
                   placeholder="Search users">
        </nuc-input>

        <paginator button-bar-right
                   [viewId]="tableId"
                   [disableNextPage]="disableNextPage"
                   [loading]="!usersSubscription?.closed">
        </paginator>
    </nuc-table>
</ng-container>

<ng-template #noPermissionState>
    <no-permission-state-component></no-permission-state-component>
</ng-template>
