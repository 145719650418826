<form [formGroup]="form">
    <div class="centered">
        <nuc-checkbox
            formControlName="enabled"
            text="Enable product syncing">
        </nuc-checkbox>
        <nuc-form-field label="Product data field">
            <nuc-dropdown
                formControlName="productDataField"
                placeholder="Select a product data field which is used to link the image to"
                [searchable]="true"
                [nullOption]="false"
                [items]="dataFieldOptions"
                [required]="true"
                (requestData)="searchProductDataFields($event)">
            </nuc-dropdown>
        </nuc-form-field>
        <nuc-checkbox
            formControlName="removeLeadingZeros"
            text="Remove leading zero's from image identifier.">
        </nuc-checkbox>
    </div>

    <div class="mappings-container">
        <div class="mappings-header">
            <div class="mappings-info">
                <h2>Product mapping</h2>
                <p>Add product datafields and map it to their external path from Adam</p>
            </div>
            <nuc-button-secondary
                text="Add product mapping"
                icon="nucicon_add"
                iconPosition="end"
                (click)="addMapping()">
            </nuc-button-secondary>
        </div>

        <div class="mapping" *ngFor="let mapGroup of form.controls.mapping.controls; let index = index">
            <product-synchronisation-mapping-form-component
                [formGroup]="mapGroup"
                [dataFields]="dataFields">
            </product-synchronisation-mapping-form-component>

            <nuc-button-bar>
                <div></div>
                <nuc-button-secondary icon="nucicon_trash_fill" (click)="deleteMapping(index)"></nuc-button-secondary>
            </nuc-button-bar>

        </div>
    </div>

</form>
