import {Component, DestroyRef, EventEmitter, inject, Input, OnInit, Output} from '@angular/core';
import {FormBuilder, FormGroup} from '@angular/forms';
import {RLValidatorConstants} from '../../../../classes/validators/rl-validators.constant';
import {distinctUntilChanged, map, startWith} from 'rxjs/operators';
import {ConnectionDataService} from '../../connection.data-service';
import {ConnectionModel, EConnectionType} from '../../../../models/api/connection.model';
import {takeUntilDestroyed} from '@angular/core/rxjs-interop';
import {DropdownItem} from '../../../../models/ui/dropdown-item.model';

@Component({
    selector: 'superunie-adam-connection-form',
    templateUrl: './superunie-adam-connection-form.component.html',
    styleUrls: ['./superunie-adam-connection-form.component.scss'],
})
export class SuperunieAdamConnectionFormComponent implements OnInit {
    protected destroyRef: DestroyRef = inject(DestroyRef);
    public form: FormGroup;

    public validationMessages: any;

    public logo = ConnectionModel.getLogo(EConnectionType.SUPERUNIE_ADAM);

    @Input() public formData: Record<string, any>;
    @Output() public formSubmitted: EventEmitter<Record<string, any>> = new EventEmitter<Record<string, any>>();

    constructor(private fb: FormBuilder,
                private connectionDataService: ConnectionDataService) {
    }

    public ngOnInit(): void {
        this.setupFormGroup();

        this.form.statusChanges.pipe(
            map((status) => {
                return status === 'VALID';
            }),
            distinctUntilChanged(),
            startWith(false),
            takeUntilDestroyed(this.destroyRef)
        ).subscribe((valid) => {
            this.connectionDataService.setSaveButtonStateDisabled(!valid);
        });

        this.connectionDataService.saveButtonClickEvent$.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(() => {
            if (this.form.status === 'VALID') {
                this.onSubmitValid();
            }
        });
    }

    private setupFormGroup(): void {
        const connection = this.formData;
        this.form = this.fb.group({
            name: [connection?.name || '', RLValidatorConstants.VALIDATOR_SETS.REQUIRED],
            apiKey: [connection?.apiKey || '', RLValidatorConstants.VALIDATOR_SETS.REQUIRED],
            globalLocationNumbers: [connection?.globalLocationNumbers || null]
        });

        if (connection?.globalLocationNumbers?.length > 0) {
            const items =
                connection.globalLocationNumbers.map((GLN: string) => new DropdownItem(GLN, GLN));

            this.form.patchValue(
                { globalLocationNumbers: items}
            );
        }
    }

    public onSubmitValid(): void {
        this.connectionDataService.setSaveButtonStateLoading(true);
        this.formSubmitted.emit(this.form.value);
    }
}
