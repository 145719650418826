import {Injectable} from '@angular/core';
import {ApiConstants} from '../api.constant';
import {Observable} from 'rxjs';
import {environment} from '../../../environments/environment';
import {ARApiUrlBuilderService, ARPagedResponseDataModel, ARRequestOptions} from '@relayter/core';
import {BaseApiRequestService} from './base-api-request.service';
import {
    ConnectionModel,
    ConnectionApiModel,
    WebhookPostBody,
    WebhookPatchBody,
    WebhookModel,
    ConnectionEventModel,
    ConnectionEventBody,
    ConnectionEventPatchBody,
    ConnectionPatchModel,
    ImageSynchronisationBody,
    ProductSynchronisationBody,
} from '../../models/api/connection.model';
import {Cursor} from '../api-cursor';
import {SortDirection} from '@angular/material/sort';
import {QueryParams} from '../../classes/query-params';

@Injectable({
    providedIn: 'root'
})
export class ConnectionService extends BaseApiRequestService {
    /**
     * Create new connection
     * @param {ConnectionApiModel} connection - connection model to post to the api
     * @returns {Observable<ConnectionModel>}
     */
    public createConnection(connection: ConnectionApiModel): Observable<ConnectionModel> {
        const url = ARApiUrlBuilderService.urlFromComponents([
            environment.API_SERVER,
            ApiConstants.API_BASE_PATH,
            ApiConstants.API_GROUP_CONNECTIONS]);

        const options: ARRequestOptions = new ARRequestOptions();
        options.method = ApiConstants.REQUEST_METHODS.POST;
        options.url = url;
        options.body = connection;
        return new Observable((obs) => {
            this.handleDetailResponse<ConnectionModel>(options, obs, ConnectionModel);
        });
    }

    /**
     * Update connection
     * @param {string} id - id of connection
     * @param {ConnectionApiModel} connection - connection model to post to the api
     * @returns {Observable<ConnectionModel>}
     */
    public editConnection(id: string, connection: ConnectionApiModel): Observable<ConnectionModel> {
        const url = ARApiUrlBuilderService.urlFromComponents([
            environment.API_SERVER,
            ApiConstants.API_BASE_PATH,
            ApiConstants.API_GROUP_CONNECTIONS,
            id]);

        const options: ARRequestOptions = new ARRequestOptions();
        options.method = ApiConstants.REQUEST_METHODS.PUT;
        options.url = url;
        options.body = connection;
        return new Observable<ConnectionModel>((obs) => {
            this.handleDetailResponse(options, obs, ConnectionModel);
        });
    }

    public patchConnection(connectionId: string, connection: ConnectionPatchModel): Observable<ConnectionModel> {
        const url = ARApiUrlBuilderService.urlFromComponents([environment.API_SERVER,
            ApiConstants.API_BASE_PATH,
            ApiConstants.API_GROUP_CONNECTIONS,
            connectionId]);
        const options: ARRequestOptions = new ARRequestOptions();
        options.method = ApiConstants.REQUEST_METHODS.PATCH;
        options.url = url;
        options.body = connection;
        return new Observable<ConnectionModel>((obs) => {
            this.handleDetailResponse(options, obs, ConnectionModel);
        });
    }

    /**
     * get list of connections
     * @param {number} limit
     * @param {number} offset
     * @param {Cursor} cursor
     * @param {string} sortProperty
     * @param {SortDirection} sortOrder
     * @return {Observable<ARPagedResponseDataModel<ConnectionModel>>}
     */
    public getConnections(limit: number,
                          offset: number,
                          cursor?: Cursor,
                          sortProperty?: string,
                          sortOrder?: SortDirection): Observable<ARPagedResponseDataModel<ConnectionModel>> {
        const queryParams = new QueryParams()
            .setSortAndSortDirectionParams(sortProperty, sortOrder)
            .setLimitAndOffsetParams(limit, offset)
            .setCursor(cursor);

        const url = ARApiUrlBuilderService.urlFromComponents([
            environment.API_SERVER,
            ApiConstants.API_BASE_PATH,
            ApiConstants.API_GROUP_CONNECTIONS
        ], queryParams.getParams());
        const options: ARRequestOptions = new ARRequestOptions();
        options.method = ApiConstants.REQUEST_METHODS.GET;
        options.url = url;

        return new Observable(obs => this.handlePagedResponse(options, obs, ConnectionModel));
    }

    /**
     * Get connection details
     * @param {string} id
     * @returns {Observable<ConnectionModel>}
     */
    public getConnection(id: string): Observable<ConnectionModel> {

        const url = ARApiUrlBuilderService.urlFromComponents([
            environment.API_SERVER,
            ApiConstants.API_BASE_PATH,
            ApiConstants.API_GROUP_CONNECTIONS,
            id
        ]);
        const options: ARRequestOptions = new ARRequestOptions();
        options.method = ApiConstants.REQUEST_METHODS.GET;
        options.url = url;

        return new Observable<ConnectionModel>(obs => this.handleDetailResponse(options, obs, ConnectionModel));
    }

    /**
     * Delete connection
     * @param id
     * @return {Observable<boolean>}
     */
    public deleteConnection(id: string): Observable<boolean> {
        const url = ARApiUrlBuilderService.urlFromComponents([environment.API_SERVER,
            ApiConstants.API_BASE_PATH,
            ApiConstants.API_GROUP_CONNECTIONS, `/${id}`]);
        const options: ARRequestOptions = new ARRequestOptions();
        options.method = ApiConstants.REQUEST_METHODS.DELETE;
        options.url = url;
        return new Observable<boolean>((obs) => {
            this.handleNoErrorResponse(options, obs);
        });
    }

    public refreshToken(id: string): Observable<boolean> {
        const url = ARApiUrlBuilderService.urlFromComponents([environment.API_SERVER,
            ApiConstants.API_BASE_PATH,
            ApiConstants.API_GROUP_CONNECTIONS,
            id,
            ApiConstants.API_METHOD_REFRESH_TOKEN
        ]);

        const options: ARRequestOptions = new ARRequestOptions();
        options.method = ApiConstants.REQUEST_METHODS.POST;
        options.url = url;
        return new Observable((obs) => {
            this.handleNoErrorResponse(options, obs);
        });
    }

    public saveWebhook(connectionId: string, webhook: WebhookPostBody): Observable<ConnectionModel> {
        const url = ARApiUrlBuilderService.urlFromComponents([environment.API_SERVER,
            ApiConstants.API_BASE_PATH,
            ApiConstants.API_GROUP_CONNECTIONS, connectionId, ApiConstants.API_METHOD_WEBHOOKS]);
        const options: ARRequestOptions = new ARRequestOptions();
        options.method = ApiConstants.REQUEST_METHODS.POST;
        options.url = url;
        options.body = webhook;
        return new Observable<ConnectionModel>((obs) => {
            this.handleDetailResponse(options, obs, ConnectionModel);
        });
    }

    public updateWebhook(connectionId: string, webhookId: string, webhook: WebhookPatchBody): Observable<ConnectionModel> {
        const url = ARApiUrlBuilderService.urlFromComponents([environment.API_SERVER,
            ApiConstants.API_BASE_PATH,
            ApiConstants.API_GROUP_CONNECTIONS,
            connectionId,
            ApiConstants.API_GROUP_WEBHOOKS,
            webhookId]);
        const options: ARRequestOptions = new ARRequestOptions();
        options.method = ApiConstants.REQUEST_METHODS.PATCH;
        options.url = url;
        options.body = webhook;
        return new Observable<ConnectionModel>((obs) => {
            this.handleDetailResponse(options, obs, ConnectionModel);
        });
    }

    public getWebhooks(connectionId: string): Observable<ARPagedResponseDataModel<WebhookModel>> {
        const url = ARApiUrlBuilderService.urlFromComponents([
            environment.API_SERVER,
            ApiConstants.API_BASE_PATH,
            ApiConstants.API_GROUP_CONNECTIONS,
            connectionId,
            ApiConstants.API_METHOD_WEBHOOKS]);
        const options: ARRequestOptions = new ARRequestOptions();
        options.method = ApiConstants.REQUEST_METHODS.GET;
        options.url = url;
        return new Observable((obs) => {
            this.handlePagedResponse(options, obs, WebhookModel);
        });
    }

    public getEvents(connectionId: string): Observable<ARPagedResponseDataModel<ConnectionEventModel>> {
        const url = ARApiUrlBuilderService.urlFromComponents([
            environment.API_SERVER,
            ApiConstants.API_BASE_PATH,
            ApiConstants.API_GROUP_CONNECTIONS,
            connectionId,
            ApiConstants.API_METHOD_EVENTS]);
        const options: ARRequestOptions = new ARRequestOptions();
        options.method = ApiConstants.REQUEST_METHODS.GET;
        options.url = url;
        return new Observable((obs) => {
            this.handlePagedResponse(options, obs, ConnectionEventModel);
        });
    }

    public createEvent(connectionId: string, event: ConnectionEventBody): Observable<ConnectionModel> {
        const url = ARApiUrlBuilderService.urlFromComponents([
            environment.API_SERVER,
            ApiConstants.API_BASE_PATH,
            ApiConstants.API_GROUP_CONNECTIONS, connectionId,
            ApiConstants.API_METHOD_EVENTS]);
        const options: ARRequestOptions = new ARRequestOptions();
        options.method = ApiConstants.REQUEST_METHODS.POST;
        options.url = url;
        options.body = event;
        return new Observable<ConnectionModel>((obs) => {
            this.handleDetailResponse(options, obs, ConnectionModel);
        });
    }

    public updateEvent(connectionId: string, eventId: string, event: ConnectionEventPatchBody): Observable<ConnectionModel> {
        const url = ARApiUrlBuilderService.urlFromComponents([
            environment.API_SERVER,
            ApiConstants.API_BASE_PATH,
            ApiConstants.API_GROUP_CONNECTIONS,
            connectionId,
            ApiConstants.API_GROUP_EVENTS,
            eventId]);
        const options: ARRequestOptions = new ARRequestOptions();
        options.method = ApiConstants.REQUEST_METHODS.PATCH;
        options.url = url;
        options.body = event;
        return new Observable<ConnectionModel>((obs) => {
            this.handleDetailResponse(options, obs, ConnectionModel);
        });
    }

    public deleteEvent(connectionId: string, eventId: string): Observable<boolean> {
        const url = ARApiUrlBuilderService.urlFromComponents([
            environment.API_SERVER,
            ApiConstants.API_BASE_PATH,
            ApiConstants.API_GROUP_CONNECTIONS,
            connectionId,
            ApiConstants.API_GROUP_EVENTS,
            eventId]);
        const options: ARRequestOptions = new ARRequestOptions();
        options.method = ApiConstants.REQUEST_METHODS.DELETE;
        options.url = url;
        return new Observable<boolean>((obs) => {
            this.handleNoErrorResponse(options, obs);
        });
    }

    public createImageSynchronisation (connectionId: string, synchronisation: ImageSynchronisationBody): Observable<ConnectionModel> {
        const url = ARApiUrlBuilderService.urlFromComponents([
            environment.API_SERVER,
            ApiConstants.API_BASE_PATH,
            ApiConstants.API_GROUP_CONNECTIONS,
            connectionId,
            ApiConstants.API_METHOD_IMAGE_SYNCHRONISATIONS]);
        const options: ARRequestOptions = new ARRequestOptions();
        options.method = ApiConstants.REQUEST_METHODS.POST;
        options.url = url;
        options.body = synchronisation;
        return new Observable<ConnectionModel>((obs) => {
            this.handleDetailResponse(options, obs, ConnectionModel);
        });
    }
    public createProductSynchronisation (connectionId: string, synchronisation: ProductSynchronisationBody): Observable<ConnectionModel> {
        const url = ARApiUrlBuilderService.urlFromComponents([
            environment.API_SERVER,
            ApiConstants.API_BASE_PATH,
            ApiConstants.API_GROUP_CONNECTIONS,
            connectionId,
            ApiConstants.API_METHOD_PRODUCT_SYNCHRONISATIONS]);
        const options: ARRequestOptions = new ARRequestOptions();
        options.method = ApiConstants.REQUEST_METHODS.POST;
        options.url = url;
        options.body = synchronisation;
        return new Observable<ConnectionModel>((obs) => {
            this.handleDetailResponse(options, obs, ConnectionModel);
        });
    }

    public updateImageSynchronisation (
        connectionId: string,
        synchronisationId: string,
        synchronisation: ImageSynchronisationBody
    ): Observable<ConnectionModel> {
        const url = ARApiUrlBuilderService.urlFromComponents([
            environment.API_SERVER,
            ApiConstants.API_BASE_PATH,
            ApiConstants.API_GROUP_CONNECTIONS,
            connectionId,
            ApiConstants.API_METHOD_IMAGE_SYNCHRONISATIONS,
            synchronisationId]);
        const options: ARRequestOptions = new ARRequestOptions();
        options.method = ApiConstants.REQUEST_METHODS.PATCH;
        options.url = url;
        options.body = synchronisation;
        return new Observable<ConnectionModel>((obs) => {
            this.handleDetailResponse(options, obs, ConnectionModel);
        });
    }

    public updateProductSynchronisation (
        connectionId: string,
        synchronisationId: string,
        synchronisation: ProductSynchronisationBody
    ): Observable<ConnectionModel> {
        const url = ARApiUrlBuilderService.urlFromComponents([
            environment.API_SERVER,
            ApiConstants.API_BASE_PATH,
            ApiConstants.API_GROUP_CONNECTIONS,
            connectionId,
            ApiConstants.API_METHOD_PRODUCT_SYNCHRONISATIONS,
            synchronisationId]);
        const options: ARRequestOptions = new ARRequestOptions();
        options.method = ApiConstants.REQUEST_METHODS.PATCH;
        options.url = url;
        options.body = synchronisation;
        return new Observable<ConnectionModel>((obs) => {
            this.handleDetailResponse(options, obs, ConnectionModel);
        });
    }

    public deleteImageSynchronisation (connectionId: string, synchronisationId: string): Observable<boolean> {
        const url = ARApiUrlBuilderService.urlFromComponents([
            environment.API_SERVER,
            ApiConstants.API_BASE_PATH,
            ApiConstants.API_GROUP_CONNECTIONS,
            connectionId,
            ApiConstants.API_METHOD_IMAGE_SYNCHRONISATIONS,
            synchronisationId]);
        const options: ARRequestOptions = new ARRequestOptions();
        options.method = ApiConstants.REQUEST_METHODS.DELETE;
        options.url = url;
        return new Observable<boolean>((obs) => {
            this.handleNoErrorResponse(options, obs);
        });
    }

    public deleteProductSynchronisation (connectionId: string, synchronisationId: string): Observable<boolean> {
        const url = ARApiUrlBuilderService.urlFromComponents([
            environment.API_SERVER,
            ApiConstants.API_BASE_PATH,
            ApiConstants.API_GROUP_CONNECTIONS,
            connectionId,
            ApiConstants.API_METHOD_PRODUCT_SYNCHRONISATIONS,
            synchronisationId]);
        const options: ARRequestOptions = new ARRequestOptions();
        options.method = ApiConstants.REQUEST_METHODS.DELETE;
        options.url = url;
        return new Observable<boolean>((obs) => {
            this.handleNoErrorResponse(options, obs);
        });
    }
}
