<div class="header-row">
    <h1>{{publicationTypeDisplayName}} template overview</h1>
    <nuc-button-bar>
        <nuc-button-primary *ngIf="permissions.POST_TEMPLATE | userIsAllowedTo"
                            text="Add template"
                            (click)="openTemplateEditor()" i18n>
        </nuc-button-primary>
    </nuc-button-bar>
</div>

<nuc-table *ngIf="permissions.GET_TEMPLATES | userIsAllowedTo else noPermissionState"
           [items]="templates"
           [columns]="columns"
           [actions]="actionTypes"
           [tableId]="tableId"
           [loading]="!subscription?.closed"
           [prefixStorageKey]="storageKey"
           emptyStateTitle="{{searchValue ? 'No matching results.' : 'This section is empty. Add the first item.'}}"
           (actionClicked)="handleTableRowAction($event)"
           (sortOptionChange)="onSortOptionChanged($event)">

    <nuc-input button-bar-left
               [(data)]="searchValue"
               prefix="nucicon_search"
               (debounceValueChanged)="onSearchBarValueUpdated($event)"
               placeholder="Search templates by name">
    </nuc-input>

    <paginator button-bar-right
               [viewId]="viewId"
               [loading]="!subscription?.closed"
               [disableNextPage]="disableNextPage">
    </paginator>
</nuc-table>


<ng-template #createTemplateModal>
    <rl-template-detail [publicationType]="publicationType" [template]="selectedTemplate"></rl-template-detail>
</ng-template>

<ng-template #noPermissionState>
    <no-permission-state-component></no-permission-state-component>
</ng-template>
