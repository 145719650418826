import {Component, Inject, OnInit, Optional} from '@angular/core';
import {
    CustomWorkflowStickyLogComponent,
    ICustomWorkflowStickyLogModalData
} from './custom-workflow-sticky-log.component';
import {PublicationsService} from '../../../../../../api/services/publications.service';
import {UserService} from '../../../../../../api/services/users.service';
import {UserIsAllowedToPipe} from '../../../../../../pipes/user-is-allowed-to.pipe';
import {FullModalService, NUC_FULL_MODAL_DATA, NucDialogService} from '@relayter/rubber-duck';
import {CustomWorkflowService} from '../custom-workflow.service';
import {ETransitionStatus} from '../../../../../../models/api/transition-item.model';
import {combineLatest} from 'rxjs';
import {UserSettingsStorageService} from '../../../../../../api/services/user-settings-storage.service';
import {AdvancedFiltersDataService} from '../../../../../../api/services/advanced-filters.data-service';
import {PaginatorService} from '../../../../../../components/paginator/paginator.service';
import {VariantModel} from '../../../../../../models/api/variant.model';
import {DataFieldsApiService} from '../../../../../../api/services/data-fields.api.service';
import {takeUntilDestroyed} from '@angular/core/rxjs-interop';

@Component({
    selector: 'rl-custom-workflow-sticky-list-component',
    templateUrl: './custom-workflow-sticky-list-log.component.html',
    styleUrls: ['./custom-workflow-sticky-list-log.component.scss'],
    providers: [AdvancedFiltersDataService, PaginatorService]
})
export class CustomWorkflowStickyListComponent extends CustomWorkflowStickyLogComponent implements OnInit {
    public tableId = 'custom-workflow-sticky-list-table';

    constructor(protected publicationService: PublicationsService,
                protected userService: UserService,
                protected userIsAllowedToPipe: UserIsAllowedToPipe,
                @Optional() @Inject(NUC_FULL_MODAL_DATA) protected modalData: ICustomWorkflowStickyLogModalData,
                protected dialogService: NucDialogService,
                protected fullModalService: FullModalService,
                public customWorkflowService: CustomWorkflowService,
                userSettingsStorageService: UserSettingsStorageService,
                filtersDataService: AdvancedFiltersDataService,
                paginatorService: PaginatorService,
                protected dataFieldService: DataFieldsApiService) {
        super(publicationService, userService, userIsAllowedToPipe, modalData, dialogService, fullModalService,
            userSettingsStorageService, filtersDataService, paginatorService, dataFieldService);
    }

    public ngOnInit(): void {
        super.ngOnInit();

        this.customWorkflowService.transitionItemUpdate$
            .pipe(takeUntilDestroyed(this.destroyRef))
            .subscribe((transitionItem) => {
                // Failed items in current step no longer in transition
                const transition = this.workflow.transitions.find(item => item._id === transitionItem.transition);
                if (transition && transitionItem.status === ETransitionStatus.FAILED && this.step._id === transition.from) {
                    this.refresh$.next();
                }
            });

        combineLatest([
            this.customWorkflowService.activeStep$,
            this.customWorkflowService.activeFilters$
        ])
            .pipe(takeUntilDestroyed(this.destroyRef))
            .subscribe(([step, filters]) => {
                this.step = step;
                this.activeFilters = filters;
                this.refreshData();
            });

        this.customWorkflowService.activeVariant$.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(
            (variant: VariantModel) => {
                if (this.activeVariant !== variant) {
                    this.activeVariant = variant;
                    this.refreshData();
                    this.setupTableColumns();
                }
            }
        );
    }

}
