import {Injectable, OnDestroy} from '@angular/core';
import {BehaviorSubject, combineLatest, Observable, of, ReplaySubject, Subject} from 'rxjs';
import {CustomWorkflowStepModel} from '../../../../../models/api/custom-workflow-step.model';
import {CustomWorkflowComponentModel} from '../../../../../models/api/custom-workflow-component.model';
import {WorkflowConfigurationModel} from '../../../../../models/api/workflow-configuration.model';
import {PublicationModel} from '../../../../../models/api/publication.model';
import {CampaignModel} from '../../../../../models/api/campaign.model';
import {CustomWorkflowFilterModel} from '../../../../../models/api/custom-workflow-filter.model';
import {CustomWorkflowFilterOptionModel} from '../../../../../models/api/custom-workflow-filter-option.model';
import {TransitionItemModel} from '../../../../../models/api/transition-item.model';
import {map, take} from 'rxjs/operators';
import {CampaignItemModel} from '../../../../../models/api/campaign-item.model';
import {VariantModel} from '../../../../../models/api/variant.model';

@Injectable()
export class CustomWorkflowService implements OnDestroy {
    private _campaign: CampaignModel;

    private publication = new ReplaySubject<PublicationModel>(1);
    public publication$ = this.publication.asObservable();

    private activeStep = new ReplaySubject<CustomWorkflowStepModel>(1);
    public activeStep$ = this.activeStep.asObservable();

    private activeComponent = new ReplaySubject<CustomWorkflowComponentModel>(1);
    public activeComponent$ = this.activeComponent.asObservable();

    private activeFilters = new ReplaySubject<Map<CustomWorkflowFilterModel, CustomWorkflowFilterOptionModel[]>>(1);
    public activeFilters$ = this.activeFilters.asObservable();

    private workflow = new ReplaySubject<WorkflowConfigurationModel>(1);
    public workflow$ = this.workflow.asObservable();

    private transitionItemUpdate = new Subject<TransitionItemModel>();
    public transitionItemUpdate$ = this.transitionItemUpdate.asObservable();

    private requestClose = new Subject<void>();
    public requestClose$ = this.requestClose.asObservable();

    private closeSubjects: Observable<boolean>[] = [];

    private getCloseSubjects(): Observable<boolean>[] {
        return this.closeSubjects.length ? this.closeSubjects : [of(true)];
    }

    private editCampaignItemDetails = new ReplaySubject<CampaignItemModel>(1);
    public editCampaignItemDetails$ = this.editCampaignItemDetails.asObservable();

    private editCampaignItemId = new Subject<{ campaignItemId: string; pubItemId: string }>();
    public editCampaignItemId$ = this.editCampaignItemId.asObservable();

    private activeVariant = new BehaviorSubject<VariantModel>(null);
    public activeVariant$ = this.activeVariant.asObservable();

    private publicationVariants = new BehaviorSubject<VariantModel[]>([]);
    public publicationVariants$ = this.publicationVariants.asObservable();

    private startTransition = new Subject<{transitionId: string, data: Record<string, any>}>();
    public startTransition$ = this.startTransition.asObservable();

    private itemActionUpdate = new Subject<string[]>();
    public itemActionUpdate$ = this.itemActionUpdate.asObservable();

    private subjects = [
        this.publication,
        this.activeStep,
        this.activeComponent,
        this.activeFilters,
        this.workflow,
        this.transitionItemUpdate,
        this.editCampaignItemDetails,
        this.editCampaignItemId,
        this.activeVariant,
        this.publicationVariants,
        this.startTransition,
        this.itemActionUpdate
    ];

    public ngOnDestroy(): void {
        this.subjects.forEach((subject) => subject.complete());
    }

    public set campaign(campaign: CampaignModel) {
        this._campaign = campaign;
    }

    public get campaign(): CampaignModel {
        return this._campaign;
    }

    public setPublication(publication: PublicationModel): void {
        this.publication.next(publication);
    }

    public setActiveStep(step: CustomWorkflowStepModel): void {
        combineLatest(this.getCloseSubjects()).pipe(
            take(1),
            map((results: boolean[]) => {
                return !results.includes(false);
            }))
            .subscribe((result) => {
                if (result) {
                    this.closeSubjects = [];
                    this.activeStep.next(step);
                }
            });
        this.requestClose.next();
    }

    public setActiveComponent(component: CustomWorkflowComponentModel): void {
        combineLatest(this.getCloseSubjects()).pipe(
            take(1),
            map((results: boolean[]) => {
                return !results.includes(false);
            }))
            .subscribe((result) => {
                if (result) {
                    this.closeSubjects = [];
                    this.activeComponent.next(component);
                }
            });
        this.requestClose.next();
    }

    public canClose(): Observable<boolean> {
        return new Observable((subscriber) => {
            combineLatest(this.getCloseSubjects()).pipe(
                take(1),
                map((results: boolean[]) => {
                    return !results.includes(false);
                }))
                .subscribe((result) => {
                    if (result) {
                        this.closeSubjects = [];
                        subscriber.next(true);
                    } else {
                        subscriber.next(false);
                    }
                    subscriber.complete();
                });
            this.requestClose.next();
        });
    }

    public setActiveFilters(filters: Map<CustomWorkflowFilterModel, CustomWorkflowFilterOptionModel[]>): void {
        this.activeFilters.next(filters);
    }

    public setWorkflow(workflow: WorkflowConfigurationModel): void {
        this.workflow.next(workflow);
    }

    public setTransitionItemUpdate(transitionItem: TransitionItemModel): void {
        this.transitionItemUpdate.next(transitionItem);
    }

    public addCloseCheck(obs: Observable<boolean>): void {
        this.closeSubjects.push(obs);
    }

    public removeCloseCheck(subject: Observable<boolean>): void {
        this.closeSubjects.splice(this.closeSubjects.indexOf(subject), 1);
    }

    public setEditCampaignItemDetails(campaignItem: CampaignItemModel): void {
        this.editCampaignItemDetails.next(campaignItem);
    }

    public setEditCampaignItemId(editItem: { campaignItemId: string; pubItemId: string }): void {
        this.editCampaignItemId.next(editItem);
    }

    public setActiveVariant(variant: VariantModel | null): void {
        this.activeVariant.next(variant);
    }

    public setPublicationVariants(variants: VariantModel[] | null): void {
        this.publicationVariants.next(variants);
    }

    public postTransition(transitionId: string, data: Record<string, any>): void {
        this.startTransition.next({transitionId, data});
    }

    public setItemActionUpdate(items: string[]): void {
        this.itemActionUpdate.next(items);
    }
}
