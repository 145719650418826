<div class="actions">
    <nuc-button-bar class="bottom-line">
        <div class="left-bar">
            <nuc-button-secondary
                [disabled]="(publicationItemSelectionService.isFirstItem$ | async) || !publicationItem"
                icon="nucicon_chevron_left"
                (click)="publicationItemSelectionService.onBackClick()">
            </nuc-button-secondary>

            <nuc-button-secondary
                [disabled]="(publicationItemSelectionService.isLastItem$ | async) || !publicationItem"
                icon="nucicon_chevron_right"
                (click)="publicationItemSelectionService.onNextClick()">
            </nuc-button-secondary>
        </div>

        <div class="right-bar">
            <nuc-button-secondary *ngFor="let action of iterableActions"
                                  [disabled]="!publicationItem"
                                  [text]="action.title"
                                  (click)="handleAction(action, [publicationItem])">
            </nuc-button-secondary>
        </div>
    </nuc-button-bar>
</div>

<div class="content">
    <div class="item-selection">
        <rl-custom-workflow-item-selection-component></rl-custom-workflow-item-selection-component>
    </div>

    <div class="editor-container">
        <ng-container *ngIf="publicationItem; else otherState">
            <rl-spread-editor-component
                class="drop-target editor"
                *ngIf="publicationItem?.template; else noTemplate"
                id="spreadEditor"
                [editorOptions]="editorOptions"
                [activeVariant]="activeVariant"
                [content]="content"
                [template]="template"
                cdkDropList
                (cdkDropListDropped)="itemReleased($event)"
                [campaign]="campaign"
                (editCampaignItem)="editCampaignItemClicked($event)">
            </rl-spread-editor-component>

            <div class="controls">
                @if (spreadEditorComponent()) {
                    <nuc-button-secondary (click)="spreadEditorComponent().zoomIn()" icon="nucicon_add"
                                          [nucTooltip]="'Zoom in'"
                                          [nucTooltipPosition]="'right'">
                    </nuc-button-secondary>

                    <nuc-slider step="1" min="100" max="300" vertical="true"
                                [value]="spreadEditorComponent().getZoomValueForSlider()"
                                (valueChange)="spreadEditorComponent().valueChanged($event)"
                                (mouseup)="spreadEditorComponent().refreshLayout()"
                                [nucTooltip]="spreadEditorComponent().getZoomValueStringForSlider()"
                                [nucTooltipPosition]="'right'">
                    </nuc-slider>

                    <nuc-button-secondary (click)="spreadEditorComponent().zoomOut()" icon="nucicon_remove"
                                          [nucTooltip]="'Zoom out'"
                                          [nucTooltipPosition]="'right'">
                    </nuc-button-secondary>

                    <nuc-button-secondary (click)="spreadEditorComponent().fitToContainer()" icon="nucicon_images_line"
                                          [nucTooltip]="'Fit to canvas'" [nucTooltipPosition]="'right'">
                    </nuc-button-secondary>
                }

                <nuc-button-secondary
                    [disabled]="(publicationItemSubscription && !publicationItemSubscription.closed) || (patchPublicationItemSubscription && !patchPublicationItemSubscription.closed)"
                    [icon]="layoutOverlay === layoutOverlayEnum.NOTES ? 'nucicon_comments_show': 'nucicon_comments_hide'"
                    [matMenuTriggerFor]="menu"
                    nucTooltip="Note view" nucTooltipPosition="right">
                </nuc-button-secondary>
                <mat-menu #menu="matMenu" xPosition="before" class="menu-items-container">
                    <button mat-menu-item
                            *ngFor="let item of layoutOverlayOptions"
                            (click)="setLayoutOverlay(item)">
                        {{item.getTitle()}}
                    </button>
                </mat-menu>
            </div>
            <div class="editor-buttons">
                <overlay-button icon="nucicon_eye"
                                [showIndicator]="false"
                                nucTooltip="View settings"
                                [content]="propertySettingsComponent">
                </overlay-button>

                <form *ngIf="showLayoutRevisionsAction" [formGroup]="formGroup">
                    <nuc-dropdown
                        formControlName="revision"
                        class="revisionSelect"
                        placeholder="Select revision"
                        [nullOption]=false
                        [items]="revisionsItems">
                    </nuc-dropdown>
                </form>
            </div>
        </ng-container>
    </div>

    <div class="form-container" *ngIf="showBriefingForm">
        <campaign-item-inline-form (closeEvent)="closeEditBriefing($event)" [campaignId]="campaign._id"
                                   *fullscreenForm="editCampaignItemEvents; viewId: 'campaignItemWorkflowForm'; btnContainer: 'right'; modalEventSubject: fullscreenModalEventSubject;"
                                   [isLoading]="!campaignDetailsSubscription.closed"></campaign-item-inline-form>
    </div>

    <!-- 2 x sidebar but there may only be 1 action configured in the workflow config (see docs)-->
    <div *ngIf="editLayoutAction" class="sidebar">
        <nuc-tab-bar [(activeTab)]="activeTab" [tabs]="tabs"></nuc-tab-bar>

        <div *ngIf="activeTab === briefingItemsTab && publicationItem" class="container">

            <div class="container-options"
                 *ngIf="publicationItem?.campaignItems.length > 0 || editLayoutOptions?.autoAssign?.value">
                <nuc-input class="item-search"
                           prefix="nucicon_search"
                           [(data)]="campaignItemSearch"
                           (debounceValueChanged)="onCampaignItemSearchChanged()">
                </nuc-input>
            </div>

            <div class="summary">
                <ng-container *ngIf="editLayoutOptions?.autoAssign?.value !== true">
                    <div class="count">
                        <p>In layout</p>
                        <div class="counter">{{selectedCampaignItemIds.length}}</div>
                    </div>

                    <div class="count">
                        <p>Not in layout</p>
                        <div
                            class="counter">{{publicationItem.campaignItems.length - selectedCampaignItemIds.length}}</div>
                    </div>
                </ng-container>
            </div>

            <rl-loading-indicator
                *ngIf="!campaignItemsSubscription?.closed && !campaignItems?.length; else campaignItemsLoaded">
            </rl-loading-indicator>

            <ng-template #campaignItemsLoaded>
                <div class="items">
                    <div *ngFor="let campaignItem of campaignItems"
                         cdkDropList
                         cdkDropListConnectedTo="spreadEditor"
                         [cdkDropListAutoScrollDisabled]="true"
                         [cdkDropListSortingDisabled]="true"
                         (cdkDropListEntered)="enteredItems()"
                         (cdkDropListExited)="exitedCampaignItems($event)">

                        <nuc-card-view cdkDrag
                                              (cdkDragMoved)="onMove($event)"
                                              [cdkDragData]="campaignItem"
                                              [badgeText]="(campaignItem | isPlaced: selection) ? BADGE_TEXT : null"
                                              [image]="campaignItem.thumb">
                            <div content>
                                <ng-container *ngFor="let relayterFieldSetting of propertySettings.relayterFields">
                                    <div class="field">
                                        <p class="body-strong">{{relayterFieldSetting.title}}</p>
                                        <span>
                                            {{campaignItem | propertySettingDisplay: relayterFieldSetting}}
                                        </span>
                                    </div>
                                </ng-container>

                                <ng-container *ngFor="let dataFieldSetting of propertySettings.dataFields">
                                    <div class="field">
                                        <p class="body-strong">{{dataFieldSetting.title}}</p>
                                        <span>
                                            {{campaignItem | propertySettingDisplay: dataFieldSetting : activeVariant?.key}}
                                        </span>
                                    </div>
                                </ng-container>
                            </div>
                        </nuc-card-view>

                        <nuc-card-view *ngIf="transferringItem === campaignItem" size="MEDIUM"
                                              [title]="campaignItem.briefingItemId"
                                              [badgeText]="(campaignItem | isPlaced: selection) ? BADGE_TEXT : null"
                                              [image]="campaignItem.thumb">
                            <div content>
                                <ng-container *ngFor="let relayterFieldSetting of propertySettings.relayterFields">
                                    <div class="field">
                                        <p class="body-strong">{{relayterFieldSetting.title}}</p>
                                        <span>
                                            {{campaignItem | propertySettingDisplay: relayterFieldSetting}}
                                        </span>
                                    </div>
                                </ng-container>

                                <ng-container *ngFor="let dataFieldSetting of propertySettings.dataFields">
                                    <div class="field">
                                        <p class="body-strong">{{dataFieldSetting.title}}</p>
                                        <span>
                                            {{campaignItem | propertySettingDisplay: dataFieldSetting : activeVariant?.key}}
                                        </span>
                                    </div>
                                </ng-container>
                            </div>
                        </nuc-card-view>
                    </div>
                </div>
                <nuc-empty-state-component *ngIf="!campaignItems.length && !campaignItemSearch?.length"
                                           imageUrl="assets/images/empty_states/layout_briefing_items.svg"
                                           title="No briefing items"
                                           subtitle="You have to assign briefing items to the page first.">
                </nuc-empty-state-component>

                <nuc-empty-state-component *ngIf="!campaignItems.length && campaignItemSearch?.length"
                                           imageUrl="assets/images/empty_states/search.svg"
                                           title="No briefing items found"
                                           subtitle="We couldn't find any results for: '{{campaignItemSearch}}'.">
                </nuc-empty-state-component>
                <nuc-button-bar class="show-more-button-bar">
                    <nuc-button-secondary *ngIf="campaignItems.length"
                                          nucTooltip="Show more available items"
                                          text="Show more"
                                          (click)="getCampaignItems()"
                                          [loading]="campaignItemCollectionOptions.isLoading"
                                          [disabled]="campaignItemCollectionOptions.total <= campaignItems.length || campaignItemCollectionOptions.isLoading"
                                          i18n>
                    </nuc-button-secondary>
                </nuc-button-bar>
            </ng-template>

        </div>

        <div *ngIf="activeTab === assetsTab && publicationItem" class="container">

            <div class="filters">
                <nuc-input class="item-search"
                           prefix="nucicon_search"
                           [(data)]="assetSearch"
                           placeholder="Search assets"
                           (debounceValueChanged)="onAssetSearchChanged()">
                </nuc-input>
                <div class="vertical-line"></div>
                <rl-data-filter
                    [addDataFieldsContext]="filterContext"
                    [filters]="dataFilters">
                </rl-data-filter>
            </div>

            <rl-loading-indicator *ngIf="!assetsSubscription?.closed && !assets?.length; else assetsLoaded">
            </rl-loading-indicator>

            <ng-template #assetsLoaded>
                <div class="items">
                    <div *ngFor="let asset of assets"
                         cdkDropList
                         cdkDropListConnectedTo="spreadEditor"
                         [cdkDropListAutoScrollDisabled]="true"
                         [cdkDropListSortingDisabled]="true"
                         (cdkDropListEntered)="enteredItems()"
                         (cdkDropListExited)="exitedAssets($event)">

                        <nuc-card-view
                            cdkDrag
                            (cdkDragMoved)="onMove($event)"
                            [cdkDragData]="asset"
                            size="MEDIUM"
                            [title]="asset | assetDisplay:EAssetDisplayProperties.TITLE"
                            [subTitle]="asset | assetDisplay:EAssetDisplayProperties.SUBTITLE"
                            [badgeText]="(asset | isPlaced:selection) ? BADGE_TEXT : null"
                            [image]="asset | assetDisplay:EAssetDisplayProperties.THUMBNAIL_URL">
                        </nuc-card-view>
                        <nuc-card-view *ngIf="transferringItem === asset"
                                              [title]="asset | assetDisplay:EAssetDisplayProperties.TITLE"
                                              [subTitle]="asset | assetDisplay:EAssetDisplayProperties.SUBTITLE"
                                              [badgeText]="(asset | isPlaced:selection) ? BADGE_TEXT : null"
                                              [image]="asset | assetDisplay:EAssetDisplayProperties.THUMBNAIL_URL">
                        </nuc-card-view>
                    </div>
                </div>

                <nuc-empty-state-component *ngIf="!assets.length && (!assetSearch?.length || assetSearch?.length)"
                                           imageUrl="assets/images/empty_states/default.svg"
                                           title="No matching results."
                                           [small]="true">
                </nuc-empty-state-component>

                <nuc-button-bar class="show-more-button-bar">
                    <nuc-button-secondary *ngIf="assets.length"
                                          nucTooltip="Show more available items"
                                          text="Show more"
                                          (click)="onButtonNextClicked()"
                                          [loading]="assetsLoading"
                                          [disabled]="disableNextPage || assetsLoading"
                                          i18n>
                    </nuc-button-secondary>
                </nuc-button-bar>
            </ng-template>
        </div>
    </div>

    <!-- 2 x sidebar but there may only be 1 action configured in the workflow config (see docs)-->
    <div *ngIf="showLayoutRevisionsAction" class="sidebar">
        <nuc-tab-bar [(activeTab)]="activeChangesTab" [tabs]="changesTabs"></nuc-tab-bar>

        <div class="container changes"
             *ngIf="changes && changes[activeChangesTab.title | uppercase]?.length else noChangesInCurrentRevision">
            <rl-layout-change-indicator-component
                *ngFor="let change of (changes[activeChangesTab.title | uppercase])"
                [propertySettings]="propertySettings"
                [contentChange]="change"
                [publication]="publication"
                [campaign]="campaign"
                [activeVariant]="activeVariant">
            </rl-layout-change-indicator-component>
        </div>
    </div>

</div>

<ng-template #noChangesInCurrentRevision>
    <nuc-empty-state-component imageUrl="assets/images/empty_states/small-empty-state-layout-revisions.svg"
                               [small]="true"
                               title="No changes in selected revision.">
    </nuc-empty-state-component>
</ng-template>

<ng-template #noTemplate>
    <div class="otherState"> <!-- container to keep the content centered -->
        <nuc-empty-state-component imageUrl="assets/images/empty_states/layout.svg"
                                   title="No layouts"
                                   subtitle="The item has no template.">
        </nuc-empty-state-component>
    </div>
</ng-template>

<ng-template #otherState>
    <div class="otherState"> <!-- container to keep the content centered -->
        <nuc-empty-state-component *ngIf="publicationItemSubscription?.closed || !workflowLayoutItem; else loadingState"
                                   imageUrl="assets/images/empty_states/layout.svg"
                                   title="No layouts"
                                   subtitle="You have to assign briefing items to pages first.">
        </nuc-empty-state-component>

        <ng-template #loadingState>
            <rl-loading-indicator size="large"></rl-loading-indicator>
        </ng-template>
    </div>
</ng-template>

<ng-template #propertySettingsComponent>
    <rl-property-settings-component [context]="context"></rl-property-settings-component>
</ng-template>
