import {Component, DestroyRef, EventEmitter, inject, Input, OnInit, Output, signal, WritableSignal} from '@angular/core';
import {FormGroup, Validators} from '@angular/forms';
import {BooleanOption, ConditionsConstants, ConditionType, ConditionTypeValue, PropertyOperator} from '../../format-ruleset.constants';
import {ERulesetPropertyType, RulePropertyModel} from '../../../../models/api/rule-property.model';
import {IDropdownItem} from '@relayter/rubber-duck/lib/interfaces/idropdown-item';
import {EDataFieldTypes} from '../../../../app.enums';
import {DropdownItem} from '../../../../models/ui/dropdown-item.model';
import {ConditionForm} from '../condition-group-form/condition-group-form.component';
import {ConditionBaseType} from '../../../../models/api/rule-condition.model';
import {takeUntilDestroyed} from '@angular/core/rxjs-interop';
import {startWith} from 'rxjs';
import {pairwise} from 'rxjs/operators';
import {EControlType} from '../../../../components/property-control/property-control.component';
import {PropertyValueModel} from '../../../../models/ui/property-value.model';
import {PropertyControlOptions} from '../../../../components/property-control/property-control.options';

export enum EValueType {
    CUSTOM = 'CUSTOM',
    PROPERTY = 'PROPERTY'
}

@Component({
    selector: 'conditions-form',
    templateUrl: './conditions-form.component.html',
    styleUrls: ['./conditions-form.component.scss']
})
export class ConditionsFormComponent implements OnInit {
    @Output() public deleteClicked = new EventEmitter<void>();

    @Input() public formGroup: FormGroup<ConditionForm>;
    @Input() public ruleProperties: RulePropertyModel[] = [];
    @Input() public valueIndex = 0;

    public valuePropertyRuleProperties: RulePropertyModel[] = [];

    public conditionTypes: ConditionType[];
    public valueTypes = ConditionTypeValue.TYPES;
    public operators = [];

    private destroyRef = inject(DestroyRef);

    public inputType: string = null;
    public typeValueRequired: WritableSignal<boolean> = signal(false);
    public dropdownItems: IDropdownItem<ConditionBaseType>[];

    // Property control settings
    public static readonly PropertyControlOptions = new PropertyControlOptions(false, false, false);
    protected readonly propertyControlOptions = ConditionsFormComponent.PropertyControlOptions;

    public EControlType = EControlType;

    get propertyFormControl() {
        return this.formGroup.controls.property;
    }

    get operatorFormControl() {
        return this.formGroup.controls.operator;
    }

    get typeFormControl() {
        return this.formGroup.controls.type;
    }

    get typeValueFormControl() {
        return this.formGroup.controls.typeValue;
    }

    get valueFormControl() {
        return this.formGroup.controls.value;
    }

    get valuePropertyFormControl() {
        return this.formGroup.controls.valueProperty;
    }

    /**
     * Subscribe to the change of the property and type values
     */
    public ngOnInit(): void {
        this.propertyFormControl.valueChanges
            .pipe(
                startWith(this.propertyFormControl.value),
                pairwise(),
                takeUntilDestroyed(this.destroyRef)
            )
            .subscribe(([prevProperty, property]: PropertyValueModel[]) => {
                if (prevProperty === property) return;

                // When changing to enum
                const isDropDownValue = property?.dataType?.type === EDataFieldTypes.ENUM ||
                    property?.dataType?.type === EDataFieldTypes.BOOLEAN;
                if (this.operatorFormControl.value !== PropertyOperator.LENGTH &&
                    (isDropDownValue || (this.inputType === 'dropdown' && !isDropDownValue))) {
                    this.valueFormControl.patchValue(null);
                }

                this.setOperators();

                if (this.operators.length) {
                    this.operatorFormControl.enable();
                } else {
                    this.operatorFormControl.patchValue(null);
                    this.operatorFormControl.disable();
                }

                if (prevProperty?.dataType?.type !== property?.dataType?.type) {
                    this.valueFormControl.patchValue(null);
                    this.valuePropertyFormControl.patchValue(null);
                    this.updatePropertyValueRuleProperties();
                }

                this.setTypes();
                this.setTypeValueRequired();
                this.setInputType();
            });

        // Subscribe to value change of the property to update inputType
        this.operatorFormControl.valueChanges
            .pipe(
                startWith(this.operatorFormControl.value),
                pairwise(),
                takeUntilDestroyed(this.destroyRef)
            )
            .subscribe(([previousOperator, operator]: PropertyOperator[]) => {
                if (previousOperator === operator) return;

                // Reset value if no longer compatible
                let value = this.formGroup.controls.value.value;
                let type = this.formGroup.controls.type.value;
                let typeValue = this.formGroup.controls.typeValue.value;
                switch (operator) {
                    case PropertyOperator.LENGTH:
                        value = parseInt(value as string, 10);
                        value = isNaN(value) ? null : value;
                        break;
                    case PropertyOperator.EVERY:
                    case PropertyOperator.SOME:
                        const property = this.propertyFormControl.value;
                        if (property?.dataType?.type !== EDataFieldTypes.ENUM) {
                            value = value?.toString();
                        } else if (!(value instanceof DropdownItem)) {
                            value = null;
                        }
                        break;
                    case PropertyOperator.EXISTS:
                    case PropertyOperator.NOT_EXISTS:
                        value = null;
                        type = null;
                        typeValue = null;
                        break;
                }
                this.valueFormControl.patchValue(value);
                this.typeValueFormControl.patchValue(typeValue);
                this.typeFormControl.patchValue(type);
                this.setTypes();
                this.setTypeValueRequired();
                if (!this.conditionTypes.find(conditionType => this.typeFormControl.value === conditionType)) {
                    this.typeFormControl.patchValue(null);
                }
                this.setInputType();
            });

        this.typeFormControl.valueChanges
            .pipe(
                startWith(this.typeFormControl.value),
                pairwise(),
                takeUntilDestroyed(this.destroyRef)
            )
            .subscribe(([previousType, type]: ConditionType[]) => {
                if (previousType === type) return;

                this.setTypeValueRequired();

                if (this.typeValueRequired()) {
                    this.typeValueFormControl.enable();

                    if (!this.typeValueFormControl.value) {
                        this.typeValueFormControl.patchValue(ConditionTypeValue.CUSTOM);
                    }

                    switch (this.typeValueFormControl.value) {
                        case ConditionTypeValue.PROPERTY:
                            this.valuePropertyFormControl.enable();
                            this.valueFormControl.patchValue(null);
                            this.valueFormControl.disable();
                            break;
                        case ConditionTypeValue.CUSTOM:
                        default:
                            this.valueFormControl.enable();
                            this.valuePropertyFormControl.patchValue(null);
                            this.valuePropertyFormControl.disable();
                            break;
                    }
                } else {
                    this.valuePropertyFormControl.patchValue(null);
                    this.valuePropertyFormControl.disable();

                    this.typeValueFormControl.patchValue(null);
                    this.typeValueFormControl.disable();

                    if (type?.valueRequired) {
                        this.valueFormControl.enable();
                    } else {
                        this.valueFormControl.patchValue(null);
                        this.valueFormControl.disable();
                    }
                }

                this.setInputType();
            });

        this.typeValueFormControl.valueChanges
            .pipe(
                startWith(this.typeValueFormControl.value),
                pairwise(),
                takeUntilDestroyed(this.destroyRef)
            )
            .subscribe(([previousTypeValue, typeValue]) => {
                if (previousTypeValue === typeValue) return;

                switch (this.typeValueFormControl.value) {
                    case ConditionTypeValue.PROPERTY:
                        this.valuePropertyFormControl.enable();

                        this.valueFormControl.patchValue(null);
                        this.valueFormControl.disable();
                        break;
                    case ConditionTypeValue.CUSTOM:
                    default:
                        this.valueFormControl.enable();

                        this.valuePropertyFormControl.patchValue(null);
                        this.valuePropertyFormControl.disable();
                        break;
                }

                this.setInputType();
            });

        this.updatePropertyValueRuleProperties();
        this.setOperators();
        this.setTypes();
        this.setTypeValueRequired();
        this.setInputType();
    }

    public setDropdownItems(dataType: string, enumList?: string[]): void {
        switch (dataType) {
            case EDataFieldTypes.ENUM:
                this.dropdownItems = enumList.map((item) => new DropdownItem(item, item));
                break;
            case EDataFieldTypes.BOOLEAN:
                this.dropdownItems = BooleanOption.OPTIONS;
                break;
            default:
                this.dropdownItems = [];
                break;
        }
    }

    /**
     * Set possible operators that can be used for selected property
     */
    public setOperators(): void {
        this.operators = PropertyOperator.getOperatorsForProperty(this.propertyFormControl.value);
    }

    /**
     * Set possible typeOperators that can be used for selected property dataType
     */
    public setTypes(): void {
        const propertyValue = this.propertyFormControl.value;

        let property: string;
        switch (this.operatorFormControl?.value) {
            case PropertyOperator.LENGTH:
                property = ConditionsConstants.DATA_TYPE_LENGTH;
                break;
            case PropertyOperator.EXISTS:
            case PropertyOperator.NOT_EXISTS:
                property = null;
                break;
            case PropertyOperator.EVERY:
            case PropertyOperator.SOME:
                property = this.propertyFormControl.value?.dataType?.type;
                break;
            default:
                if (!propertyValue?.isArray || (propertyValue?.arrayIndexSelected && propertyValue?.property?.type == ERulesetPropertyType.FIELD)) {
                    property = this.propertyFormControl.value?.dataType?.type;
                }
                break;
        }

        this.conditionTypes = ConditionType.getTypesForProperty(property);
        // No conditions, no value
        if (this.conditionTypes.length === 0) {
            this.valueFormControl.patchValue(null);
            this.valuePropertyFormControl.patchValue(null);
        }
    }

    /**
     * Set input type of the value fields based on the property datatype, operator and condition type
     */
    public setTypeValueRequired(): void {
        const property = this.propertyFormControl.value;
        const operator = this.operatorFormControl.value;
        const type = this.typeFormControl.value;

        let required = false;

        if (operator !== PropertyOperator.LENGTH) {
            const propertyDataType = property?.dataType?.type;

            switch (type) {
                case ConditionType.LOWER_OR_EQUAL:
                case ConditionType.LOWER_THAN:
                case ConditionType.GREATER_OR_EQUAL:
                case ConditionType.GREATER_THAN:
                    if (propertyDataType === EDataFieldTypes.DATE || propertyDataType === EDataFieldTypes.NUMBER) {
                        required = true;
                    }
                    break;
                case ConditionType.NOT_EQUALS:
                case ConditionType.EQUALS:
                case ConditionType.INCLUDES:
                case ConditionType.NOT_INCLUDES:
                    required = true;
                    break;
            }
        }

        this.typeValueRequired.set(required);
        if (this.typeValueRequired()) {
            this.typeValueFormControl.enable();
            if (!this.typeValueFormControl.value) {
                this.typeValueFormControl.patchValue(ConditionTypeValue.CUSTOM);
            }
        } else {
            this.typeValueFormControl.disable();
            this.typeValueFormControl.patchValue(null);
        }
    }

    private updatePropertyValueRuleProperties(): void {
        this.valuePropertyRuleProperties = RulePropertyModel.filterPropertiesOnDataType(this.propertyFormControl.value?.dataType?.type,
            this.ruleProperties);
    }

    /**
     * Set input type of the value fields based on the property datatype, operator and condition type
     */
    public setInputType(): void {
        const property = this.propertyFormControl.value;
        const operator = this.operatorFormControl.value;
        const typeValue = this.typeValueFormControl.value;
        const type = this.typeFormControl.value;

        // Reset input type if selected type is not selectable
        if (!this.conditionTypes.find(conditionType => conditionType === type)) {
            this.inputType = null;
            return;
        }

        switch (type) {
            case ConditionType.LEADING_LENGTH:
            case ConditionType.LEADING_LENGTH_GREATER_THAN:
            case ConditionType.LENGTH_GREATER_THAN:
            case ConditionType.LENGTH:
                this.inputType = EDataFieldTypes.NUMBER;
                break;
            case ConditionType.LOWER_OR_EQUAL:
            case ConditionType.LOWER_THAN:
            case ConditionType.GREATER_OR_EQUAL:
            case ConditionType.GREATER_THAN:
                switch (typeValue) {
                    case ConditionTypeValue.PROPERTY:
                        this.inputType = 'property';
                        break;
                    case ConditionTypeValue.CUSTOM:
                    default:
                        this.inputType = property?.dataType?.type === EDataFieldTypes.DATE ? EDataFieldTypes.DATE : EDataFieldTypes.NUMBER;
                        break;
                }
                break;
            case ConditionType.NOT_EQUALS:
            case ConditionType.EQUALS:
            case ConditionType.INCLUDES:
            case ConditionType.NOT_INCLUDES:
                if (operator === PropertyOperator.LENGTH) {
                    this.inputType = EDataFieldTypes.NUMBER;
                } else if (typeValue == ConditionTypeValue.PROPERTY) {
                    this.inputType = 'property';
                } else {
                    const propertyDataType = property?.dataType?.type as string;

                    switch(propertyDataType) {
                        case EDataFieldTypes.ENUM:
                            this.inputType = 'dropdown';
                            this.setDropdownItems(EDataFieldTypes.ENUM, property.dataType.enumeration?.items);
                            break;
                        case EDataFieldTypes.BOOLEAN:
                            this.inputType = 'dropdown';
                            this.setDropdownItems(EDataFieldTypes.BOOLEAN);
                            break;
                        case EDataFieldTypes.LIST:
                            this.inputType = operator || property.arrayIndexSelected ? EDataFieldTypes.STRING : null;
                            break;
                        case EDataFieldTypes.OBJECT_ID:
                            // TODO: Type ObjectId should show a drop down with values from the collection this data type refers too
                            this.inputType = EDataFieldTypes.STRING;
                            break;
                        case ConditionsConstants.DATA_TYPE_LENGTH:
                            // Normally, propertyDataType will only be length for array operator length
                            this.inputType = EDataFieldTypes.NUMBER;
                            break;
                        default:
                            this.inputType = propertyDataType;
                            break;
                    }
                }
                break;
            case ConditionType.REGEX:
                this.inputType = 'regex';
                break;
            case ConditionType.EXISTS:
            case ConditionType.NOT_EXISTS:
            default:
                this.inputType = null;
                break;
        }

        // Update validators, based on the inputType
        if (this.inputType) {
            switch (this.typeValueFormControl.value) {
                case ConditionTypeValue.PROPERTY:
                    this.valuePropertyFormControl.addValidators(Validators.required);
                    break;
                case ConditionTypeValue.CUSTOM:
                default:
                    this.valueFormControl.addValidators(Validators.required);
                    break;
            }
        } else {
            this.valueFormControl.removeValidators(Validators.required);
            this.valuePropertyFormControl.removeValidators(Validators.required);
        }

        // Apply the new validators
        this.valueFormControl.updateValueAndValidity();
        this.valuePropertyFormControl.updateValueAndValidity();
    }
}
