import {Component, Input, OnInit} from '@angular/core';
import {UntypedFormGroup} from '@angular/forms';
import {DropdownItem} from '../../../../../models/ui/dropdown-item.model';
import {IDropdownRequestDataEvent} from '@relayter/rubber-duck/lib/atoms/dropdown/dropdown.component';
import {StringUtil} from '../../../../../classes/string-util';

@Component({
    selector: 'product-synchronisation-mapping-form-component',
    templateUrl: 'product-synchronisation-mapping-form.component.html',
    styleUrls: ['product-synchronisation-mapping-form.component.scss']
})
export class ProductSynchronisationMappingFormComponent implements OnInit {
    @Input() public formGroup: UntypedFormGroup;
    @Input() public readonly dataFields: DropdownItem<string>[];
    public dataFieldOptions: DropdownItem<string>[];

    ngOnInit() {
        this.dataFieldOptions = this.dataFields;
    }

    public searchProductDataFields(event: IDropdownRequestDataEvent): void {
        this.dataFieldOptions = this.filterDropdownItems(this.dataFields, event.search);
    }

    private filterDropdownItems(sourceData: DropdownItem<string>[], search: string): DropdownItem<string>[] {
        try {
            const regex = new RegExp(StringUtil.escapeRegExp(search), 'i');
            if (search) {
                return sourceData.filter((item) => item.getTitle().match(regex)?.length > 0);
            } else {
                return sourceData;
            }
        } catch(err) {
            return sourceData;
        }
    }
}
