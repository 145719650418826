import {Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {AbstractControl, FormControl, UntypedFormControl, UntypedFormGroup, ValidatorFn} from '@angular/forms';
import {ARApiError, ARPagedResponseDataModel} from '@relayter/core';
import {Toaster} from '../../classes/toaster.class';
import {ChannelModel} from '../../models/api/channel.model';
import {RLValidatorConstants} from '../../classes/validators/rl-validators.constant';
import {PublicationModel, PublicationPatchModel, PublicationPostModel} from '../../models/api/publication.model';
import {Subject} from 'rxjs';
import {AppConstants} from '../../app.constants';
import {distinctUntilChanged, filter, takeUntil} from 'rxjs/operators';
import {EPublicationType} from '../../pages/relayter/templates/template-detail/publication-type.enum';
import {
    BUTTON_TYPE,
    ButtonConfig,
    FullModalActionModel,
    FullModalService,
    NUC_FULL_MODAL_DATA,
    NucDialogConfigModel,
    NucDialogService
} from '@relayter/rubber-duck';
import {RLSegmentService} from '../../services/segment/rl-segment.service';
import {PublicationsService} from '../../api/services/publications.service';
import {WorkflowConfigurationModel} from '../../models/api/workflow-configuration.model';
import {DropdownItem} from '../../models/ui/dropdown-item.model';
import {ISegmentService, SEGMENT_SERVICE} from '../../services/segment/segment.service.interface';
import {VariantService} from '../../api/services/variant.service';
import {VariantModel} from '../../models/api/variant.model';
import {CampaignService} from '../../api/services/campaigns.service';
import {PublicationTypesService} from '../../api/services/publication-types.service';
import {WorkflowConfigurationsService} from '../../api/services/workflow-configurations.service';

export interface IPublicationFormComponentData {
    campaignId: string;
    publication?: PublicationModel;
}

@Component({
    selector: 'rl-publication-form-component',
    templateUrl: 'publication-form.component.html',
    styleUrls: ['publication-form.component.scss']
})

export class PublicationFormComponent implements OnInit, OnDestroy {
    public formGroup: UntypedFormGroup;

    public pageSize: number = AppConstants.PAGE_SIZE_DEFAULT;
    public EPublicationType = EPublicationType;

    public channels: ChannelModel[] = [];

    public workflowConfigurations: WorkflowConfigurationModel[] = [];

    private onDestroySubject = new Subject<void>();

    private readonly campaignId: string;
    public readonly publicationToEdit: PublicationModel;

    private saveButton: ButtonConfig;
    private cancelButton: ButtonConfig;

    public tagOptions: DropdownItem<string>[];

    public variants: VariantModel[];

    constructor(private publicationTypesService: PublicationTypesService,
                @Inject(NUC_FULL_MODAL_DATA) private modalData: IPublicationFormComponentData,
                private fullModalService: FullModalService,
                private campaignService: CampaignService,
                private publicationsService: PublicationsService,
                private workflowConfigurationService: WorkflowConfigurationsService,
                private variantService: VariantService,
                private dialogService: NucDialogService,
                @Inject(SEGMENT_SERVICE) private segmentService: ISegmentService) {
        this.publicationToEdit = modalData.publication;
        this.campaignId = modalData.campaignId;
    }

    public ngOnInit(): void {
        this.initModalButtons();
        this.initForm();
    }

    public ngOnDestroy(): void {
        this.onDestroySubject.next();
        this.onDestroySubject.complete();
    }

    private initModalButtons(): void {
        // set up the buttons for full modal
        this.saveButton = new ButtonConfig(BUTTON_TYPE.PRIMARY, this.publicationToEdit ? 'Save' : 'Create', null, null, !this.publicationToEdit);
        this.cancelButton = new ButtonConfig(BUTTON_TYPE.SECONDARY, 'Cancel');
        const cancel = new FullModalActionModel(this.cancelButton);
        cancel.observable.subscribe(() => this.fullModalService.close(false, true));
        const save = new FullModalActionModel(this.saveButton);
        save.observable.subscribe(() => {
            this.publicationToEdit ? this.onEditClicked() : this.onCreateClicked();
        });
        this.fullModalService.setModalActions([cancel, save]);
    }

    private validateDeadline(): ValidatorFn {
        return (control: AbstractControl) => {
            return !control.pristine && control.value && control.value < new Date() ? {invalidDate: {value: control.value}} : null;
        };
    }

    private initForm(): void {
        this.formGroup = new UntypedFormGroup({
            name: new UntypedFormControl(this.publicationToEdit ? this.publicationToEdit.name : '', RLValidatorConstants.VALIDATOR_SETS.REQUIRED),
            tags: new UntypedFormControl(this.publicationToEdit && this.publicationToEdit.tags ?
                this.publicationToEdit.tags.map((tag: string) => new DropdownItem(tag, tag)) :
                []),
            variants: new UntypedFormControl(this.publicationToEdit && this.publicationToEdit.variants ?
                this.publicationToEdit.variants.map((variant) => new DropdownItem(variant, variant)) :
                []),
            publicationType: new UntypedFormControl(null, RLValidatorConstants.VALIDATOR_SETS.REQUIRED),
            workflow: new UntypedFormControl(null, RLValidatorConstants.VALIDATOR_SETS.REQUIRED),
            deadline: new FormControl(this.publicationToEdit ? this.publicationToEdit.deadline : null, [this.validateDeadline()])
        });

        this.formGroup.statusChanges.pipe(
            distinctUntilChanged(),
            takeUntil(this.onDestroySubject)
        ).subscribe((status) => {
            this.saveButton.disabled = status !== 'VALID'; // disable the button based on the form status
        });

        if (!this.publicationToEdit) { // when creating, there is no publication yet
            this.formGroup.controls.publicationType.valueChanges.pipe(
                filter((value) => !!value),
                takeUntil(this.onDestroySubject)
            ).subscribe((value) => {
                // Add new formControl
                // Reset workflow configurations and start loading new workflow-configurations
                this.workflowConfigurations = [];
                this.getWorkflowConfigurations(value.name);
            });
        } else {
            this.getWorkflowConfigurations(this.publicationToEdit.channel.name);
            this.formGroup.get('variants').disable({emitEvent: false});
            this.formGroup.get('publicationType').disable({emitEvent: false});
            this.formGroup.get('workflow').disable({emitEvent: false});
        }

        this.getChannels();
        this.getVariants();
    }

    private getChannels(): void {
        this.publicationTypesService.find()
            .pipe(takeUntil(this.onDestroySubject))
            .subscribe({
                next: (res: ARPagedResponseDataModel<ChannelModel>) => {
                    this.channels = res.items;
                    if (this.publicationToEdit?.channel) {
                        const update = {
                            publicationType: this.channels.find(channel => channel._id === this.publicationToEdit.channel._id)
                        };
                        this.formGroup.patchValue(update);
                    }
                },
                error: (err: ARApiError) => Toaster.handleApiError(err)
            });
    }

    public getVariants(): void {
        this.variantService.getVariants(this.campaignId)
            .pipe(takeUntil(this.onDestroySubject))
            .subscribe(variantData => {
                this.variants = variantData.items;
                // Set selected values
                if (this.publicationToEdit?.variants?.length) {
                    const selectedVariants = this.variants.filter((variant) =>
                        this.publicationToEdit.variants.some((selectedVariantId) => selectedVariantId === variant._id));
                    const update = {
                        variants: selectedVariants
                    };
                    this.formGroup.patchValue(update);
                }
            }, Toaster.handleApiError);

    }

    public getWorkflowConfigurations(publicationType: EPublicationType): void {
        this.workflowConfigurationService.findByPublicationType(publicationType)
            .subscribe({
                next: (res: ARPagedResponseDataModel<WorkflowConfigurationModel>) => {
                    this.workflowConfigurations = res.items;
                    if (this.workflowConfigurations.length) {
                        if (this.publicationToEdit) {
                            this.formGroup.patchValue({
                                workflow: this.workflowConfigurations.find(configuration =>
                                    configuration._id === this.publicationToEdit.workflow)
                            });
                        } else {
                            this.formGroup.patchValue({workflow: this.workflowConfigurations[0]});
                        }
                    }
                },
                error: Toaster.handleApiError
            });
    }

    public onTagChanged(event: string): void {
        this.tagOptions = event && event.length && event.trim().length ? [new DropdownItem<string>(event.trim(), event.trim())] : [];
    }

    /**
     * when clicking the Create button
     * show confirmation dialog when campaign has variants configured but no variants selected for publication
     */
    private onCreateClicked(): void {
        const tags = this.formGroup.value.tags.map((tag: DropdownItem<string>) => tag.getValue());
        const selectedVariants = this.formGroup.value.variants.map((selectedVariant) => selectedVariant._id);
        const publicationBody = new PublicationPostModel(
            this.formGroup.value.name.trim(),
            this.formGroup.value.publicationType._id,
            this.formGroup.value.workflow._id,
            tags,
            selectedVariants,
            this.formGroup.value.deadline);

        if (this.variants?.length > 0 && selectedVariants.length === 0) {
            const dialog = new NucDialogConfigModel('Create publication',
                'This publication currently has no selected variants, while the campaign has variants configured. ' +
                'Are you sure you want to continue? Variants cannot be added to a publication at a later time.');
            const confirmDialog = this.dialogService.openDialog(dialog);
            dialog.addAction('Cancel', BUTTON_TYPE.SECONDARY).subscribe(() => confirmDialog.close());
            dialog.addAction('Save', BUTTON_TYPE.PRIMARY).subscribe(() => {
                confirmDialog.close();
                this.saveButton.loading = true;
                this.postPublication(publicationBody);
            });
        } else {
            this.postPublication(publicationBody);
        }
    }

    private postPublication(publicationBody: PublicationPostModel): void {
        this.publicationsService.postPublicationsForCampaign(this.campaignId, publicationBody).subscribe({
            next: (res: PublicationModel) => {
                this.saveButton.loading = false;
                this.segmentService.track(RLSegmentService.TRACK_ADD_PUBLICATION(res.channel.name));
                Toaster.success('Publication added successfully');
                this.fullModalService.close(true);
            },
            error: (err: ARApiError) => this.handleSaveError(err)
        });
    }

    /**
     * when edit a publication
     */
    private onEditClicked(): void {
        this.saveButton.loading = true;
        const tags = this.formGroup.value.tags.map((tag: DropdownItem<string>) => tag.getValue());
        const deadline = this.formGroup.value.deadline;
        const publication = new PublicationPatchModel(this.formGroup.value.name.trim(), tags, deadline);

        this.publicationsService.updatePublicationsForCampaign(this.campaignId, this.publicationToEdit._id, publication).subscribe(
            () => {
                this.saveButton.loading = false;
                Toaster.success('Publication edited successfully');
                this.fullModalService.close(true);
            },
            (err: ARApiError) => this.handleSaveError(err));
    }

    private handleSaveError(err: ARApiError): void {
        this.saveButton.loading = false;
        Toaster.handleApiError(err);
    }

}
