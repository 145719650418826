import {Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {TemplateService} from '../../api/services/templates.service';
import {ARPagedResponseDataModel} from '@relayter/core';
import {Toaster} from '../../classes/toaster.class';
import {EEngineType, TemplateModel} from '../../models/api/template.model';
import {EPublicationType} from '../../pages/relayter/templates/template-detail/publication-type.enum';
import {BUTTON_TYPE, ButtonConfig, FullModalActionModel, FullModalService, NUC_FULL_MODAL_DATA} from '@relayter/rubber-duck';
import {PublicationsService} from '../../api/services/publications.service';
import {distinctUntilChanged, finalize, map, takeUntil} from 'rxjs/operators';
import {Subject, Subscription} from 'rxjs';
import {RLValidatorRegExConstants} from '../../classes/validators/rl-validator-regex.constant';
import {IDropdownItem} from '@relayter/rubber-duck/lib/interfaces/idropdown-item';
import {IDropdownRequestDataEvent} from '@relayter/rubber-duck/lib/atoms/dropdown/dropdown.component';

export interface IAddPageFormData {
    selectedPageType: number;
    publicationId: string;
    transition: string;
    tagsFilter: string[];
}

@Component({
    selector: 'rl-add-page-form-component',
    templateUrl: 'add-page-form.component.html',
    styleUrls: ['add-page-form.component.scss']
})

export class AddPageFormComponent implements OnInit, OnDestroy {
    public templateOptions: IDropdownItem<string>[] = [];
    public formGroup: UntypedFormGroup;
    private search: string;
    private offset: number;
    public totalItems = 0;
    public addButton: ButtonConfig;
    public templatesSubscription: Subscription;

    private onDestroySubject = new Subject<void>();

    constructor(private templatesService: TemplateService,
                private publicationService: PublicationsService,
                private fullModalService: FullModalService,
                @Inject(NUC_FULL_MODAL_DATA) private modalData: IAddPageFormData) {
    }

    public ngOnInit(): void {
        this.initButtons();
        this.getTemplates();
        this.initForm();
    }

    public ngOnDestroy(): void {
        this.onDestroySubject.next();
        this.onDestroySubject.complete();
    }

    public initButtons(): void {
        this.addButton = new ButtonConfig(BUTTON_TYPE.PRIMARY, 'Add', null, null, true);
        const cancelButton = new ButtonConfig(BUTTON_TYPE.SECONDARY, 'Cancel');

        const cancel = new FullModalActionModel(cancelButton);
        const add = new FullModalActionModel(this.addButton);

        cancel.observable.subscribe(() => this.fullModalService.close(false, true));
        add.observable.subscribe(() => this.onAddClicked());

        const actions = [cancel, add];
        this.fullModalService.setModalActions(actions);
    }

    public initForm(): void {
        this.formGroup = new UntypedFormGroup({
            template: new UntypedFormControl(null, Validators.required),
            amount: new UntypedFormControl(1,
                [Validators.required, Validators.min(1), Validators.max(100), Validators.pattern(RLValidatorRegExConstants.INTEGER)])
        });

        this.formGroup.statusChanges.pipe(
            distinctUntilChanged(),
            map((status) => status === 'VALID'),
            takeUntil(this.onDestroySubject)
        ).subscribe((isValid: boolean) => this.addButton.disabled = !isValid);
    }

    private getTemplates(): void {
        this.templatesSubscription?.unsubscribe();

        this.templatesSubscription = this.templatesService.getTemplates(EPublicationType.PRINT_MAGAZINE, EEngineType.INDESIGN, null, this.offset,
            this.modalData.selectedPageType, this.modalData.tagsFilter, this.search)
            .subscribe({
                next: (result: ARPagedResponseDataModel<TemplateModel>) => {
                    this.templateOptions.push(...result.items);
                    this.totalItems = result.total;
                },
                error: Toaster.handleApiError
            });
    }

    public onAddClicked(): void {
        this.addButton.loading = true;
        const templateId = this.formGroup.value.template.getValue();
        const amount = this.formGroup.value.amount;
        const data = {items: Array(amount).fill({templateId})};

        this.publicationService.postTransitionItem(this.modalData.publicationId, this.modalData.transition, data)
            .pipe(finalize(() => {
                this.addButton.loading = false;
                this.fullModalService.close();
            }))
            .subscribe({
                error: (error) => Toaster.handleApiError(error)
            });
    }

    public searchTemplates(event: IDropdownRequestDataEvent): void {
        if (event.reset) {
            this.templateOptions = [];
        }

        this.offset = event.offset;
        this.search = event.search;

        this.getTemplates();
    }
}
