import {Component, Inject, Input, OnChanges, OnInit, Optional, SimpleChanges} from '@angular/core';
import {
    CustomWorkflowStickyListComponent
} from '../../../custom-workflow-sticky-list/custom-workflow-sticky-list.component';
import {PublicationsService} from '../../../../../../../../api/services/publications.service';
import {UserService} from '../../../../../../../../api/services/users.service';
import {UserIsAllowedToPipe} from '../../../../../../../../pipes/user-is-allowed-to.pipe';
import {FullModalService, NUC_FULL_MODAL_DATA, NucDialogService} from '@relayter/rubber-duck';
import {
    ICustomWorkflowStickyLogModalData
} from '../../../custom-workflow-sticky-list/custom-workflow-sticky-log.component';
import {CustomWorkflowService} from '../../../custom-workflow.service';
import {UserSettingsStorageService} from '../../../../../../../../api/services/user-settings-storage.service';
import {AdvancedFiltersDataService} from '../../../../../../../../api/services/advanced-filters.data-service';
import {StickyNoteModel} from '../../../../../../../../models/api/sticky-note.model';
import {StickyNotesDataService} from '../sticky-notes-data.service';
import {RLDatePipe} from '../../../../../../../../pipes/rl-date.pipe';
import {DataFieldModel} from '../../../../../../../../models/api/data-field.model';
import {DropdownItem} from '../../../../../../../../models/ui/dropdown-item.model';
import {
    PublicationItemSelectionService
} from '../../../custom-workflow-item-selection/publication-item-selection.service';
import {EComponentActions, IShowStickyNotesActionOptions} from '../../custom-workflow-preview.component';
import {IQueryParam} from '../../../../../../../../classes/query-params';
import {Toaster} from '../../../../../../../../classes/toaster.class';
import {DataFilterModel} from '../../../../../../../../models/ui/data-filter.model';
import {EDataCollectionName, EDataFieldTypes, EStickyNoteStatus} from '../../../../../../../../app.enums';
import {CampaignItemModel} from '../../../../../../../../models/api/campaign-item.model';
import {PaginatorService} from '../../../../../../../../components/paginator/paginator.service';
import {CustomWorkflowActionModel} from '../../../../../../../../models/api/custom-workflow-action.model';
import {DataFieldsComponentUtil} from '../../../../../../../../classes/data-fields-component.util';
import {FormatFunction} from '@relayter/rubber-duck/lib/pipes/property.pipe';
import {DataFieldsApiService} from '../../../../../../../../api/services/data-fields.api.service';
import {EWorkflowActionOptionName} from '../../../../../../../../models/api/custom-workflow-option.model';
import {takeUntilDestroyed} from '@angular/core/rxjs-interop';
import {SortDirection} from '@angular/material/sort';

enum EPreviewStickyListSortProperty {
    PUBLICATION_ITEM = 'publicationItem',
    CREATED_AT = 'createdAt',
}

export interface IPreviewStickyListSort {
    sortProperty: EPreviewStickyListSortProperty;
    sortOrder: SortDirection;
}

@Component({
    selector: 'custom-workflow-preview-sticky-list-view',
    templateUrl: './sticky-list-view.component.html',
    styleUrls: ['./sticky-list-view.component.scss'],
    providers: [AdvancedFiltersDataService, PaginatorService]
})
export class StickyListViewComponent extends CustomWorkflowStickyListComponent implements OnInit, OnChanges {
    static readonly PREVIEW_STICKY_LIST_VIEW_SORT = 'preview-sticky-list-sort';
    protected readonly EPreviewStickyListSortProperty = EPreviewStickyListSortProperty;
    public tableId = 'custom-workflow-preview-sticky-list-view';
    public readonly dateFormats = RLDatePipe.dateFormats;

    public briefingItemFields: { label: string; property: string }[] = [];
    @Input() private campaignItemDataFields: DataFieldModel[];
    @Input() private actions: CustomWorkflowActionModel[];
    public stickies: StickyNoteModel[] = [];

    public showStickyNotesAction: CustomWorkflowActionModel;
    public showStickyNotesActionOptions: IShowStickyNotesActionOptions;
    public dataFieldFormatter: FormatFunction;

    // sort related
    public sortOptions: DropdownItem<string>[];
    public sortValue: DropdownItem<any>;
    public sortAsc: boolean = true;

    constructor(protected publicationService: PublicationsService,
                protected userService: UserService,
                protected userIsAllowedToPipe: UserIsAllowedToPipe,
                @Optional() @Inject(NUC_FULL_MODAL_DATA) protected modalData: ICustomWorkflowStickyLogModalData,
                protected dialogService: NucDialogService,
                protected fullModalService: FullModalService,
                public customWorkflowService: CustomWorkflowService,
                userSettingsStorageService: UserSettingsStorageService,
                filtersDataService: AdvancedFiltersDataService,
                paginatorService: PaginatorService,
                private stickyNotesDataService: StickyNotesDataService,
                private publicationItemSelectionService: PublicationItemSelectionService,
                protected dataFieldService: DataFieldsApiService) {
        super(publicationService, userService, userIsAllowedToPipe, modalData, dialogService, fullModalService, customWorkflowService,
            userSettingsStorageService, filtersDataService, paginatorService, dataFieldService);
    }

    public selectStickyNote(note: StickyNoteModel): void {
        // TODO: find workflowItem of this sticky
        this.publicationItemSelectionService.navigateToPublicationItem(note.publicationItem._id);
        this.stickyNotesDataService.setSelectedStickyNote(note);
    }

    public selectPubItem(note: StickyNoteModel, event: MouseEvent): void {
        // TODO: find workflowItem of this sticky
        this.publicationItemSelectionService.navigateToPublicationItem(note.publicationItem._id);
        event.stopPropagation();
    }

    public ngOnInit(): void {
        this.prepareSorting();
        super.ngOnInit();
        this.setupPaginatorService();

        this.stickyNotesDataService.refreshStickyNotes$.pipe(
            takeUntilDestroyed(this.destroyRef)
        ).subscribe(() => {
            this.refreshData();
        });
    }

    public ngOnChanges(changes: SimpleChanges): void {
        if (changes.actions) {
            this.showStickyNotesAction = this.actions.find((action) => action.name === EComponentActions.ShowStickyNotes);
            if (this.showStickyNotesAction?.options?.find((option) => option.name === EWorkflowActionOptionName.DISPLAY)) {
                this.showStickyNotesActionOptions = {
                    display: this.showStickyNotesAction.options.find((option) => option.name === EWorkflowActionOptionName.DISPLAY)
                } as IShowStickyNotesActionOptions;
            }
            this.setCampaignItemData();
            this.setupStickyNoteSubscription();
            this.dataFilters = this.getDataFilters();
        }
    }

    protected getFilterValues(): IQueryParam[] {
        const componentFilters = this.showStickyNotesAction?.fromStatusFilter;
        const filters = [];
        if (this.filterValues && this.filterValues.length > 0) {
            this.filterValues.forEach((filterValue) => {
                const foundFilter = componentFilters.find(item => item.queryParam === filterValue.queryParam);
                if (foundFilter) {
                    // check if the selected values are available in the workflow configured filters.
                    if (Array.isArray(filterValue.value) && filterValue.value.every(value => foundFilter.value.includes(value))) {
                        filters.push(filterValue);
                    } else if (foundFilter.value.includes(filterValue.value as string)) {
                        filters.push(filterValue);
                    } else {
                        Toaster.error('Filters used are not allowed by the workflow config');
                    }
                } else {
                    filters.push(filterValue);
                }
            });
            // Add component filter if it is not overwritten by the user configured filters
            this.addComponentFilters(componentFilters, filters);
            return filters;
        } else {
            return componentFilters;
        }
    }

    private addComponentFilters(componentFilters: IQueryParam[], filters: IQueryParam[]): void {
        componentFilters.forEach((componentFilter) => {
            if (!filters.find(filter => filter.queryParam === componentFilter.queryParam)) {
                filters.push(componentFilter);
            }
        });
    }

    private setupStickyNoteSubscription(): void {
        this.stickyNotes$.pipe(
            takeUntilDestroyed(this.destroyRef)
        ).subscribe((results: StickyNoteModel[]) => {
            if (this.pageIndex === 1) {
                this.stickies = results;
            } else {
                this.stickies.push(...results);
            }
        });

        this.stickyNotesDataService.updatedLinkedCampaignItem$.pipe(
            takeUntilDestroyed(this.destroyRef)
        ).subscribe((updatedCampaignItem: CampaignItemModel) => {
            if (this.stickies.length > 0) {
                this.stickies.forEach((sticky) => {
                    if (sticky.campaignItem && sticky.campaignItem._id === updatedCampaignItem._id) {
                        sticky.campaignItem = updatedCampaignItem;
                    }
                });
            }
        });
    }

    private prepareSorting(): void {
        this.sortOptions = [
            new DropdownItem('Publication item', EPreviewStickyListSortProperty.PUBLICATION_ITEM),
            new DropdownItem('Date created', EPreviewStickyListSortProperty.CREATED_AT)
        ];

        const storedSort = this.getStoredSort();
        const sortOption = this.sortOptions.find((sortOption) => sortOption.getValue() === storedSort.sortProperty);
        const sortAsc = storedSort.sortOrder === 'asc';

        this.setSort(sortOption, sortAsc);
    }

    private setCampaignItemData(): void {
        const campaignItemDisplayOption = this.showStickyNotesActionOptions?.display;

        if (campaignItemDisplayOption && this.campaignItemDataFields.length > 0) {
            for (const displayProperty of campaignItemDisplayOption.value) {
                const label = this.campaignItemDataFields.find(item => item.fieldName === displayProperty);
                if (label) {
                    this.briefingItemFields.push({
                        label: label.name,
                        property: displayProperty
                    });
                }
            }
        }
    }

    private setSort(sortOption: DropdownItem<string>, sortAsc: boolean): void {
        this.sortValue = sortOption;
        this.sortAsc = sortAsc;

        let sortProperty: string;

        if (sortOption.getValue() === EPreviewStickyListSortProperty.PUBLICATION_ITEM) {
            // means we sort by publicationItem, then we need to join the stored publication item sort property
            const publicationItemSort =
                this.publicationItemSelectionService.getStoredSort(this.publication.channel.name);
            sortProperty = EPreviewStickyListSortProperty.PUBLICATION_ITEM + '.' + publicationItemSort.sortProperty;
            this.sortOrder = publicationItemSort.sortOrder;
            this.sortAsc = this.sortOrder === 'asc';
        } else {
            sortProperty = this.sortValue.getValue();
            this.sortOrder = this.sortAsc ? 'asc' : 'desc';
        }

        this.storeSort(sortOption.getValue(), this.sortOrder);
        this.sortOption = {sortProperty, sortDuplicates: true};
    }

    public onSortChanged(sortOption: DropdownItem<string>, sortAsc: boolean): void {
        if (sortOption !== this.sortValue || this.sortAsc !== sortAsc) {
            this.setSort(sortOption, sortAsc);
            this.refreshData();
        }
    }

    public loadMore(): void {
        this.pageIndex++;
        this.refresh$.next();
    }

    protected getDataFilters(): DataFilterModel[] {
        const statusesFilter = this.showStickyNotesAction?.from || null;
        const filters = [];
        if (statusesFilter) {
            filters.push(new DataFilterModel(
                'Status',
                'statuses',
                EDataFieldTypes.ENUM,
                Object.values(EStickyNoteStatus).filter(key => statusesFilter.includes(key)).map(key => new DropdownItem(key, key))
            ));
        }
        filters.push(new DataFilterModel(
            'User name',
            'createdBy.fullName',
            EDataFieldTypes.LIST,
            null,
            null,
            {publication: this.publication._id, ['publicationItem.step']: this.step?._id},
            EDataCollectionName.STICKY_NOTE
        ));
        return filters;
    }

    private setupPaginatorService() {
        this.paginatorService.getStoredPageSize(this.viewId)
            .pipe(takeUntilDestroyed(this.destroyRef))
            .subscribe((pageSize: number) => {
                this.pageSize = pageSize;
            });
    }

    public refreshData(): void {
        super.refreshData();
        this.dataFieldFormatter = DataFieldsComponentUtil.getDataFieldFormatter(null, this.activeVariant?.key);
    }

    private storeSort(sortProperty: string, sortOrder: SortDirection): void {
        this.userSettingsStorageService.storeSettings(StickyListViewComponent.PREVIEW_STICKY_LIST_VIEW_SORT, {sortProperty, sortOrder});
    }

    private getStoredSort(): IPreviewStickyListSort {
        let storedSort = this.userSettingsStorageService.loadSettings(StickyListViewComponent.PREVIEW_STICKY_LIST_VIEW_SORT);
        if (!storedSort || !storedSort.sortProperty) {
            storedSort = {sortProperty: EPreviewStickyListSortProperty.CREATED_AT, sortOrder: 'asc'};
            this.userSettingsStorageService.storeSettings(StickyListViewComponent.PREVIEW_STICKY_LIST_VIEW_SORT, storedSort);
        }
        return storedSort;
    }
}
