import {StackOption} from '../format-ruleset.constants';
import {Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {
    BUTTON_TYPE,
    ButtonConfig,
    FullModalActionModel,
    FullModalService,
    NUC_FULL_MODAL_DATA
} from '@relayter/rubber-duck';
import {DataCollectionService} from '../../../api/services/data-collection.service';
import {FormatRulesetService} from '../../../api/services/format-ruleset.service';
import {FormatRulesetItemGroupModel} from '../models/api/format-ruleset-item-group.model';
import {Toaster} from '../../../classes/toaster.class';
import {map, takeUntil} from 'rxjs/operators';
import {Subject} from 'rxjs';

export interface IFormatRulesetItemGroupFormComponentData {
    ruleSetId: string;
    itemGroup: FormatRulesetItemGroupModel;
}

@Component({
    selector: 'format-ruleset-item-group-form',
    templateUrl: './format-ruleset-item-group-form.component.html',
    styleUrls: ['./format-ruleset-item-group-form.component.scss']
})
export class FormatRulesetItemGroupFormComponent implements OnInit, OnDestroy {
    public formGroup: FormGroup;
    public stackOptions = StackOption.OPTIONS;
    public nameControl = new FormControl('', Validators.required);
    public limitControl = new FormControl(0, [Validators.required, Validators.min(0)]);
    public stackControl = new FormControl();
    private modalData: IFormatRulesetItemGroupFormComponentData;
    protected onDestroySubject = new Subject<void>();

    constructor(private fullModalService: FullModalService,
                private ruleSetService: FormatRulesetService,
                private dataCollectionService: DataCollectionService,
                @Inject(NUC_FULL_MODAL_DATA) modelData: IFormatRulesetItemGroupFormComponentData) {
        this.modalData = modelData;
        this.formGroup = new FormGroup({
            name: this.nameControl,
            limit: this.limitControl,
            stack: this.stackControl
        });
    }

    public ngOnInit(): void {
        this.initButtonsAndForm();
    }

    public ngOnDestroy() {
        this.onDestroySubject.next();
        this.onDestroySubject.complete();
    }

    private initButtonsAndForm() {
        const saveButtonConfig = new ButtonConfig(
            BUTTON_TYPE.PRIMARY,
            this.modalData.itemGroup ? 'Save' : 'Create',
            false,
            false,
            this.formGroup.status !== 'VALID');
        const saveAction = new FullModalActionModel(saveButtonConfig);
        const cancelAction = new FullModalActionModel(
            new ButtonConfig(
                BUTTON_TYPE.SECONDARY,
                'Cancel'));
        const actions = [
            cancelAction,
            saveAction,
        ];

        cancelAction.observable.subscribe(() => this.fullModalService.close(false, true));
        saveAction.observable.subscribe(() => this.saveItem());

        this.fullModalService.setModalActions(actions);

        if (this.modalData.itemGroup) {
            const patch = {
                name: this.modalData.itemGroup.name,
                limit: this.modalData.itemGroup.limit,
                stack: StackOption.getByValue(this.modalData.itemGroup.stack)
            };

            this.formGroup.patchValue(patch);
        }
        this.formGroup.statusChanges.pipe(
            map((status) => status === 'VALID'),
            takeUntil(this.onDestroySubject)
        ).subscribe((valid) => saveButtonConfig.disabled = !valid);
    }

    private saveItem() {
        const itemGroup = new FormatRulesetItemGroupModel(
            this.formGroup.value.name,
            this.formGroup.value.limit,
            this.formGroup.value.stack ? this.formGroup.value.stack.getValue() : undefined);

        const observable = this.modalData.itemGroup
            ? this.ruleSetService.updateFormatRulesetItemGroup(this.modalData.ruleSetId, this.modalData.itemGroup._id, itemGroup)
            : this.ruleSetService.postFormatRulesetItemGroup(this.modalData.ruleSetId, itemGroup);

        observable.subscribe((result) => {
            Toaster.success(`Successfully ${this.modalData.itemGroup ? 'updated' : 'created'} item group: ${itemGroup.name}`);
            this.fullModalService.close(result);
        }, Toaster.handleApiError);
    }

}
