import {autoserialize, autoserializeAs, inheritSerialization} from 'cerialize';
import {ITableItem} from '../interfaces/table-item.interface';
import {IDropdownItem} from '@relayter/rubber-duck/lib/interfaces/idropdown-item';
import {UserTeamModel} from './user-team.model';
import {RoleModel} from './role.model';

class BaseUserModel implements IDropdownItem, ITableItem {
    @autoserialize public _id: string;
    @autoserialize public email: string;
    @autoserialize public fullName: string;
    /**
     * IDropdownItem implementation
     * @returns {string}
     */
    public getValue(): string {
        return this._id;
    }

    /**
     * IDropdownItem implementation
     * @returns {string}
     */
    public getTitle(): string {
        return this.fullName;
    }
}

@inheritSerialization(BaseUserModel)
export class UserModel extends BaseUserModel {
    @autoserializeAs(RoleModel, 'permissionGroups') public roles: RoleModel[] = [];
}

@inheritSerialization(BaseUserModel)
export class UpdateUserModel extends BaseUserModel {
    @autoserializeAs('permissionGroups') public roles: string[] = [];
}

@inheritSerialization(UserModel)
export class UserDetailModel extends UserModel {
    @autoserialize public acceptPolicy: boolean;
    @autoserialize public acceptTracking: boolean;
    @autoserialize public userHash: string;
    @autoserializeAs(UserTeamModel) public team: UserTeamModel;
}
