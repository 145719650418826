import {
    HttpEvent,
    HttpHandler,
    HttpInterceptor,
    HttpRequest,
} from '@angular/common/http';
import {Observable, Subject, throwError} from 'rxjs';
import {UserStorage} from '../classes/user-storage.class';
import {AppConstants} from '../app.constants';
import {catchError, filter, switchMap, take} from 'rxjs/operators';
import {AuthorizeService} from '../api/services/authorize.service';
import {UserService} from '../api/services/users.service';
import {ApiConstants} from '../api/api.constant';
import {Injectable} from '@angular/core';
import {ErrorConstants} from '../api/error.constants';

@Injectable()
export class JwtInterceptor implements HttpInterceptor {

    private isRefreshing = false;
    private refreshTokenSubject = new Subject<string>();

    constructor(private userService: UserService,
                private authorizeService: AuthorizeService
    ) {
    }

    /**
     * Patch the incoming request Authorization header with our access token
     * @param request
     * @param token
     */
    private patchRequestWithAccessToken(request: HttpRequest<any>, token: string): HttpRequest<any> {
        return request.clone({
            setHeaders: {
                Authorization: token
            }
        });
    }

    /**
     * Intercept any http request.
     * If we do requests to Amazon we can't intercept, so continue as usual.
     * Else
     * Add the access token (jwt) if in storage.
     * Listen for errors on any http request and see if it's an 401 else continue as usual.
     * If 401 we try to call our `/authenticate` end point to retrieve a new access token.
     * The retry the earlier failed request
     *
     * @param {HttpRequest<any>} request
     * @param {HttpHandler} next
     * @returns {Observable<HttpEvent<any>>}
     */
    public intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

        // TODO: Remove this and fix this per request in the services instead
        // This prevents us in sending the access token to Amazon (e.g. uploading an image)
        const isException = request.url.includes(AppConstants.AMAZON_AWS_REQUEST) || request.url.includes('auth/register');
        if (isException) {
            return next.handle(request);
        }

        // Only when we've got an access token in storage, we patch it in the Authorization header
        const token = UserStorage.getAccessToken();
        if (token) {
            // Patch every request with our current stored access token
            request = this.patchRequestWithAccessToken(request, token);
        }

        // Listen for errors on the requests
        return next.handle(request).pipe(catchError((error) => {
            // Ignore certain end points
            const ignoreCalls = [
                `/${ApiConstants.API_GROUP_USERS}/${ApiConstants.API_METHOD_LOGIN}`,
                ApiConstants.API_METHOD_AUTHORIZE, ApiConstants.API_GROUP_EXCHANGE_AUTH_TOKEN,
                ApiConstants.API_GROUP_EXCHANGE_AUTH0_ID_TOKEN
            ];
            if (ignoreCalls.some((path) => request.url.includes(path))) {
                return throwError(() => error);
            }

            // We only listen for the unauthorized response
            if (error.status === 401) {

                if (this.isRefreshing) {
                    // This will keep subscribers on 'hold' and signal them when there is the new access token arrived
                    return this.refreshTokenSubject.pipe(
                        filter((res) => res !== null),
                        take(1),
                        switchMap((newToken: string) => {
                            return next.handle(this.patchRequestWithAccessToken(request, newToken));
                        }));
                }
                // Start the refreshing flow
                this.isRefreshing = true;

                this.refreshTokenSubject.next(null);
                // When authorize is returned
                return this.authorizeService.authorize().pipe(
                    switchMap((newToken: string) => {
                        // Store the new access token
                        UserStorage.setAccessToken(newToken);
                        this.isRefreshing = false;
                        this.refreshTokenSubject.next(newToken);
                        // patch the request with the new access token and continue
                        request = this.patchRequestWithAccessToken(request, newToken);
                        // This ensures the earlier failed request is retried again
                        return next.handle(request);
                    }),
                    catchError((err: any) => {
                        this.isRefreshing = false;
                        // This is a last resort when the authorize call fails (failed in retrieving a new access token)
                        // Still need to pass the error to the original request handler, otherwise it will receive a 'null' response,
                        // which can lead to null pointer errors
                        if (err.error?.error_code === ErrorConstants.API_ERROR_CODES.SESSION_REVOKED) {
                            this.userService.forcedSessionClose();
                        }
                        return throwError(() => err);
                    })
                );
            }
            // All other kind of errors are ignored (rethrow the error) and let the caller handle the error as usual.
            return throwError(() => error);
        }));
    }
}
