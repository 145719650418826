import {autoserialize, autoserializeAs} from 'cerialize';
import {ITableItem} from '@relayter/rubber-duck';
import {EPublicationType} from '../../pages/relayter/templates/template-detail/publication-type.enum';

export enum ECustomWorkflowTransitionRecipeTaskName {
    CREATE_POS_ITEMS = 'CREATE_POS_ITEMS',
    CREATE_WEB_ITEMS = 'CREATE_WEB_ITEMS',
    CREATE_SPREADS = 'CREATE_SPREADS',
    EXECUTE_AUTOMATIONS = 'EXECUTE_AUTOMATIONS',
    GENERATE_POS_ITEMS = 'GENERATE_POS_ITEMS',
    GENERATE_PRINT_ITEMS = 'GENERATE_PRINT_ITEMS',
    GENERATE_WEB_ITEMS = 'GENERATE_WEB_ITEMS',
    MOVE_UPLOADS = 'MOVE_UPLOADS',
    UPLOAD_ITEM_FILES = 'UPLOAD_ITEM_FILES',
    KOOYMAN_REASSIGNING_ITEMS = 'KOOYMAN_REASSIGNING_ITEMS',
    UPDATE_INDESIGN_CONTENT = 'UPDATE_INDESIGN_CONTENT',
    SEND_NOTIFICATIONS = 'SEND_NOTIFICATIONS',
    CREATE_PACKAGE_ITEMS = 'CREATE_PACKAGE_ITEMS',
    REFRESH_CAMPAIGN_PACKAGES = 'REFRESH_CAMPAIGN_PACKAGES',
    REJECT_RULES = 'REJECT_RULES',
    REJECT_SIGN_OFF_ROLES = 'REJECT_SIGN_OFF_ROLES',
    MOVE_ORPHANED_STICKY_NOTES = 'MOVE_ORPHANED_STICKY_NOTES',
    SAVE_LAYOUT_REVISION = 'SAVE_LAYOUT_REVISION',
    REMOVE_APPROVALS = 'REMOVE_APPROVALS',
    SAVE_FILES_REVISION = 'SAVE_FILES_REVISION',
    DELETE_PUBLICATION_ITEMS = 'DELETE_PUBLICATION_ITEMS',
    REMOVE_FILES = 'REMOVE_FILES',
    UPLOAD_ASSETS = 'UPLOAD_ASSETS',
    DELETE_ORPHANED_NOTES = 'DELETE_ORPHANED_NOTES',
    CREATE_LINKS = 'CREATE_LINKS',
    SEND_LINKS = 'SEND_LINKS'
}

export const CUSTOM_WORKFLOW_DESTRUCTIVE_RECIPE_TASK_NAMES: Record<string, (itemsTxt: string, pubItems: string) => string> =
    {
        [ECustomWorkflowTransitionRecipeTaskName.DELETE_PUBLICATION_ITEMS]: (itemsTxt: string, pubItems: string): string =>
            `Are you sure you want to delete the publication ${itemsTxt} ${pubItems}?
                All data and files of the ${itemsTxt} will be permanently removed`,
        [ECustomWorkflowTransitionRecipeTaskName.REMOVE_FILES]: (itemsTxt: string, pubItems: string): string =>
            `Are you sure you want to remove files and revisions for the publication ${itemsTxt} ${pubItems}?
                All files and revisions (for configured variants) of the ${itemsTxt} will be permanently removed`
    };

// eslint-disable-next-line max-len
export const RECIPE_TASK_PUBLICATION_ITEM_LINK = '/campaigns/{campaignId}/publications/{publicationId}/items/{publicationItemId}/uploads?variantId={variantId}';

export enum ERecipeTaskNotificationType {
    CREATE_LINKS = 'CREATE_LINKS',
    BETWEEN_STEPS_TRANSITION = 'BETWEEN_STEPS_TRANSITION'
}

export enum ERecipeTaskCollection {
    STICKY_NOTE = 'RLStickyNote',
    PUBLICATION_ITEM = 'RLPublicationItem'
}

export enum ERecipeTaskType {
    PER_VARIANT,
    REJECT_RULE,
    NOTIFICATION
}
export class RecipeTaskConfig {
    public task: ECustomWorkflowTransitionRecipeTaskName;
    public title: string;
    public progressTitle: string;
    public publicationTypes?: EPublicationType[];
    public recipeTaskType?: ERecipeTaskType;
    // when allowedTasksBefore is null, any task can be before the current one
    // when allowedTasksBefore is [], the current task has to be the first one
    // allowedTasksBefore only checks the closest task before the current one
    public allowedTasksBefore?: ECustomWorkflowTransitionRecipeTaskName[];
    public onlyTask?: boolean;
    public excludeTasks?: ECustomWorkflowTransitionRecipeTaskName[];
}

export const CUSTOM_WORKFLOW_TRANSITION_RECIPE_TASKS: RecipeTaskConfig[] =
    [
        {
            task: ECustomWorkflowTransitionRecipeTaskName.CREATE_POS_ITEMS,
            title: 'Create POS items',
            progressTitle: 'POS items created',
            allowedTasksBefore: [ECustomWorkflowTransitionRecipeTaskName.EXECUTE_AUTOMATIONS],
            publicationTypes: [EPublicationType.POS]
        },
        {
            task: ECustomWorkflowTransitionRecipeTaskName.CREATE_WEB_ITEMS,
            title: 'Create web items',
            progressTitle: 'Web items created',
            allowedTasksBefore: [ECustomWorkflowTransitionRecipeTaskName.EXECUTE_AUTOMATIONS],
            publicationTypes: [EPublicationType.WEB]
        },
        {
            task: ECustomWorkflowTransitionRecipeTaskName.CREATE_SPREADS,
            title: 'Create print items',
            progressTitle: 'Print items created',
            allowedTasksBefore: [],
            publicationTypes: [EPublicationType.PRINT_MAGAZINE]
        },
        {
            task: ECustomWorkflowTransitionRecipeTaskName.GENERATE_POS_ITEMS,
            title: 'Generate POS items',
            progressTitle: 'POS items generated',
            recipeTaskType: ERecipeTaskType.PER_VARIANT,
            publicationTypes: [EPublicationType.POS]
        },
        {
            task: ECustomWorkflowTransitionRecipeTaskName.GENERATE_PRINT_ITEMS,
            title: 'Generate print items',
            progressTitle: 'Print items generated',
            recipeTaskType: ERecipeTaskType.PER_VARIANT,
            publicationTypes: [EPublicationType.PRINT_MAGAZINE]
        },
        {
            task: ECustomWorkflowTransitionRecipeTaskName.GENERATE_WEB_ITEMS,
            title: 'Generate web items',
            progressTitle: 'Web items generated',
            recipeTaskType: ERecipeTaskType.PER_VARIANT,
            publicationTypes: [EPublicationType.WEB]
        },
        {
            task: ECustomWorkflowTransitionRecipeTaskName.EXECUTE_AUTOMATIONS,
            title: 'Run workflow automations',
            progressTitle: 'Workflow automations ran',
            allowedTasksBefore: [],
            publicationTypes: [EPublicationType.POS, EPublicationType.WEB]
        },
        {
            task: ECustomWorkflowTransitionRecipeTaskName.MOVE_UPLOADS,
            title: 'Upload items',
            progressTitle: 'Uploads processed'
        },
        {
            task: ECustomWorkflowTransitionRecipeTaskName.UPLOAD_ITEM_FILES,
            title: 'Upload items for a publication item',
            progressTitle: 'Uploads processed'
        },
        {
            task: ECustomWorkflowTransitionRecipeTaskName.KOOYMAN_REASSIGNING_ITEMS,
            title: 'Reassigning items',
            progressTitle: 'Items reassigned'
        },
        {
            task: ECustomWorkflowTransitionRecipeTaskName.UPDATE_INDESIGN_CONTENT,
            title: 'Update InDesign content',
            progressTitle: 'InDesign content updated'
        },
        {
            task: ECustomWorkflowTransitionRecipeTaskName.SEND_NOTIFICATIONS,
            title: 'Process items for notifications',
            progressTitle: 'Items processed for notifications',
            recipeTaskType: ERecipeTaskType.NOTIFICATION
        },
        {
            task: ECustomWorkflowTransitionRecipeTaskName.CREATE_PACKAGE_ITEMS,
            title: 'Create package items',
            progressTitle: 'Package items created'
        },
        {
            task: ECustomWorkflowTransitionRecipeTaskName.REFRESH_CAMPAIGN_PACKAGES,
            title: 'Refresh packages',
            progressTitle: 'Refresh packages tasks done'
        },
        {
            task: ECustomWorkflowTransitionRecipeTaskName.REJECT_RULES,
            title: 'Reject transition with rules',
            progressTitle: 'Items checked for rejection',
            recipeTaskType: ERecipeTaskType.REJECT_RULE
        },
        {
            task: ECustomWorkflowTransitionRecipeTaskName.REJECT_SIGN_OFF_ROLES,
            title: 'Reject transition with sign off rules',
            progressTitle: 'Items checked for sign off rejection',
            recipeTaskType: ERecipeTaskType.REJECT_RULE
        },
        {
            task: ECustomWorkflowTransitionRecipeTaskName.MOVE_ORPHANED_STICKY_NOTES,
            title: 'Move orphaned notes',
            progressTitle: 'Items checked for notes location'
        },
        {
            task: ECustomWorkflowTransitionRecipeTaskName.SAVE_LAYOUT_REVISION,
            title: 'Save layout revision',
            progressTitle: 'Layout revision saved'
        },
        {
            task: ECustomWorkflowTransitionRecipeTaskName.REMOVE_APPROVALS,
            title: 'Remove approvals',
            progressTitle: 'Approvals removed'
        },
        {
            task: ECustomWorkflowTransitionRecipeTaskName.SAVE_FILES_REVISION,
            title: 'Save files revision',
            progressTitle: 'Files revision saved'
        },
        {
            task: ECustomWorkflowTransitionRecipeTaskName.DELETE_PUBLICATION_ITEMS,
            title: 'Delete publication item',
            progressTitle: 'Publication items deleted',
            onlyTask: true
        },
        {
            task: ECustomWorkflowTransitionRecipeTaskName.REMOVE_FILES,
            title: 'Remove files',
            progressTitle: 'Item files removed',
            recipeTaskType: ERecipeTaskType.PER_VARIANT
        },
        {
            task: ECustomWorkflowTransitionRecipeTaskName.UPLOAD_ASSETS,
            title: 'Upload assets',
            progressTitle: 'Assets uploaded',
            recipeTaskType: ERecipeTaskType.PER_VARIANT,
            excludeTasks: [
                ECustomWorkflowTransitionRecipeTaskName.CREATE_POS_ITEMS,
                ECustomWorkflowTransitionRecipeTaskName.CREATE_SPREADS,
                ECustomWorkflowTransitionRecipeTaskName.CREATE_WEB_ITEMS,
                ECustomWorkflowTransitionRecipeTaskName.GENERATE_POS_ITEMS,
                ECustomWorkflowTransitionRecipeTaskName.GENERATE_PRINT_ITEMS,
                ECustomWorkflowTransitionRecipeTaskName.GENERATE_WEB_ITEMS,
                ECustomWorkflowTransitionRecipeTaskName.UPDATE_INDESIGN_CONTENT,
                ECustomWorkflowTransitionRecipeTaskName.EXECUTE_AUTOMATIONS,
                ECustomWorkflowTransitionRecipeTaskName.MOVE_UPLOADS,
                ECustomWorkflowTransitionRecipeTaskName.UPLOAD_ITEM_FILES
            ]
        },
        {
            task: ECustomWorkflowTransitionRecipeTaskName.DELETE_ORPHANED_NOTES,
            title: 'Delete orphaned notes',
            progressTitle: 'Orphaned notes deleted'
        },
        {
            task: ECustomWorkflowTransitionRecipeTaskName.CREATE_LINKS,
            title: 'Create authorized links',
            recipeTaskType: ERecipeTaskType.PER_VARIANT,
            progressTitle: 'Links created'
        },
        {
            task: ECustomWorkflowTransitionRecipeTaskName.SEND_LINKS,
            title: 'Send links',
            recipeTaskType: ERecipeTaskType.PER_VARIANT,
            progressTitle: 'Links sent'
        }
    ];

export class CustomWorkflowTransitionRecipeTaskModel {
    @autoserialize public name: ECustomWorkflowTransitionRecipeTaskName;
    // TODO: make this more strict when we have a better structure of parameters
    @autoserialize public parameters: Record<string, any>;
}

export class CustomWorkflowTransitionModel implements ITableItem {
    @autoserialize public _id: string;
    @autoserialize public name: string;
    @autoserialize public title: string;
    @autoserialize public to: string;
    @autoserialize public from: string;
    @autoserializeAs(CustomWorkflowTransitionRecipeTaskModel) public recipe: CustomWorkflowTransitionRecipeTaskModel[];

    public static getRecipeTaskProgressTitle(recipeTask: ECustomWorkflowTransitionRecipeTaskName): string {
        return CUSTOM_WORKFLOW_TRANSITION_RECIPE_TASKS.find(recipeConfig => recipeConfig.task === recipeTask)?.progressTitle || 'Items done';
    }
}
