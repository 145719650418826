<div class="row">
    <div class="button" [class]="contentChange.action | lowercase"></div>
    <div class="content">
        <p class="header body-strong black">{{contentChange.action | titlecase}} {{contentChange.type | contentTypeDisplayPipe}}</p>
        <ng-container [ngSwitch]="contentChange.type">
            <ng-container class="briefing-container" *ngSwitchCase="SPREAD_CONTENT_TYPES.CAMPAIGN_ITEM">
                <ng-container *ngIf="contentChange.contentItem.campaignItem else deletedBriefingItem">
                    <div class="field" *ngFor="let relayterFieldSetting of propertySettings.relayterFields">
                        <p class="body-strong">{{relayterFieldSetting.title}}</p>
                        <span>{{contentChange.contentItem.campaignItem | propertySettingDisplay:relayterFieldSetting}}</span>
                    </div>

                    <div class="field" *ngFor="let dataFieldSetting of propertySettings.dataFields">
                        <p class="body-strong">{{dataFieldSetting.title}}</p>
                        <span>{{contentChange.contentItem.campaignItem | propertySettingDisplay: dataFieldSetting: activeVariant?.key}}</span>
                    </div>
                </ng-container>
                <ng-template #deletedBriefingItem>
                    <p>{{DELETED_BRIEFING_ITEM}}</p>
                </ng-template>
            </ng-container>
            <div class="asset-container" *ngSwitchCase="SPREAD_CONTENT_TYPES.ASSET">
                <div class="thumbnail">
                    <img [src]="contentChange.contentItem.asset?.getThumbnailMedia().url || 'assets/images/icon_image_main.svg'">
                </div>
                <p>{{contentChange.contentItem.asset?.rin | nullUndefinedFormatter:DELETED_ASSET}}</p>
            </div>
            <div class="note-container" *ngSwitchCase="SPREAD_CONTENT_TYPES.NOTE">
                <p>{{contentChange.action === EContentChangeAction.EDITED ? 'Note' : contentChange.contentItem.note.message}}</p>
            </div>
        </ng-container>
    </div>
    <div class="nucicon_clock_line"></div>
</div>

<div class="layout-search" *ngIf="contentChange.action === EContentChangeAction.REMOVED
                                    && contentChange.type === SPREAD_CONTENT_TYPES.CAMPAIGN_ITEM
                                    && contentChange.contentItem.campaignItem">
    <div class="content-container">
        <hr />
        <div class="content-item">
            <div class="text">
                <p class="body-strong">Layouted in</p>
                <p>{{foundItems ? foundItems.length ? 'the following layout(s)' : 'This item is not in any layouts' : 'Find this item in all layouts'}}</p>
            </div>
            <nuc-button-secondary *ngIf="!foundItems || (loading === false && foundItems?.length > 0)"
                                  icon="nucicon_images_line"
                                  nucTooltip="Find this item in all layouts"
                                  nucTooltipPosition="left"
                                  [loading]="loading"
                                  (click)="findPublicationItems(contentChange.contentItem.campaignItem._id)"></nuc-button-secondary>
        </div>
        <div class="content-item" *ngFor="let item of foundItems">
            <nuc-badge-dove>Page {{item.formattedPageNumbers}}</nuc-badge-dove>
            <a [href]="item | workflowUrl:campaign:publication" target="_blank">
                <nuc-icon-button icon="nucicon_open" nucTooltipPosition="left" nucTooltip="Go to layout"></nuc-icon-button>
            </a>
        </div>
    </div>
</div>
