<div>
    <nuc-button-bar>
        <nuc-button-secondary *ngIf="user?._id === team?.owner._id"
                              text="Transfer ownership"
                              (click)="onTransferOwnershipButtonClicked()">

        </nuc-button-secondary>
        <nuc-button-primary text="Change password"
                            (click)="onChangePasswordButtonClicked()"
                            i18n>
        </nuc-button-primary>
    </nuc-button-bar>

    <div class="account_management__form">
        <rl-account-management-component *ngIf="user" [user]="user"></rl-account-management-component>

        <div class="info-row">
            <nuc-checkbox [disabled]="true" [data]="true"></nuc-checkbox>
            <span>I agree Relayter's <a [routerLink]="['/auth/terms-of-use']" target="_blank">Terms of use</a></span>
        </div>
        <br>
        <div class="info-row">
            <nuc-checkbox [disabled]="true" [data]="true"></nuc-checkbox>
            <span>I agree Relayter's <a [routerLink]="['/auth/privacy-policy']" target="_blank">Privacy Policy</a></span>
        </div>

    </div>
</div>

<rl-relayter-version></rl-relayter-version>
