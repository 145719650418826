import {Component, DestroyRef, EventEmitter, inject, Input, OnInit, Output} from '@angular/core';
import {FormBuilder, FormGroup} from '@angular/forms';
import {RLValidatorConstants} from '../../../../classes/validators/rl-validators.constant';
import {distinctUntilChanged, map} from 'rxjs/operators';
import {ConnectionDataService} from '../../connection.data-service';
import {ConnectionModel, EConnectionType} from '../../../../models/api/connection.model';
import {takeUntilDestroyed} from '@angular/core/rxjs-interop';

@Component({
    selector: 'media-valet-connection-form',
    templateUrl: './media-valet-connection-form.component.html',
    styleUrls: ['./media-valet-connection-form.component.scss'],
})
export class MediaValetConnectionFormComponent implements OnInit {
    protected destroyRef: DestroyRef = inject(DestroyRef);
    public form: FormGroup;

    public validationMessages: any;

    public logo = ConnectionModel.getLogo(EConnectionType.MEDIA_VALET);

    @Input() public formData: Record<string, any>;
    @Output() public formSubmitted: EventEmitter<Record<string, any>> = new EventEmitter<Record<string, any>>();

    constructor(private fb: FormBuilder,
                private connectionDataService: ConnectionDataService) {
    }

    ngOnInit(): void {
        this.setupFormGroup();

        this.form.statusChanges.pipe(
            map((status) => {
                return status === 'VALID';
            }),
            distinctUntilChanged(),
            takeUntilDestroyed(this.destroyRef)
        ).subscribe((valid) => {
            this.connectionDataService.setSaveButtonStateDisabled(!valid);
        });

        this.connectionDataService.setSaveButtonStateDisabled(this.form.status !== 'VALID');

        this.connectionDataService.saveButtonClickEvent$.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(() => {
            if (this.form.status === 'VALID') {
                this.onSubmitValid();
            }
        });
    }

    private setupFormGroup(): void {
        const mediaValetConnection = this.formData;
        this.form = this.fb.group({
            clientId: [mediaValetConnection?.clientId || '', RLValidatorConstants.VALIDATOR_SETS.REQUIRED],
            clientSecret: [mediaValetConnection?.clientSecret || '', RLValidatorConstants.VALIDATOR_SETS.REQUIRED],
            name: [mediaValetConnection?.name || '', RLValidatorConstants.VALIDATOR_SETS.REQUIRED],
            subscriptionKey: [mediaValetConnection?.subscriptionKey || '', RLValidatorConstants.VALIDATOR_SETS.REQUIRED]
        });
    }

    public onSubmitValid(): void {
        this.connectionDataService.setSaveButtonStateLoading(true);
        this.formSubmitted.emit(this.form.value);
    }
}
