<div class="header-row">
    <h1>InDesign library overview</h1>
    <nuc-button-bar>
        <nuc-button-primary *ngIf="permissions.POST_INDESIGN_LIBRARY_JOBS | userIsAllowedTo"
                            text="Add InDesign library"
                            (click)="openLibraryModal()" i18n>
        </nuc-button-primary>
    </nuc-button-bar>
</div>

<ng-container *ngIf="(permissions.GET_LIBRARY_ITEMS | userIsAllowedTo); else noPermissionState">
    <nuc-table [loading]="!indesignLibrarySubject?.closed"
               [columns]="columns"
               [items]="items"
               [actions]="actions"
               [tableId]="tableId"
               [prefixStorageKey]="storageKey"
               [selectionMode]="ESelectionMode.EMIT"
               (itemClicked)="onTableRowClicked($event)"
               (sortOptionChange)="onSortOptionChanged($event)"
               (actionClicked)="handleTableRowAction($event)">
        <paginator button-bar-right
                   [loading]="!indesignLibrarySubject?.closed"
                   [viewId]="tableId"
                   [disableNextPage]="disableNextPage">
        </paginator>
    </nuc-table>
</ng-container>

<ng-template #noPermissionState>
    <no-permission-state-component></no-permission-state-component>
</ng-template>
