import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {FormBuilder, FormGroup} from '@angular/forms';
import {RLValidatorConstants} from '../../../../classes/validators/rl-validators.constant';
import {distinctUntilChanged, map, startWith, takeUntil} from 'rxjs/operators';
import {Subject} from 'rxjs';
import {ConnectionDataService} from '../../connection.data-service';
import {EConnectionType, ConnectionModel} from '../../../../models/api/connection.model';

@Component({
    selector: 'consumer-webhook-connection-form',
    templateUrl: './consumer-webhook-connection-form.component.html',
    styleUrls: ['./consumer-webhook-connection-form.component.scss'],
})
export class ConsumerWebhookConnectionFormComponent implements OnInit, OnDestroy {
    public form: FormGroup;

    public validationMessages: any;

    public logo = ConnectionModel.getLogo(EConnectionType.WEBHOOK_CONSUMER);

    private onDestroySubject = new Subject<void>();

    @Input() public formData: Record<string, any>;
    @Output() public formSubmitted: EventEmitter<Record<string, any>> = new EventEmitter<Record<string, any>>();

    constructor(private fb: FormBuilder,
                private connectionDataService: ConnectionDataService) {
    }

    public ngOnInit(): void {
        this.setupFormGroup();

        this.form.statusChanges.pipe(
            map((status) => {
                return status === 'VALID';
            }),
            distinctUntilChanged(),
            startWith(false),
            takeUntil(this.onDestroySubject)
        ).subscribe((valid) => {
            this.connectionDataService.setSaveButtonStateDisabled(!valid);
        });

        this.connectionDataService.saveButtonClickEvent$.pipe(takeUntil(this.onDestroySubject)).subscribe(() => {
            if (this.form.status === 'VALID') {
                this.onSubmitValid();
            }
        });
    }

    public ngOnDestroy(): void {
        this.onDestroySubject.next();
        this.onDestroySubject.complete();
    }

    private setupFormGroup(): void {
        const connection = this.formData;
        this.form = this.fb.group({
            name: [connection?.name || '', RLValidatorConstants.VALIDATOR_SETS.REQUIRED]
        });
    }

    public onSubmitValid(): void {
        this.connectionDataService.setSaveButtonStateLoading(true);
        this.formSubmitted.emit(this.form.value);
    }
}
