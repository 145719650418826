<div class="selected-items-count-bar">
    <div class="selection-row">
        <nuc-checkbox text="Select all {{totalItemCount ? totalItemCount : null}} search results"
                      textPosition="end"
                      [disabled]="!(searchValue && totalItemCount)"
                      [data]="selectAllChecked"
                      (dataChange)="onSelectAllChanged($event)">
        </nuc-checkbox>
        <button type="button"
                class="help-button"
                nucTooltipPosition="right"
                nucTooltip="Use search to select all the results to a maximum of 100 products to use this option.">
            ?</button>
    </div>
    <p>{{selectedProducts.length}} product(s) selected</p>
</div>
<rl-collection-view [items]="products"
                    [propertySettingsContext]="EPropertySettingsContext.PRODUCT"
                    (collectionViewOptionsChanged)="onCollectionViewOptionsChanged()"
                    [selectedItems]="selectedProducts"
                    (collectionViewItemClicked)="onCollectionViewItemClicked($event)"
                    [(sort)]="sort"
                    [(sortDescending)]="sortDescending"
                    [disableNextPage]="disableNextPage"
                    [sortingOptions]="sortingOptions"
                    [disableSort]="!!searchValue"
                    [isLoading]="!productServiceSubscription?.closed"
                    [isSearching]="!!searchValue"
                    [selectMultipleItems]="true"
                    [searchPlaceholder]="'Search products for data fields'"
                    (onSearchValueUpdated)="onSearchBarValueUpdated($event)"
                    [disablePageOptions]="!!searchValue"
                    [viewId]="tableId"
                    class="smooth-animated">
</rl-collection-view>
