import {autoserialize} from 'cerialize';
import {EDataFieldTypes} from '../../app.enums';
import {DropdownItem} from '../ui/dropdown-item.model';
import {BooleanOption} from '../../modules/format-rulesets/format-ruleset.constants';

export type ConditionBaseType = boolean | string | number;
export type ConditionValueType = ConditionBaseType | BooleanOption | Date | DropdownItem<ConditionBaseType>

export class RuleConditionModel {
    @autoserialize public property: string;
    @autoserialize public operator?: string;
    @autoserialize public type: string;
    @autoserialize public typeValue: string;
    @autoserialize public dataType?: EDataFieldTypes;
    @autoserialize public value?: ConditionValueType|ConditionBaseType;
    @autoserialize public valueProperty?: string;

    constructor(property?: string, type?: string, valueType?: string, value?: ConditionValueType|ConditionBaseType,  valueProperty?: string,
                operator?: string, dataType?: EDataFieldTypes) {
        this.property = property;

        if (value !== null && value !== undefined) this.value = value;
        if (valueProperty !== null) this.valueProperty = valueProperty;
        if (type) this.type = type;
        if (valueType) this.typeValue = valueType;
        if (operator) this.operator = operator;
        if (dataType) this.dataType = dataType;
    }
}
