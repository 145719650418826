<form *ngIf="formGroup else loadingIndicator" [formGroup]="formGroup" class="container">
    <div class="workflow-filter">
        <nuc-form-field label="Name">
            <nuc-input formControlName="name"
                       placeholder="Fill in a filter name">
            </nuc-input>
        </nuc-form-field>
        <nuc-form-field label="Type">
            <nuc-dropdown formControlName="type"
                          [nullOption]="false"
                          [total]="filterTypeOptions.length"
                          [items]="filterTypeOptions"
                          placeholder="Select a filter type"
                          (dataChange)="onFilterTypeChange($event?.getValue())">
            </nuc-dropdown>
        </nuc-form-field>
        <nuc-form-field label="Query parameter" class="query-param-field"
                        *ngIf="formGroup.value.type?.getValue() === EWorkflowConfigurationFilterType.ITEM_FIELD_FILTER">
            <property-control
                formControlName="queryParam"
                [ignoreIndex]="propertyControlOptions.ignoreIndex"
                [variants]="variants"
                [ruleProperties]="queryParamPropertyOptions" />
        </nuc-form-field>
        <nuc-form-field *ngIf="formGroup.value.type?.getValue() === EWorkflowConfigurationFilterType.CONDITIONS_FILTER"
                        label="Condition filter identifier" class="query-param-condition" nucTooltip="Identifies the filter and has to be unique within this workflow configuration">
            <nuc-input
                formControlName="queryParam"
                placeholder="Fill in a workflow condition filter identifier"
                [formGroup]="formGroup"/>
        </nuc-form-field>
        <nuc-error *ngIf="formGroup.get('queryParam').invalid && formGroup.get('queryParam').hasError('pattern')">
            Identifier may only contain alphanumeric characters
        </nuc-error>
        <ng-container
            *ngIf="formGroup.value.type?.getValue() === EWorkflowConfigurationFilterType.EXTERNAL_PUBLICATION_ITEMS">
            <div>
                <h1 class="title">Filters</h1>
                <p>Set up filters. Select publication types to filter publication items.</p>
                <hr/>
            </div>
            <div formGroupName="filters">
                <nuc-form-field label="Publication types">
                    <nuc-dropdown-multiselect formControlName="publicationTypes"
                                              [items]="publicationTypeOptions"
                                              placeholder="Select one or more publication types">
                    </nuc-dropdown-multiselect>
                </nuc-form-field>
            </div>
        </ng-container>
    </div>
    <ng-container *ngIf="formGroup.value.type?.getValue() === EWorkflowConfigurationFilterType.CONDITIONS_FILTER">
        <div class="workflow-filter-items">
            <h1>Filter items</h1>
            <p>Set up filter items. Title will be displayed in the workflow filter dropdown and will apply the configured conditions.</p>
            <hr/>

            <div *ngFor="let itemGroup of formGroup.controls.items?.controls; let iIndex = index">
                <div class="workflow-filter-item" [formGroup]="itemGroup">
                    <div>Filter item #{{ iIndex + 1 }}</div>
                    <nuc-button-secondary icon="nucicon_trash_fill" class="delete-button"
                                          (click)="deleteItem(iIndex)">
                    </nuc-button-secondary>
                    <nuc-form-field label="Title">
                        <nuc-input formControlName="title"
                                   placeholder="Enter title">
                        </nuc-input>
                    </nuc-form-field>

                    <div class="conditions">
                        <h2>Condition(s)</h2>
                        <p>Conditions applied for the filter</p>
                        <ng-container
                            *ngFor="let conditionGroup of formGroup.controls.items.at(iIndex).controls.conditions.controls; let cIndex = index">
                            <condition-form class="condition"
                                            [formGroup]="conditionGroup"
                                            [variants]="variants"
                                            [condition]="getCondition(iIndex, cIndex)"
                                            (deleteClicked)="deleteCondition(iIndex, cIndex)"
                                            [ruleProperties]="conditionPropertyOptions">
                            </condition-form>
                        </ng-container>
                        <div class="add-button">
                            <nuc-button-secondary icon="nucicon_add" (click)="addCondition(iIndex)"
                                                  nucTooltip="Add condition">
                            </nuc-button-secondary>
                        </div>
                    </div>
                </div>
            </div>
            <div class="add-button">
                <nuc-button-secondary text="Add item" (click)="addItem()" nucTooltip="Add item">
                </nuc-button-secondary>
            </div>
        </div>
    </ng-container>
</form>

<ng-template #loadingIndicator>
    <rl-loading-indicator size="large"></rl-loading-indicator>
</ng-template>
