import {Injectable} from '@angular/core';
import {ApiConstants} from '../api.constant';
import {Observable} from 'rxjs';
import {environment} from '../../../environments/environment';
import {ARApiUrlBuilderService, ARPagedResponseDataModel, ARRequestOptions} from '@relayter/core';
import {BaseApiRequestService} from './base-api-request.service';
import {EEngineType, TemplateBodyModel, TemplateDetailModel, TemplateModel} from '../../models/api/template.model';
import {TemplateUsageModel} from '../../models/api/template-usage.model';
import {QueryParams} from '../../classes/query-params';
import {Serialize} from 'cerialize';
import {EPublicationType} from '../../pages/relayter/templates/template-detail/publication-type.enum';
import {SortDirection} from '@angular/material/sort';

@Injectable({
    providedIn: 'root'
})
export class TemplateService extends BaseApiRequestService {
    public getTemplates(publicationType: EPublicationType, engineType: EEngineType, limit?: number, offset?: number,
                        pages?: number, tags?: string[], search?: string,
                        templateType?: string, sortProperty?: string,
                        sortOrder?: SortDirection, publicationId?: string,
                        campaignItemId?: string): Observable<ARPagedResponseDataModel<TemplateModel>> {

        const queryParams = new QueryParams()
            .setLimitAndOffsetParams(limit, offset)
            .setSortAndSortDirectionParams(sortProperty, sortOrder)
            .setSearchParams(search)
            .addParam('templateTypeId', templateType)
            .addParam('engineType', engineType)
            .addParam('publicationType', publicationType);

        if (pages > 0) {
            queryParams.addParam('pages', pages);
        }
        if (publicationId) {
            queryParams.addParam('publicationId', publicationId);
        }
        if (campaignItemId) {
            queryParams.addParam('campaignItemId', campaignItemId);
        }
        if (tags?.length > 0) {
            queryParams.addParam('tags', tags);
        }
        const url = ARApiUrlBuilderService.urlFromComponents([environment.API_SERVER,
            ApiConstants.API_BASE_PATH, ApiConstants.API_GROUP_TEMPLATES], queryParams.getParams());
        const options: ARRequestOptions = new ARRequestOptions();
        options.method = ApiConstants.REQUEST_METHODS.GET;
        options.url = url;
        return new Observable((obs) => {
            this.handlePagedResponse(options, obs, TemplateModel);
        });
    }

    /**
     * Get the details of a template
     * @param id
     * @returns {Observable<TemplateModel>}
     */
    public getTemplateDetails(id: string): Observable<TemplateDetailModel> {
        const url = ARApiUrlBuilderService.urlFromComponents([environment.API_SERVER,
            ApiConstants.API_BASE_PATH, ApiConstants.API_GROUP_TEMPLATES, id]);
        const options: ARRequestOptions = new ARRequestOptions();
        options.method = ApiConstants.REQUEST_METHODS.GET;
        options.url = url;
        return new Observable((obs) => {
            this.handleDetailResponse(options, obs, TemplateDetailModel);
        });
    }

    /**
     * Check if a template is in use
     * @param {string} templateId
     * @returns {Observable<TemplateUsageModel>}
     */
    public getTemplateUsage(templateId: string): Observable<TemplateUsageModel> {
        const url = ARApiUrlBuilderService.urlFromComponents([environment.API_SERVER,
            ApiConstants.API_BASE_PATH, ApiConstants.API_GROUP_TEMPLATES, templateId, ApiConstants.API_METHOD_USAGE]);
        const options: ARRequestOptions = new ARRequestOptions();
        options.method = ApiConstants.REQUEST_METHODS.GET;
        options.url = url;
        return new Observable((obs) => {
            this.handleDetailResponse(options, obs, TemplateUsageModel);
        });
    }

    /**
     * Post a new template to the API
     * @param templateData
     * @returns {any}
     */
    public postTemplate(templateData: TemplateBodyModel): Observable<TemplateModel> {
        const url = ARApiUrlBuilderService.urlFromComponents([environment.API_SERVER,
            ApiConstants.API_BASE_PATH, ApiConstants.API_GROUP_TEMPLATES]);
        const options: ARRequestOptions = new ARRequestOptions();
        options.method = ApiConstants.REQUEST_METHODS.POST;
        options.url = url;
        options.body = Serialize(templateData);
        return new Observable((obs) => {
            this.handleDetailResponse(options, obs, TemplateModel);
        });
    }

    /**
     * Post a new template to the API
     * @param id
     * @param templateData
     * @returns {any}
     */
    public updateTemplate(id: string, templateData: TemplateBodyModel): Observable<TemplateModel> {
        const url = ARApiUrlBuilderService.urlFromComponents([environment.API_SERVER,
            ApiConstants.API_BASE_PATH, ApiConstants.API_GROUP_TEMPLATES, id]);
        const options: ARRequestOptions = new ARRequestOptions();
        options.method = ApiConstants.REQUEST_METHODS.PUT;
        options.url = url;
        options.body = Serialize(templateData);
        return new Observable((obs) => {
            this.handleDetailResponse(options, obs, TemplateModel);
        });
    }

    /**
     * Deletes the template
     * @param {string} templateId
     * @returns {Observable<boolean>}
     */
    public deleteTemplate(templateId: string): Observable<boolean> {
        const url = ARApiUrlBuilderService.urlFromComponents([environment.API_SERVER,
            ApiConstants.API_BASE_PATH, ApiConstants.API_GROUP_TEMPLATES, templateId]);
        const options: ARRequestOptions = new ARRequestOptions();
        options.method = ApiConstants.REQUEST_METHODS.DELETE;
        options.url = url;
        return new Observable((obs) => {
            this.handleNoErrorResponse(options, obs);
        });
    }
}
