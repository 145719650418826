import {autoserialize, autoserializeAs, inheritSerialization} from 'cerialize';
import {UserTeamModel} from './user-team.model';
import {UserModel} from './user.model';

enum EContractPeriod {
    MONTH = 'MONTH',
    YEAR= 'YEAR'
}

export class ContractModel {
    @autoserializeAs(Date) public startDate: Date;
    @autoserialize public period: EContractPeriod;
}

@inheritSerialization(UserTeamModel)
export class TeamModel extends UserTeamModel {
    @autoserialize public companyName: string;
    @autoserialize public country: string;
    @autoserialize public userLimit: number;
    @autoserialize public userCount: number;
    @autoserialize public storageLimit: number;
    @autoserialize public publicationItemLimit: number; // per contract period
    @autoserialize public storageUsed: number;
    @autoserialize public publicationItemCreatedInCurrentContractPeriod: number;
    @autoserializeAs(ContractModel) public contract: ContractModel; // per contract period
    @autoserializeAs(UserModel) public owner: UserModel;
}
