import {Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {UntypedFormControl, UntypedFormGroup} from '@angular/forms';
import {Toaster} from '../../../../../../../classes/toaster.class';
import {Subject, Subscription} from 'rxjs';
import {
    BUTTON_TYPE,
    ButtonConfig,
    FullModalActionModel,
    FullModalService,
    NUC_FULL_MODAL_DATA
} from '@relayter/rubber-duck';
import {PublicationsService} from '../../../../../../../api/services/publications.service';
import {DropdownItem} from '../../../../../../../models/ui/dropdown-item.model';
import {PublicationItemModel} from '../../../../../../../models/api/publication-item.model';
import {RLValidatorConstants} from '../../../../../../../classes/validators/rl-validators.constant';
import {ETemplateType} from '../../../../../templates/template-detail/template-size/template-size.model';
import {TemplateService} from '../../../../../../../api/services/templates.service';
import {EEngineType, TemplateModel} from '../../../../../../../models/api/template.model';
import {distinctUntilChanged, finalize, takeUntil} from 'rxjs/operators';
import {AppConstants} from '../../../../../../../app.constants';
import {PublicationModel} from '../../../../../../../models/api/publication.model';
import {TemplateTypeModel} from '../../../../../../../models/api/template-type.model';
import {TemplateTypeService} from '../../../../../../../api/services/template-types.service';
import {EPublicationType} from '../../../../../templates/template-detail/publication-type.enum';

export interface IPublicationItemFormComponentData {
    publication: PublicationModel;
    publicationItem?: PublicationItemModel;
}

@Component({
    selector: 'rl-publication-item-form-component',
    templateUrl: 'publication-item-form.component.html',
    styleUrls: ['publication-item-form.component.scss']
})
// this form can be used as create & update
export class PublicationItemFormComponent implements OnInit, OnDestroy {
    private readonly publication: PublicationModel;
    private readonly publicationItemToEdit: PublicationItemModel;

    public formGroup: UntypedFormGroup;
    private onDestroySubject = new Subject<void>();

    // for print magazine
    public readonly pageTypeOptions: DropdownItem<number>[] = [
        new DropdownItem(ETemplateType.Single, 1),
        new DropdownItem(ETemplateType.Spread, 2)
    ];

    public typeOptions: TemplateTypeModel[] | DropdownItem<number>[] = [];

    private templateSubscription: Subscription;
    private templateIndexPage = 0;
    public totalTemplates: number;
    public templates: TemplateModel[] = [];
    private templatePageSize = AppConstants.PAGE_SIZE_DEFAULT;
    private selectedTemplateId: string;

    private saveButton: ButtonConfig;
    public tagOptions: DropdownItem<string>[];

    constructor(@Inject(NUC_FULL_MODAL_DATA) private modalData: IPublicationItemFormComponentData,
                private fullModalService: FullModalService,
                private publicationsService: PublicationsService,
                private templateService: TemplateService,
                private templateTypeService: TemplateTypeService) {
        this.publication = modalData.publication;

        this.publicationItemToEdit = modalData.publicationItem;
        this.selectedTemplateId = this.publicationItemToEdit.template?._id;
    }

    public ngOnInit(): void {
        this.initButtons();
        this.initForm();
    }

    public ngOnDestroy(): void {
        this.onDestroySubject.next();
        this.onDestroySubject.complete();
    }

    private initButtons(): void {
        // set up the buttons for full modal
        this.saveButton = new ButtonConfig(BUTTON_TYPE.PRIMARY, 'Save', null, null, false);
        const cancelButton = new ButtonConfig(BUTTON_TYPE.SECONDARY, 'Cancel');
        const cancel = new FullModalActionModel(cancelButton);
        cancel.observable.subscribe(() => this.fullModalService.close(false, true));
        const save = new FullModalActionModel(this.saveButton);
        save.observable.subscribe(() => {
            if (this.publicationItemToEdit) {
                this.onEditClicked();
            }
        });
        this.fullModalService.setModalActions([cancel, save]);
    }

    private initForm(): void {
        this.formGroup = new UntypedFormGroup({
            name: new UntypedFormControl({value: this.publicationItemToEdit.publicationItemId, disabled: true},
                RLValidatorConstants.VALIDATOR_SETS.REQUIRED),
            tags: new UntypedFormControl(this.publicationItemToEdit.tags.map((tag: string) => new DropdownItem(tag, tag))),
            template: new UntypedFormControl('', RLValidatorConstants.VALIDATOR_SETS.REQUIRED)
        });

        this.formGroup.get('template').valueChanges.pipe(
            distinctUntilChanged(),
            takeUntil(this.onDestroySubject)
        ).subscribe((template: TemplateModel) => this.selectedTemplateId = template?._id);

        this.formGroup.statusChanges.pipe(
            distinctUntilChanged(),
            takeUntil(this.onDestroySubject)
        ).subscribe((status) => {
            this.saveButton.disabled = status !== 'VALID'; // disable the button based on the form status
        });

        if (this.publication.channel.name === EPublicationType.PRINT_MAGAZINE || this.publication.channel.name === EPublicationType.POS) {
            this.formGroup.addControl('templateType', new UntypedFormControl(null, RLValidatorConstants.VALIDATOR_SETS.REQUIRED));
            this.formGroup.get('templateType').valueChanges
                .pipe(
                    distinctUntilChanged(),
                    takeUntil(this.onDestroySubject)
                )
                .subscribe((value) => {
                    this.templateIndexPage = 0;
                    this.templates = [];
                    if (value) this.getTemplates();
                });
        }

        switch (this.publication.channel.name) {
            case EPublicationType.PRINT_MAGAZINE: {
                this.typeOptions = this.pageTypeOptions;
                const pageType = this.typeOptions.find((item) => {
                    return item.getValue() === (this.publicationItemToEdit?.numberOfPages || 1);
                });
                this.formGroup.patchValue({templateType: pageType});
                break;
            }
            case EPublicationType.POS: {
                this.templateTypeService.getTemplateTypes(AppConstants.PAGE_SIZE_MAX, 0)
                    .pipe(takeUntil(this.onDestroySubject))
                    .subscribe({
                        next: (result) => {
                            this.typeOptions = result.items;
                            const foundType = this.typeOptions.find((type) => type._id === this.publicationItemToEdit?.template?.templateType._id);
                            this.formGroup.patchValue({templateType: foundType});
                        },
                        error: Toaster.handleApiError
                    });
                break;
            }
            case EPublicationType.WEB:
                this.getTemplates();
                break;
            default:
                return;
        }

    }

    /**
     * when edit a publication item
     */
    private onEditClicked(): void {
        this.saveButton.loading = true;

        const tags = this.formGroup.value.tags.map((tag: DropdownItem<string>) => tag.getValue());
        const patchBody = {tags};
        if (this.formGroup.value.template._id !== this.publicationItemToEdit?.template?._id) {
            patchBody['template'] = this.formGroup.value.template._id;
        }
        this.publicationsService.patchPublicationItem(this.publicationItemToEdit.getModel(),
            patchBody, this.publication._id, this.publicationItemToEdit._id)
            .pipe(finalize(() => this.saveButton.loading = false))
            .subscribe({
                next: () => {
                    Toaster.success('Publication item edited successfully');
                    this.fullModalService.close(true);
                },
                error: Toaster.handleApiError
            });
    }

    public onTagChanged(event: string): void {
        this.tagOptions = event && event.length && event.trim().length ? [new DropdownItem<string>(event.trim(), event.trim())] : [];
    }

    public getTemplates(): void {
        if (this.templateSubscription && !this.templateSubscription.closed) return;

        const selectedNumberOfPages = this.publication.channelIsPrintPublication() ? this.formGroup.get('templateType')?.value?.getValue() : null;
        const selectedTemplateTypeId = this.publication.channelIsPos() ? this.formGroup.get('templateType')?.value?.getValue() : null;

        this.templateSubscription = this.templateService.getTemplates(this.publication.channel.name,
            EEngineType.INDESIGN, this.templatePageSize, this.templateIndexPage * this.templatePageSize,
            selectedNumberOfPages, undefined, null, selectedTemplateTypeId, 'name', 'asc')
            .subscribe({
                next: (result) => {
                    this.templateIndexPage += 1;
                    this.templates = this.templates.concat(result.items);
                    this.totalTemplates = result.total;

                    if (this.selectedTemplateId) {
                        const foundTemplate = this.templates.find((template) => template._id === this.selectedTemplateId);

                        // If the template was not found, and we have more pages to check, continue recursion.
                        // Otherwise, patch the form with either the foundTemplate or a null value
                        if (!foundTemplate && result.total > this.templates.length) {
                            // Unsubscribe so the closed check does not stop this recursion
                            this.templateSubscription.unsubscribe();

                            this.getTemplates();
                        } else {
                            this.formGroup.patchValue({template: foundTemplate});
                        }
                    }
                },
                error: Toaster.handleApiError
            });
    }

}
